import classNames from 'classnames';
import React, { FC, useEffect, useRef } from 'react';
import ReactLoading from 'react-loading';

import MarkdownWithCodeHighlighting from './MarkdownWithCodeHighlighting';
import SourceDocuments from './SourceDocuments';
import styles from './styles.module.scss';
import utils from '../../../../../scss/base/utils.module.scss';
import AvatarIcon from '../../../atoms/avatar-icon/AvatarIcon';
import AltaSigmaLogo from '../AltaSigmaLogo';

export type UserMessage = {
  id?: string | number;
  role: 'user';
  title: string;
  content?: string;
  isLoading?: boolean;
  isError?: boolean;
};

export type AssistantMessage = {
  id?: string | number;
  role: 'assistant';
  title: string;
  content?: string;
  isLoading?: boolean;
  isError?: boolean;
  source_documents?: SourceDocument[];
};

export type SourceDocument = {
  file_name: string;
  page_content: string;
};

export type ChatMessage = UserMessage | AssistantMessage;

type Props = {
  /** ID of the logged in user, used for the icons */
  userId?: string;
  messages: ChatMessage[];
};

const ChatMessages: FC<Props> = ({ messages, userId }) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const strMessages = JSON.stringify(messages);

  useEffect(() => {
    scrollToBottom();
  }, [strMessages]);

  return (
    <div className={styles.chatMessagesOuter}>
      <div className={styles.fadeTop} />
      <div className={classNames(styles.chatMessages, utils.hideScrollbar)}>
        {messages.map((message, i) => (
          <ChatMessage key={i} message={message} userId={userId} />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className={styles.fadeBottom} />
    </div>
  );
};

const ChatMessage: FC<{ message: ChatMessage; userId?: string }> = ({
  message,
  userId,
}) => {
  return (
    <div
      className={classNames(styles.message, {
        [styles.errorMessage]: message.isError,
        [styles.userMessage]: message.role === 'user',
        [styles.assistantMessage]: message.role === 'assistant',
      })}
    >
      {message.role === 'assistant' && (
        <div className={styles.iconContainer}>
          <AltaSigmaLogo width={'20px'} height={'20px'} />
        </div>
      )}
      <div className={styles.textContainer}>
        <span className={styles.title}>{message.title}</span>
        {message.content && (
          <div className={styles.content}>
            <MarkdownWithCodeHighlighting>
              {message.content}
            </MarkdownWithCodeHighlighting>
          </div>
        )}
        {message.isLoading && (
          <div className={styles.loadingContainer}>
            <ReactLoading type={'bars'} color={'#dce2eb'} width={32} />
          </div>
        )}
        {message.role === 'assistant' && message.source_documents && (
          <SourceDocuments sourceDocuments={message.source_documents} />
        )}
      </div>
      {message.role === 'user' && (
        <div className={styles.iconContainer}>
          <AvatarIcon
            userId={userId}
            showTooltip={false}
            fallbackToIcon={false}
          />
        </div>
      )}
    </div>
  );
};

export default ChatMessages;
