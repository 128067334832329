import commonMessages from 'common/dist/messages/common';
import { contentPathToArray } from 'common/dist/utils/workbench/content';
import filesize from 'filesize';
import React, { FC } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import { DeprecatedRootState } from '../../../../../store/state.type';
import { useAppDispatch } from '../../../../../store/store';
import { useShowWarningOpenFile } from '../../../../../store/workbench/state.types';
import { workbenchRoutes } from '../../../../../workbench/common/workbenchRoutes';
import Button from '../../../../atoms/button/Button';
import SelectedDirs from '../../../../molecules/selected-dirs/SelectedDirs';
import { openFileNoCheck, WARNING_SIZE } from '../../../utils';

export interface Props {}

const WarningOpenFileWizard: FC<Props> = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const state = useSelector<DeprecatedRootState, DeprecatedRootState>(
    (state) => state
  );
  const showWarningOpenFile = useShowWarningOpenFile();
  if (showWarningOpenFile.path === undefined) {
    return <Redirect to={workbenchRoutes.basePath} />;
  }

  const fileBrowserRoute = `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}`;

  return (
    <div
      className={'dialog-container'}
      style={{
        paddingTop: '40px', // This wizard requires additional space in the top to look good
      }}
    >
      <div className={'dialog-inner-container'}>
        <p className={'dialog-headline'}>Open File</p>
        <div className={'step-container'}>
          <div className={'description-container'}>
            <div className={'bubble bubble-warn'}>
              <FiAlertTriangle className={'icon-x'} size={'20px'} />
            </div>
            <div className={'description'}>
              <div className={'title'}>
                Are you sure you want to open this large file (&gt;
                {filesize(WARNING_SIZE)})? It may lead to instability.
              </div>
              <SelectedDirs
                dirs={contentPathToArray(showWarningOpenFile.path)}
                highlightLastEntry
              />
            </div>
          </div>
        </div>
        <div className={'dialog-button-container'}>
          <Button
            label={commonMessages.cancel}
            color='white'
            linkTo={fileBrowserRoute}
          />

          <Button
            label='Open'
            onClick={() => {
              openFileNoCheck(
                state,
                dispatch,
                history,
                showWarningOpenFile.path
              );
            }}
            linkTo={fileBrowserRoute}
            color='orange'
          />
        </div>
      </div>
    </div>
  );
};

export default WarningOpenFileWizard;
