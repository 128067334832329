import classNames from 'classnames';
import commonMessages from 'common/dist/messages/common';
import workbenchMessages from 'common/dist/messages/workbench';
import filesize from 'filesize';
import React, { FC } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';

import styles from './styles.module.scss';
import { ButtonProps } from '../../../../atoms/button/Button';
import Modal from '../../../../organisms/modal/Modal';
import { WARNING_SIZE } from '../../../utils';

export interface Props {
  isOpen: boolean;
  onRequestSubmit: () => void;
  onRequestClose: () => void;
}

const WarningSaveNotebookModal: FC<Props> = ({
  isOpen,
  onRequestSubmit,
  onRequestClose,
}) => {
  const buttons: ButtonProps[] = [
    {
      color: 'white',
      label: commonMessages.cancel,
      onClick: onRequestClose,
    },
    {
      color: 'orange',
      label: commonMessages.save,
      onClick: onRequestSubmit,
    },
  ];

  return (
    <Modal
      show={isOpen}
      headline={workbenchMessages.notebookEnvironmentSaveFile}
      hideModal={onRequestClose}
      buttons={buttons}
    >
      <div className={styles.modalContainer}>
        <div className={classNames(styles.bubble, styles.bubbleWarn)}>
          <FiAlertTriangle className={styles.icon} />
        </div>

        <div className={styles.title}>
          <FormattedMessage
            {...workbenchMessages.notebookEnvironmentSaveLargeFile}
            values={{ filesize: filesize(WARNING_SIZE) }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default WarningSaveNotebookModal;
