import type { Node, NodeProps } from '@xyflow/react';
import classNames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';
import { AS_NODE_TYPES, AsNodes, GenericNodeData } from './types';
import { generateHandles } from './utils';
import { AS_DATA_TYPES } from '../data.types';
import { ParameterDefinition } from '../types';

export type ConditionalNodeData = GenericNodeData;

export type ConditionalNode = Node<
  ConditionalNodeData,
  typeof AS_NODE_TYPES.CONDITIONAL
>;

export function isConditionalNode(node: AsNodes): node is ConditionalNode {
  return node.type === AS_NODE_TYPES.CONDITIONAL;
}

export const HANDLE_ID_CONDITION = 'in-conditional' as const;
export const HANDLE_ID_TRUE = 'in-true' as const;
export const HANDLE_ID_FALSE = 'in-false' as const;

export const CONDITIONAL_INPUTS: ParameterDefinition[] = [
  {
    id: HANDLE_ID_TRUE,
    type: AS_DATA_TYPES.ANY,
  },
  {
    id: HANDLE_ID_CONDITION,
    type: AS_DATA_TYPES.BOOL,
  },
  {
    id: HANDLE_ID_FALSE,
    type: AS_DATA_TYPES.ANY,
  },
];

export const CONDITIONAL_OUTPUTS: ParameterDefinition[] = [
  {
    id: 'conditional_out',
    type: AS_DATA_TYPES.ANY,
  },
];

export function ConditionalNode({ data }: NodeProps<ConditionalNode>) {
  const { highlight, connections } = data;
  return (
    <div
      className={classNames(
        'react-flow__node-default',
        styles.defaultNodeStyle
      )}
      style={highlight ? { backgroundColor: 'rgb(222, 222, 222)' } : {}}
    >
      {generateHandles(connections.inputs, 'target')}
      IF
      {generateHandles(connections.outputs, 'source')}
    </div>
  );
}
