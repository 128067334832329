import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiX } from 'react-icons/fi';
import ReactModal from 'react-modal';

import infoStyles from './../../../info/styles.module.scss';
import { useGitCreateBranchHook } from '../../../../../../../core/api/notebook';
import Button from '../../../../../../atoms/button/Button';
import IntlTextInputLine from '../../../../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import styles from '../styles.module.scss';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

interface CreateBranchModalProps {
  isCreateBranchModalOpen: boolean;
  hideCreateBranchModal: () => void;
  infoFilePath: string;
  existingBranches: { name: string; commit: object }[];
}

interface FormInputs {
  branchName: string;
}

const CreateBranchModal: FC<CreateBranchModalProps> = ({
  isCreateBranchModalOpen,
  hideCreateBranchModal,
  infoFilePath,
  existingBranches,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormInputs>({ mode: 'onChange' });
  const { mutate } = useGitCreateBranchHook();

  const handleClose = () => {
    hideCreateBranchModal();
  };

  const onSubmit = (data: FormInputs) => {
    const parts = infoFilePath.split('/');
    const repositoryPath = parts.slice(0, parts.length - 1).join('/');

    mutate({ repositoryPath, branchName: data.branchName });
    handleClose();
  };

  return (
    <ReactModal
      isOpen={isCreateBranchModalOpen}
      contentLabel='Create Branch Modal'
      onRequestClose={handleClose}
      style={customStyles}
      appElement={document.getElementById('react-app')}
      parentSelector={() => document.querySelector('#MainContainer')}
    >
      <div className={infoStyles.modalCommitParent}>
        <div
          title={'Close Dialog'}
          className={'workbench-button close-button'}
          onClick={handleClose}
        >
          <FiX className={'icon'} size={'20px'} />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={infoStyles.modalCommitElements}>
            <div className={styles.parent}>
              <Controller
                name={'branchName'}
                control={control}
                rules={{
                  required: 'Branch name is required',
                  minLength: {
                    value: 3,
                    message: 'Branch name must be at least 3 characters long',
                  },
                  validate: (branchName: string) => {
                    if (branchName.includes('--')) {
                      return 'Branch name cannot contain continuous hyphens';
                    }
                    if (branchName.endsWith('-')) {
                      return 'Branch name cannot end with a hyphen';
                    }
                    if (!/^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(branchName)) {
                      return 'Branch name must start with a lowercase letter or number and can only contain lowercase letters, numbers, and single hyphens';
                    }

                    if (branchName.length > 50) {
                      return 'Branch name should not exceed 50 characters';
                    }
                    if (
                      existingBranches?.some(
                        (branch) => branch.name === branchName
                      )
                    ) {
                      return 'Branch name already exists';
                    }
                    return true;
                  },
                }}
                render={({ field, fieldState }) => {
                  const { ref, ...rest } = field; // extract ref to pass as inputRef
                  return (
                    <IntlTextInputLine
                      label={'Branch Name'}
                      placeholder={'Branch Name'}
                      {...rest}
                      {...fieldState}
                      isTouched={true}
                      inputRef={ref}
                      error={fieldState.error?.message}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className={infoStyles.modalCommitButtons}>
            <Button
              color={'primary'}
              label={'Create'}
              disabled={!isValid}
              type='submit'
            />
          </div>
        </form>
      </div>
    </ReactModal>
  );
};

export default CreateBranchModal;
