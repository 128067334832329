import {
  hierarchy,
  HierarchyPointNode,
  tree as treeLayout,
} from 'd3-hierarchy';
import React, { FC, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { TransitionGroup } from 'react-transition-group';

import { BinaryTreeState } from './BinaryTreeShadowModel';
import Link from './Link';
import Node, { TreeNode } from './Node';
import NodeTooltip from './NodeTooltip';
import { expand, getDepth } from './treeChart/tree';
import { ClassificationTreeNode } from './type';
import { useDimensions } from '../../../../../../utils';
import Tooltip from '../../../../../atoms/tooltip/Tooltip';

const MAX_DEPTH = 12;
const LEVEL_HEIGHT = 45;
const LEVEL_PADDING = 20;
const LEVEL_PADDING_WIDTH = 15;
const WIDTH = 200;

interface Props {
  data: {
    root: ClassificationTreeNode;
  };
  state: BinaryTreeState;
  dispatch: (action: any) => void;
  animationDuration: {
    mount: {
      delay: number;
      duration: number;
    };
    update: {
      delay: number;
      duration: number;
    };
    exit: {
      delay: number;
      duration: number;
    };
  };
  expandHeight?: number;
  initialHeight?: number;
  margins?: {
    top: number;
    left: number;
    right: number;
    bottom: number;
  };

  linkShapeFunc(...args: unknown[]): unknown;
  linkThicknessFunc(...args: unknown[]): unknown;
  adjustTreeFunc(...args: unknown[]): any;
}

const Tree: FC<Props> = ({
  state,
  data,
  adjustTreeFunc,
  linkThicknessFunc,
  linkShapeFunc,
  dispatch,
  animationDuration,
  initialHeight = 4,
  expandHeight = 1,
  margins = { top: 20, left: 20, bottom: 20, right: 20 },
}) => {
  const [
    treeChartRef,
    { width: parentWidth, height: parentHeight },
  ] = useDimensions<HTMLDivElement>();

  const dataRoot = useMemo(() => {
    const selectedNode = state.selectedNode;
    return !selectedNode?.depth || selectedNode.depth === 0
      ? expand(data.root, 0, initialHeight)
      : adjustTreeFunc(selectedNode, expandHeight);
  }, [
    data.root,
    initialHeight,
    adjustTreeFunc,
    expandHeight,
    state.selectedNode,
  ]);
  const currentDepth = useMemo(() => getDepth(dataRoot), [dataRoot]);

  const depth = Math.min(MAX_DEPTH, currentDepth);
  const root = hierarchy(
    dataRoot,
    (node) => node.renderedChildren
  ) as HierarchyPointNode<TreeNode>;

  const tree = treeLayout().size([WIDTH * 2, LEVEL_HEIGHT * depth]);

  tree(root);

  const resultWidth = Math.max(WIDTH * 2 + LEVEL_PADDING_WIDTH, parentWidth);
  const resultHeight = (LEVEL_HEIGHT + LEVEL_PADDING) * (currentDepth - 1);

  const nodes = root.descendants();
  const links = root.links();
  const viewBox = `0, 0, ${resultWidth}, ${resultHeight}`;
  // padding-bottom hack to scale the inline svg to the container width
  // see https://css-tricks.com/scale-svg/#article-header-id-10
  return (
    <div className='tree-chart' ref={treeChartRef}>
      {createPortal(
        <Tooltip
          anchorSelect='.tree-chart_node'
          className={'tree-chart_tooltip'}
        >
          <NodeTooltip state={state} />
        </Tooltip>,
        document.body
      )}
      <svg viewBox={viewBox} preserveAspectRatio='xMinYMin meet'>
        <g transform={`translate(${margins.left}, ${margins.top})`}>
          <TransitionGroup component={null}>
            {links.map((link) => (
              <Link
                state={state}
                totalRecordCount={Number(dataRoot.recordCount)}
                key={`${link.source.data.id}-${link.target.data.id}`}
                link={link}
                linkShapeFunc={linkShapeFunc}
                linkThicknessFunc={linkThicknessFunc}
                animationDuration={animationDuration}
              />
            ))}
            {nodes.map((node) => (
              <Node
                state={state}
                dispatch={dispatch}
                key={node.data.id}
                node={node}
                positiveClassName={'tree-chart_node--positive'}
                negativeClassName={'tree-chart_node--negative'}
                leafClassName={'tree-chart_node--leaf'}
                animationDuration={animationDuration}
              />
            ))}
          </TransitionGroup>
        </g>
      </svg>
    </div>
  );
};

export default Tree;
