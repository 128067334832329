import classNames from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';
import { useAugurs } from '../../../core/api/augurs';
import { useHabitatNames } from '../../../core/api/habitats';
import { useRealtimeSummary } from '../../../core/api/orchestration';
import { assistantsRoutes } from '../routes';

type Props = {};

export const ASSISTANT_ID_SPEECH2TEXT = 'speech2text';
export const ASSISTANT_ID_RAG = 'rag';
export const ASSISTANT_ID_GRAP_SEARCH = 'graphSearch';
export const ASSISTANT_ID_FRUIT_OBJECT_DETECTION = 'fruitObjectDetection';

type ASSISTANT_IDS =
  | typeof ASSISTANT_ID_SPEECH2TEXT
  | typeof ASSISTANT_ID_RAG
  | typeof ASSISTANT_ID_GRAP_SEARCH
  | typeof ASSISTANT_ID_FRUIT_OBJECT_DETECTION;

type HabitatWithAssistants = {
  habitatCode: string;
  habitatName: string;
  assistants: Assistant[];
};

type Assistant = {
  augurName: string;
  augurCode: string;
  moduleName: string;
  assistantId: ASSISTANT_IDS;
  /** Is the realtime deployment for the underlying augur running? */
  isOnline: boolean;
};

const AssistantsOverview: FC<Props> = () => {
  // useRealtimeSummary also implicitly dispatches the fetchSummary action to ensure state.summary is filled with the
  // overview of all Habitats and Augurs in the Redux state.
  const realtimeSummary = useRealtimeSummary();
  const { data: habitatNames } = useHabitatNames();
  const { data: augurs } = useAugurs();

  const validModuleNames = [
    'Speech2Text',
    'RAG',
    'Graph Search',
    'Fruit Object Detection',
  ];

  const filteredAugurs =
    augurs?.filter((augur) =>
      validModuleNames.includes(augur.moduleVersion.module.name)
    ) || [];

  const habitatsWithAssistants: HabitatWithAssistants[] = [];

  filteredAugurs.forEach((augur) => {
    const habitatCode = augur.habitatCode;
    const habitatName = habitatNames?.[habitatCode];

    if (!habitatName) return;

    let habitat = habitatsWithAssistants.find(
      (h) => h.habitatCode === habitatCode
    );
    if (!habitat) {
      habitat = {
        habitatCode,
        habitatName,
        assistants: [],
      };
      habitatsWithAssistants.push(habitat);
    }

    const rs = realtimeSummary.find((rs) => rs.augurCode === augur.code);
    let assistantId: ASSISTANT_IDS;

    switch (augur.moduleVersion.module.name) {
      case 'Speech2Text':
        assistantId = ASSISTANT_ID_SPEECH2TEXT;
        break;
      case 'RAG':
        assistantId = ASSISTANT_ID_RAG;
        break;
      case 'Graph Search':
        assistantId = ASSISTANT_ID_GRAP_SEARCH;
        break;
      case 'Fruit Object Detection':
        assistantId = ASSISTANT_ID_FRUIT_OBJECT_DETECTION;
        break;
      default:
        return; // Skip this augur if it doesn't match any known assistant type
    }

    habitat.assistants.push({
      augurName: augur.name,
      augurCode: augur.code,
      moduleName: augur.moduleVersion.module.name,
      assistantId,
      isOnline: rs && rs.status === 'Running',
    });
  });

  const CardInner: FC<{ assistant: Assistant }> = ({ assistant }) => {
    if (assistant.isOnline) {
      return (
        <Link
          key={assistant.augurCode}
          to={`${assistantsRoutes.basePath}/${assistant.assistantId}?augurCode=${assistant.augurCode}`}
        >
          <div className={styles.assistantCardInner}>
            <span className={styles.augurName}>{assistant.augurName}</span>
            <span className={styles.moduleName}>{assistant.moduleName}</span>
          </div>
        </Link>
      );
    } else {
      return (
        <>
          <div className={styles.assistantCardInner}>
            <span className={styles.augurName}>{assistant.augurName}</span>
            <span className={styles.moduleName}>{assistant.moduleName}</span>
            <div className={styles.contentSpacer} />
            <span className={styles.status}>Offline</span>
          </div>
        </>
      );
    }
  };

  return (
    <div className={styles.overview}>
      {habitatsWithAssistants.map((hwa) => (
        <div className={styles.habitatWithAssistants} key={hwa.habitatCode}>
          <span className={styles.habitatName}>{hwa.habitatName}</span>
          <div className={styles.assistantsContainer}>
            {hwa.assistants.map((assistant, i) => (
              <div
                key={i}
                className={classNames(styles.assistantCard, {
                  [styles.disabled]: !assistant.isOnline,
                })}
              >
                <CardInner assistant={assistant} />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AssistantsOverview;
