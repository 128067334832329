import classNames from 'classnames';
import orchestrationMessages from 'common/dist/messages/orchestration';
import { JobTypeToSpeaking, StatusType } from 'common/dist/types/job';
import React, { Component, Fragment } from 'react';
import { FiChevronsDown } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import ReactLoading from 'react-loading';
import { RouteComponentProps } from 'react-router';

import CodeCapsuleReport from './code-capsule-report/CodeCapsuleReport.container';
import JobLogs from './job-logs/JobLogs';
import { CProps } from './JobDetails.container';
import JobDetailsHeader from './JobDetailsHeader';
import vars from '../../../../scss/base/var.module.scss';
import { socket } from '../../../socket';
import Button from '../../atoms/button/Button';
import { speakingSuperType } from '../../molecules/job-groups/job/Job';
import KubernetesEventsTable from '../../organisms/kubernetes-events-table/KubernetesEventsTable';
import ProgressSteps from '../../organisms/progress-steps/ProgressSteps';
import { PRIORITIES_DISPLAY_NAMES } from '../../runCodeCapsuleModal/runCodeCapsule.form';
import { getStatusClass, speakingStatus } from '../common/jobStatus';
import styles from '../details/styles.module.scss';
import { orchestrationRoutes } from '../routes';

// --- Contains mappings from the technical fields to the displayed field names -> TODO Add react-intl
const fieldNames = {
  jobCode: 'Job Code',
  augurCode: 'Augur Code',
  habitatCode: 'Habitat Code',
  affiliationType: 'Affiliation Type',
  status: 'Status',
  executionType: 'Execution Type',
  priority: 'Priority',
  superType: 'Super Type',
  jobType: 'Job Type',
  wasInjected: 'Was Injected',
  moduleName: 'Module Name',
  moduleVersionNumber: 'Module Version Number',
  addedToExecutionQueue: 'Added to Execution Queue',
  moduleCode: 'Module Code',
  capsuleImage: 'Code Capsule Image',
  capsuleVersionNumber: 'Code Capsule Version',
  notebooksToExecute: 'Notebooks to execute',
  repositoryCode: 'Repository Code',
  parameters: 'Parameters',
  resources: 'Resources',
  sourceDataSourceCode: 'Source Data Source Code',
  sourceBucket: 'Source Bucket',
  sourcePath: 'Source Path',
  targetDataSourceCode: 'Target Data Source Code',
  targetBucket: 'Target Bucket',
  targetPath: 'Target Path',
  templateId: 'Template Id',
  backupId: 'Backup Id',
  name: 'Job Name',
};

// Maps jobType -> fields to display (+ ordering)
const fieldsToDisplay = {
  learning: ['status', 'jobCode', 'jobType', 'augurCode'],
  evaluation: ['status', 'jobCode', 'jobType', 'augurCode'],
  prediction: ['status', 'jobCode', 'jobType', 'augurCode'],
  'build-model': ['status', 'jobCode', 'jobType'],
  'build-code-capsule': [
    'status',
    'jobCode',
    'jobType',
    'codeCapsuleCode',
    'capsuleVersionNumber',
  ],
  'run-code-capsule': [
    'status',
    'name',
    'jobCode',
    'jobType',
    'capsuleVersionNumber',
    'notebooksToExecute',
    'parameters',
    'resources',
    'priority',
  ],
  'build-app': ['status', 'jobCode', 'jobType', 'appCode', 'appVersionNumber'],
  'build-module': [
    'status',
    'jobCode',
    'jobType',
    'moduleCode',
    'moduleVersionNumber',
  ],
  transfer: [
    'status',
    'jobCode',
    'jobType',
    'sourceDataSourceCode',
    'sourceBucket',
    'sourcePath',
    'targetDataSourceCode',
    'targetBucket',
    'targetPath',
  ],
  backup: ['status', 'jobCode', 'jobType', 'templateId', 'backupId'],
  restore: [
    'status',
    'jobGroupName',
    'jobGroupDescription',
    'jobCode',
    'jobType',
    'templateId',
    'backupId',
  ],
};

/**
 * Render an array as a list
 * @param array
 * @returns {JSX.Element}
 */
function renderArray(array) {
  return (
    <ul>
      {array.map((item) => (
        <li>{item}</li>
      ))}
    </ul>
  );
}

/**
 * Render an object with depth 1 as a table
 * @param obj
 * @returns {JSX.Element}
 */
function renderObject(obj) {
  if (!obj || Object.keys(obj).length === 0) {
    return (
      <span className={styles.valueNoValue}>
        <i>
          <FormattedMessage {...orchestrationMessages.detailsValueNoValue} />
        </i>
      </span>
    );
  } else {
    return (
      <div className={'table-reset'}>
        <table>
          {Object.entries(obj).map(([key, value]) => (
            <tr>
              <td className={'job-details-value-object-key'}>{key}:</td>
              {value ? (
                <td>{value}</td>
              ) : (
                <td className={styles.valueNoValue}>
                  <i>
                    <FormattedMessage
                      {...orchestrationMessages.detailsValueNoValue}
                    />
                  </i>
                </td>
              )}
            </tr>
          ))}
        </table>
      </div>
    );
  }
}

/**
 * Stringify any object, while removing the extra quotes from single string values
 * @param obj
 * @returns {string | JSX.Element}
 */
function renderAny(obj) {
  if (!obj) {
    return (
      <td className={styles.valueNoValue}>
        <i>No value defined</i>
      </td>
    );
  } else if (typeof obj === 'string') {
    return JSON.stringify(obj).slice(1, -1);
  } else {
    return JSON.stringify(obj);
  }
}

export interface Props {}

export interface State {
  autoScroll: boolean;
}

export type RProps = RouteComponentProps<{
  /** JobCode of the job to show the details for */
  jobCode: string;
}>;

export default class JobDetails extends Component<
  Props & CProps & RProps,
  State
> {
  static defaultProps = {
    data: {},
  };
  state: State = {
    autoScroll: true, //Set auto-scroll to true by default
  };

  componentDidMount() {
    const {
      jobCode,
      fetchJobDetails,
      fetchJobKubernetesEvents,
      fetchProgressSteps,
      fetchJobLogsOrchestration,
    } = this.props;
    socket.emit('subscribe', `job-${jobCode}`);
    socket.emit('subscribe', `job-logs-${jobCode}`);
    fetchJobDetails(jobCode);
    fetchJobKubernetesEvents(jobCode);
    fetchProgressSteps(jobCode);
    fetchJobLogsOrchestration(jobCode);
  }

  componentWillUnmount() {
    const { clearJobLog } = this.props;
    socket.emit('unsubscribe', `job-${this.props.jobCode}`);
    socket.emit('unsubscribe', `job-logs-${this.props.jobCode}`);
    clearJobLog(this.props.jobCode);
  }

  renderError() {
    const { error } = this.props;
    return <div className={styles.card}>{JSON.stringify(error)}</div>;
  }

  renderLoading() {
    return (
      <ReactLoading
        className={'busy'}
        type={'cylon'}
        color={vars.colorPrimary}
      />
    );
  }

  /**
   * Injects speaking values if necessary
   * @param data
   * @param key
   * @returns {*}
   */
  renderValue(data, key) {
    if (key === 'status') return speakingStatus(data[key]);
    else if (key === 'priority') return PRIORITIES_DISPLAY_NAMES[data[key]];
    else if (key === 'superType') return speakingSuperType(data[key]);
    else if (key === 'jobType') return JobTypeToSpeaking[data[key]];
    else if (key === 'notebooksToExecute') return renderArray(data[key]);
    else if (key === 'parameters') return renderObject(data[key]);
    else if (key === 'resources') return renderObject(data[key]);
    return renderAny(data[key]);
  }

  renderFields() {
    const { data } = this.props;
    const jobType = data.jobType;
    if (!jobType) return <div />; // Shouldn't happen, just to be very sure.
    const fields = fieldsToDisplay[jobType] || [];
    return (
      <Fragment>
        {fields.map((key) => (
          <div className={styles.row} key={key}>
            <div className={styles.title}>
              {fieldNames[key] ? fieldNames[key] : key}
            </div>
            <div
              className={
                key === 'status'
                  ? classNames(styles.statusValue, getStatusClass(data[key]))
                  : styles.value
              }
            >
              {this.renderValue(data, key)}
            </div>
          </div>
        ))}
      </Fragment>
    );
  }

  renderLoaded() {
    return <div className={styles.card}>{this.renderFields()}</div>;
  }

  renderContent() {
    const { loading, error } = this.props;
    if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    return this.renderLoaded();
  }

  renderProgress() {
    const { progressSteps, data } = this.props;
    return (
      <div className={styles.item}>
        <span className={styles.headline}>Progress</span>
        {progressSteps?.loading ? (
          <ReactLoading
            className={'busy'}
            type={'cylon'}
            color={vars.colorPrimary}
          />
        ) : (
          <div className={styles.card}>
            <ProgressSteps
              finished={
                data['status'] !== 'running' &&
                data['status'] !== 'waiting' &&
                data['status'] !== 'waiting-in-execution-queue' &&
                data['status'] !== 'triggered'
              }
              progressSteps={progressSteps?.data ?? []}
            />
          </div>
        )}
      </div>
    );
  }

  renderKubernetesEvents() {
    const { events } = this.props;
    return (
      <div className={styles.item}>
        <span className={styles.headline}>
          <FormattedMessage
            {...orchestrationMessages.jobKubernetesEventsTitle}
          />
        </span>
        {events?.loading ? (
          <ReactLoading
            className={'busy'}
            type={'cylon'}
            color={vars.colorPrimary}
          />
        ) : (
          <div className={styles.card}>
            <KubernetesEventsTable events={events?.data ?? []} />
          </div>
        )}
      </div>
    );
  }

  toggleAutoScroll = () => {
    this.setState((prevState) => ({ autoScroll: !prevState.autoScroll }));
  };

  render() {
    const {
      origin,
      jobCode,
      cancelJob,
      data,
      fetchJobDetails,
      fetchJobKubernetesEvents,
      fetchCodeCapsuleReport,
      fetchProgressSteps,
      isAdmin,
      progressSteps,
      logs,
      fetchJobLogsOrchestration,
    } = this.props;

    const codeCapsuleReportAvailable =
      data?.jobType === 'run-code-capsule' &&
      (['success', 'failure', 'running'] as StatusType[]).includes(
        data?.status
      );
    return (
      <div className={styles.contentContainer}>
        <JobDetailsHeader
          origin={origin}
          jobCode={jobCode}
          cancelJob={cancelJob}
          jobStatus={data.status}
          fetchJobDetails={(jobCode) => {
            fetchJobDetails(jobCode);
            fetchJobKubernetesEvents(jobCode);
            fetchProgressSteps(jobCode);
            if (codeCapsuleReportAvailable) {
              fetchCodeCapsuleReport(jobCode);
            }
            // This is especially important e.g. if the refresh is done after the Job changed from "Triggered" to
            // "Running". In this case logs.error would be !== undefined causing the live job logs not to appear when
            // clicking the refresh button (even though there are actually live job logs available).
            // fetchJobLogs resets the logs.error field in the redux state
            fetchJobLogsOrchestration(jobCode);
          }}
        />
        <div className={styles.item}>
          <span className={styles.headline}>
            <FormattedMessage {...orchestrationMessages.jobDetailsTitle} />
          </span>
          {this.renderContent()}
        </div>
        {(progressSteps?.data ?? []).length > 0 && this.renderProgress()}
        {this.renderKubernetesEvents()}
        {codeCapsuleReportAvailable && <CodeCapsuleReport />}

        {(logs?.loading || logs?.loaded) && !logs?.error && (
          <div className={styles.item}>
            <div className={styles.headerWithButton}>
              <span className={styles.headline}>Job Logs</span>
              <Button
                Icon={() => <FiChevronsDown size={'20px'} />}
                shape={'squared'}
                onClick={this.toggleAutoScroll}
                title={
                  this.state.autoScroll
                    ? 'Stop auto-scroll'
                    : 'Start auto-scroll'
                }
                toggled={this.state.autoScroll}
              />
            </div>
            <div className={styles.card}>
              <JobLogs logs={logs} autoScroll={this.state.autoScroll} />
            </div>
          </div>
        )}

        {isAdmin && logs?.error && (
          <div className={styles.item}>
            <span className={styles.headline}>
              Job Logs (Source: Log Collector)
            </span>
            <div className={styles.card}>
              <Button
                color={'secondary'}
                linkTo={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobDetails.path}/${jobCode}/logs`}
                label={'Open Job Logs'}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
