import {
  FlowConfig,
  PersistedConditionalNode,
  PersistedCustomPythonNode,
  PersistedEdge,
  PersistedGatewayNode,
  PersistedNode,
  PersistedSubflowNode
} from "./config.types";
import { CustomPythonNodeDefinition } from "./customNodes.types";
import { CONDITIONAL_INPUTS, CONDITIONAL_OUTPUTS, ConditionalNode } from "./nodes/ConditionalNode";
import { CustomPythonNode } from "./nodes/CustomPythonNode";
import { GatewayNode } from "./nodes/GatewayNode";
import { SubflowNode } from "./nodes/SubflowNode";
import { AS_NODE_TYPES, AsNodesWithGateway } from "./nodes/types";
import { FlowData } from "./types";

export function toPersisted(node: AsNodesWithGateway): PersistedNode {
  switch (node.type) {
    case AS_NODE_TYPES.PYTHON_NODE: {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        config: {
          settings: node.data.settings,
          name: node.data.name,
          subflowId: node.data.subflowId,
          type: node.data.type,
        },
      } satisfies PersistedCustomPythonNode
    }
    case AS_NODE_TYPES.SUBFLOW: {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        config: {
          settings: node.data.settings,
          name: node.data.name,
          subflowId: node.data.subflowId,
          connections: node.data.connections
        },
      } satisfies PersistedSubflowNode
    }
    case AS_NODE_TYPES.GATEWAY: {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        config: {
          settings: node.data.settings,
          name: node.data.name,
          connections: node.data.connections,
          gatewayType: node.data.gatewayType,
          editable: node.data.editable,
        },
      } satisfies PersistedGatewayNode
    }
    case AS_NODE_TYPES.CONDITIONAL: {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        config: {
          name: node.data.name,
          settings: node.data.settings
        },
      } satisfies PersistedConditionalNode
    }
  }
}

export function fromPersisted(node: PersistedNode, filePath: string, nodeDefinitions: CustomPythonNodeDefinition[]): AsNodesWithGateway  {
  switch (node.type) {
    case AS_NODE_TYPES.PYTHON_NODE: {
      const nodeDefinition = nodeDefinitions.find(nd => nd.type === node.config.type)
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        data: {
          filePath,
          connections: nodeDefinition.connections,
          name: nodeDefinition.title,
          subflowConnections: nodeDefinition.subflowConnections,
          ...node.config
        }
      } satisfies CustomPythonNode
    }
    case AS_NODE_TYPES.CONDITIONAL: {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        data: {
          filePath,
          connections: {
            inputs: CONDITIONAL_INPUTS,
            outputs: CONDITIONAL_OUTPUTS,
          },
        }
      } satisfies ConditionalNode
    }
    case AS_NODE_TYPES.SUBFLOW: {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        data: {
          filePath,
          ...node.config
        }
      } satisfies SubflowNode
    }
    case AS_NODE_TYPES.GATEWAY: {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        data: {
          filePath,
          ...node.config
        }
      } satisfies GatewayNode
    }
  }
}

export const flowDataToFlowConfig = (flow: FlowData): FlowConfig => ({
  ...flow,
  nodes: flow.nodes.map(
    (node) =>
      (toPersisted(node))
  ),
  edges: flow.edges.map(
    (edge) =>
      ({
        id: edge.id,
        type: edge.type,
        target: edge.target,
        source: edge.source,
        targetHandle: edge.targetHandle,
        sourceHandle: edge.sourceHandle,
      } satisfies PersistedEdge)
  ),
  subflows: flow.subflows.map((flow) => flowDataToFlowConfig(flow)),
});

export function flowConfigToFlowData(
  flowConfig: FlowConfig,
  filePath: string,
  nodeDefinitions: CustomPythonNodeDefinition[]
): FlowData {
  return {
    ...flowConfig,
    nodes: flowConfig.nodes.map((node) => (fromPersisted(node, filePath, nodeDefinitions))),
    subflows: flowConfig.subflows.map((subflow) =>
      flowConfigToFlowData(subflow, filePath, nodeDefinitions)
    ),
  };
}
