import classNames from 'classnames';
import React, { Component } from 'react';
import { WrappedFieldProps } from 'redux-form';

import styles from './styles.module.scss';

interface Props {
  label: string;
  name: string;
}
export default class TextInput extends Component<Props & WrappedFieldProps> {
  render() {
    const {
      meta: { error, touched },
      label,
      name,
    } = this.props;
    return (
      <div className={styles.commitMessageParent}>
        <div className={styles.fieldHeadline}>
          <span className={styles.commitMessageLabel}>{label}</span>
          {touched && error && (
            <div className={styles.errorLine}>
              <span className={'error'}>{error}</span>
            </div>
          )}
        </div>
        <input
          type={'text'}
          id={name}
          className={classNames(styles.inputCommitMessage, {
            [styles.invalid]: touched && error,
          })}
          {...this.props.input}
        />
      </div>
    );
  }
}
