import msgsWorkbench from 'common/dist/messages/workbench';
import { PullConflicts } from 'common/dist/types/repository';
import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import styles from './styles.module.scss';
import { useGitPull } from '../../../../../../core/api/notebook';
import { showPullConflictsModal } from '../../../../../../redux/workbench/modules/container-interactions.module';
import { ButtonProps } from '../../../../../atoms/button/Button';
import Modal from '../../../../../organisms/modal/Modal';

type Props = {
  show?: boolean;
  activeBranch?: string;
  commitsBehind?: number;
  onClose: () => void;
  repositoryPath: string;
};

const PushConflictsModal: FC<Props> = (props) => {
  const { onClose, show, activeBranch, repositoryPath, commitsBehind } = props;
  const dispatch = useDispatch();
  const { mutate, error: pullError } = useGitPull();
  useEffect(() => {
    if (pullError) {
      //Try to parse the custom thrown error so we can extract the branch and commits
      const details: PullConflicts = JSON.parse(pullError?.message);
      dispatch(
        showPullConflictsModal(
          details.error,
          //@ts-ignore
          activeBranch,
          details.conflicts
        )
      );
    }
  }, [pullError]);
  const buttons: ButtonProps[] = [
    {
      color: 'white',
      onClick: () => {
        onClose();
      },
      label: 'Cancel',
    },
    {
      color: 'primary',
      onClick: () => {
        mutate({ repositoryPath, activeBranch, force: false });
        onClose();
      },
      label: 'Pull',
    },
  ];

  return (
    <Modal
      show={show}
      hideModal={onClose}
      headline={
        msgsWorkbench.workbenchRepositoryGitNotPushedPushConflictsModalHeadline
      }
      buttons={buttons}
    >
      <div className={styles.modalContent}>
        <span>
          <FormattedMessage
            {...msgsWorkbench.workbenchRepositoryGitNotPushedPushConflictsModalLine1}
            values={{
              commitsBehind,
              pluralSuffix: commitsBehind > 1 ? 's' : '',
            }}
          />
        </span>
        <span>
          <FormattedMessage
            {...msgsWorkbench.workbenchRepositoryGitNotPushedPushConflictsModalLine2}
          />
        </span>
      </div>
    </Modal>
  );
};

export default PushConflictsModal;
