import _ from 'lodash';

import { validateSchedule } from './augur';
import { scheduleMode } from '../constants/enums';
import { JobGroupTopologyType } from '../types/job';
import { ToBeRefined } from '../types/todo_type';
import { arrayToMap } from '../utils/arrayToMap';

export const selectedScheduleField = 'selectedSchedule';
export const jobGroupField = 'jobGroup';
export const fieldDescription = 'description';
export const fieldName = 'name';

export const validateAddSchedule = (value: ToBeRefined): ToBeRefined => {
  let errors = {};

  // --- Validate name
  const name = value[fieldName];
  if (!name) {
    errors = {
      ...errors,
      [fieldName]: { id: 'todo', defaultMessage: 'Please enter a name.' },
    };
  }
  if (name?.length > 255) {
    errors = {
      ...errors,
      [fieldName]: {
        id: 'todo',
        defaultMessage: 'Name cannot contain more than 255 characters.',
      },
    };
  }

  // --- Validate description
  const description = value[fieldDescription];
  if (description?.length > 400) {
    errors = {
      ...errors,
      [fieldDescription]: {
        id: 'todo',
        defaultMessage: 'Description cannot contain more than 400 characters.',
      },
    };
  }

  //  Validate the schedule
  if (value[selectedScheduleField] === undefined) {
    Object.assign(errors, {
      [selectedScheduleField]: {
        general: { id: 'newAugur.schedule.error.general_empty' },
      },
    });
  } else if (value[selectedScheduleField]) {
    const validation = validateSchedule({
      ...value[selectedScheduleField],
      mode: scheduleMode.SCHEDULED,
    });
    if (!_.isEmpty(validation)) {
      Object.assign(errors, { [selectedScheduleField]: validation });
    }
  }

  //  Validate the JobGroup
  // Is there a JobGroup at all?
  if (!value[jobGroupField]) {
    Object.assign(errors, {
      [jobGroupField]: {
        id: 'orchestration.newAugur.schedule.error.jobs_empty',
      },
    });
  } else {
    Object.assign(errors, {
      [jobGroupField]: validateJobGroup(value[jobGroupField]),
    });
  }

  return errors;
};

/**
 * Validates a JobGroup (that is designed in the 'Add Schedule' wizard)
 *
 * @param jobGroup
 * @returns {null|{id: string}}
 */
export const validateJobGroup = (jobGroup: ToBeRefined): ToBeRefined => {
  // Is there at least one job?
  const { jobs } = jobGroup;
  if (!jobs || jobs.length === 0) {
    return {
      id: 'orchestration.newAugur.schedule.error.jobs_empty',
      defaultMessage: 'Jobs must not be empty.',
    };
  }

  const { jobGroupTopology } = jobGroup;
  if (detectCycle(jobGroupTopology)) {
    return {
      id: 'orchestration.newAugur.schedule.error.cycle',
      defaultMessage: 'The Job graph may not contain a cycle.',
    };
  }

  return null;
};

/**
 * Find a cycle in the graph by doing DFS and coloring visited vertices. Only traverses based on successors.
 * @param jobGroupTopology
 */
export function detectCycle(jobGroupTopology: JobGroupTopologyType[]): boolean {
  const jobCodeToTopology = arrayToMap(jobGroupTopology, 'jobCode') as {
    [jobCode: string]: JobGroupTopologyType;
  };
  const graphRoots = jobGroupTopology.filter(
    (jgt) => jgt.predecessors.length === 0
  );

  // jobCodes to color, White = not visited, Grey = on stack, Black = done, closed over on the helper function
  const colorMap: Record<string, 'White' | 'Grey' | 'Black'> = {};
  // Visit vertices recursively, updating the colorMap and returning early with true if a cycle is found
  function detectCycleHelper(vertex: JobGroupTopologyType): boolean {
    const vertexColor = colorMap[vertex.jobCode] ?? 'White'; // Unvisited = Not in map = white
    switch (vertexColor) {
      case 'White':
        colorMap[vertex.jobCode] = 'Grey'; // On stack now
        if (
          vertex.successors.some((succ) =>
            detectCycleHelper(jobCodeToTopology[succ])
          )
        ) {
          return true;
        } else {
          colorMap[vertex.jobCode] = 'Black'; // Done with this sub-tree, no need to visit in future
          return false;
        }
      case 'Grey':
        return true; // Means there is a cycle starting at this vertex, because it was already traversed
      case 'Black':
        return false;
    }
  }

  return (
    graphRoots.length < 1 || graphRoots.some((jgt) => detectCycleHelper(jgt))
  );
}
