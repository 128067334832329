import { Point } from 'common/dist/types/module.charts';
import type {
  EChartsOption,
  TooltipComponentFormatterCallbackParams,
} from 'echarts';
import React, { FC } from 'react';

import { useDimensions } from '../../../../../../../utils';
import EChartWrapper, {
  ReactEChartsProps,
} from '../../../../../e-chart-wrapper/EChartWrapper';
import { showLegendSize } from '../../../common/sizes';
import commonStyles from '../../../styles.module.scss';
import { MultiReportElementProps } from '../../../types/meta';
import { LineChartConfig, LineChartReportData } from '../type';

export type MultiLineChartData = {
  data: (Point[] | undefined)[];
  legend?: string[];
};

export type Props = MultiLineChartData & LineChartConfig;

const MultiLineChart: FC<Props> = ({
  data,
  legend,
  xLabel,
  yLabel,
  yAxisMin,
}) => {
  const [ref, { width, height }] = useDimensions<HTMLDivElement>();
  const isSmall = width < showLegendSize && height < showLegendSize;
  const formattedData: EChartsOption['series'] = data
    .map((entry, index) => ({ data: entry, legend: legend?.[index] }))
    .map((entry) => {
      return {
        showSymbol: false,
        name: entry.legend || '',
        type: 'line',
        data: entry.data || [],
      };
    });

  // Add dummy data to show empty grid if all data is undefined
  let isUndefined = true;
  data.forEach((dataEntry) => {
    if (dataEntry) {
      isUndefined = false;
    }
  });
  if (isUndefined) {
    formattedData.push({
      symbol: 'none',
      name: '',
      type: 'line',
      data: [[1, 1]],
    });
  }

  const option: ReactEChartsProps['option'] = {
    legend: {
      orient: 'horizontal',
      formatter: (label) => {
        if (label.length > 7) return label.substring(0, 6) + '.';
        return '';
      },
      selectedMode: true,
      show: !isSmall,
      width: width,
      top: '0px',
      left: 'center',
      icon: 'roundRect',
    },
    tooltip: {
      appendToBody: true,
      trigger: 'axis',
      confine: true,
      valueFormatter: (value) => {
        if (typeof value === 'number') {
          return String(parseFloat(value.toFixed(2)));
        } else {
          return '';
        }
      },
      formatter: isUndefined
        ? 'NoData'
        : (params: TooltipComponentFormatterCallbackParams) => {
            const x = xLabel ?? 'x-Axis';
            let xValue;
            if (Array.isArray(params) && Array.isArray(params[0]?.data)) {
              xValue = parseFloat(Number(params[0].data[0]).toFixed(2));
            }

            let tooltip = `${x}: <strong> ${xValue} </strong> </br>`;
            if (Array.isArray(params)) {
              params.forEach((param) => {
                let yValue;
                if (Array.isArray(param.data)) {
                  yValue = parseFloat(Number(param.data[1]).toFixed(2));
                }
                tooltip += `${param.marker?.toString()} ${
                  param.seriesName
                }: <strong> ${yValue} </strong> </br>`;
              });
            }

            return tooltip;
          },
    },
    grid: {
      show: true,
      containLabel: true,
      left: 24,
      // dynamically adjust the topspace for the legend based on the legend items
      top: isSmall ? 10 : 17 + (3000 / width) * data.length,
      right: 12,
      bottom: 12,
    },
    xAxis: {
      name: xLabel,
      type: 'value',
      nameLocation: 'middle',
      nameGap: 22,
      min: (params) => {
        if (Number.isNaN(params.min)) return 0;
        return params.min;
      },
      max: (params) => {
        if (Number.isNaN(params.max)) return 1;
        return params.max;
      },
      axisLine: {
        show: true,
      },

      axisLabel: {
        show: true,
        // 1.81192837132 => 1.81 but also 1 => 1. Number casting after parseFloat removes the trailing 0s
        formatter: (value) => String(Number(parseFloat(value.toFixed(2)))),
      },
    },
    yAxis: {
      name: yLabel,
      type: 'value',
      nameLocation: 'middle',
      nameGap: 36,
      nameTextStyle: {
        align: 'center',
      },
      min: yAxisMin ?? 0,

      axisLabel: {
        show: true,
        // 1.81192837132 => 1.81 but also 1 => 1. Number casting after parseFloat removes the trailing 0s
        formatter: (value) => String(Number(parseFloat(value.toFixed(2)))),
      },
      axisTick: {
        show: true,
      },
    },
    series: formattedData,
    animation: false,
  };

  return (
    <div ref={ref} className={commonStyles.chart}>
      <EChartWrapper option={option} style={{ width: width, height: height }} />
    </div>
  );
};

export default MultiLineChart;

export const LineChartMulti: FC<
  MultiReportElementProps<LineChartReportData, LineChartConfig>
> = ({ input, config, ...rest }) => {
  const data = input.map((x) => x.reportValue?.data);
  const jobCodes = input.map((x) => x.jobCode ?? '');
  return <MultiLineChart legend={jobCodes} data={data} {...config} {...rest} />;
};
