import classNames from 'classnames';
import React, { ChangeEvent, Component, FC, FocusEvent } from 'react';
import {
  injectIntl,
  MessageDescriptor,
  WrappedComponentProps,
} from 'react-intl';

import InputError from '../../input-error/InputError';

import './styles.scss';
import { WrappedFieldProps } from 'redux-form';

export type Props = {
  /** Has the field been touched? */
  touched: boolean;
  /** Has the field an error? */
  error?: string | MessageDescriptor;
  /** Is the field currently being validated? -> Shows a spinner */
  validating?: boolean;
  /** Is the field valid? -> Shows a checkmark */
  valid?: boolean;
  /** Is the field disabled? */
  disabled?: boolean;
  /** ID of the input element */
  id?: string;
  /** Name of the input field */
  name?: string;
  /** Render a label for the input field? */
  hasLabel: boolean;
  /** Intl ID for the label */
  labelId?: string;
  /** Default message for the label */
  labelDefault?: string;
  /** Intl ID for the placeholder */
  placeholderId?: string;
  /** Default message for the placeholder. Empty strings are ignored. */
  placeholderDefault: string;
  /** Value of the input field */
  value: string;
  /** onChange callback */
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  /** onBlur callback */
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  /** onFocus callback */
  onFocus: (e: FocusEvent<HTMLInputElement>) => void;
  /** text-wrap the error into multiple lines if necessary? */
  wrapMultipleErrorLines?: boolean;
  isSearchIconVisible?: true;
  isClearable?: true;
};

class TextInputLine extends Component<Props & WrappedComponentProps> {
  render() {
    const {
      id,
      name,
      touched,
      validating,
      valid,
      error,
      intl,
      disabled,
      placeholderId,
      placeholderDefault,
      hasLabel,
      labelId,
      labelDefault,
      value,
      onChange,
      onBlur,
      onFocus,
      wrapMultipleErrorLines,
      isSearchIconVisible,
      isClearable,
    } = this.props;

    const fieldClassName = classNames(
      'TextInputLine--input',
      touched && {
        error,
        success: !validating && valid,
        pending: validating,
      }
    );

    const placeHolder = intl.formatMessage({
      id: placeholderId || 'no-id',
      defaultMessage: placeholderDefault,
    });

    let label: string | undefined;
    if (hasLabel) {
      label = intl.formatMessage({
        id: labelId || 'no-id',
        defaultMessage: labelDefault,
      });
    }

    return (
      <div className={'TextInputLine'}>
        <div className={'TextInputLine--header'}>
          {hasLabel && <p className={'TextInputLine--label'}>{label}</p>}
          <div
            className='TextInputLine--error'
            style={{
              whiteSpace: wrapMultipleErrorLines ? 'normal' : 'nowrap',
            }}
          >
            {error && <InputError touched={touched} error={error} />}
          </div>
        </div>
        <input
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          className={fieldClassName}
          type='text'
          placeholder={placeHolder}
          disabled={disabled}
        />
        {!validating && valid && (
          <div className='TextInputLine--success'>
            <span className='icon-ok' />
          </div>
        )}
        {validating && (
          <div className='TextInputLine--pending'>
            <span className='icon-refresh icon-spin' />
          </div>
        )}
      </div>
    );
  }
}

const IntlTextInputLine = injectIntl(TextInputLine);
/** @deprecated use react hook form variant instead*/
export default IntlTextInputLine;

/**
 * Same TextInputLine component, but assuming it is passed WrappedFieldProps from redux-form, e.g. as component of a <Field>
 * @param props
 * @constructor
 */
export const WrappedTextInputLine: FC<
  Omit<
    Props,
    'touched' | 'valid' | 'error' | 'value' | 'onChange' | 'onBlur' | 'onFocus'
  > &
    WrappedFieldProps
> = (props) => {
  const {
    input: { value, onChange, onBlur, onFocus },
    meta: { touched, valid, error, asyncValidating },
    ...otherProps
  } = props;
  return (
    <IntlTextInputLine
      touched={touched}
      valid={valid}
      value={value}
      error={error}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      validating={asyncValidating}
      {...otherProps}
    />
  );
};
