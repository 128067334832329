import React, { FC, useEffect, useRef } from 'react';

import styles from './styles.module.scss';

type Props = {
  sampleRate: number;
  binSize: number;
  totalDuration: number;
  waveformData: number[];
};

const drawRoundedRect = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
  topLeft: boolean,
  topRight: boolean,
  bottomLeft: boolean,
  bottomRight: boolean
) => {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);

  // Top edge
  if (topRight) {
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  } else {
    ctx.lineTo(x + width, y);
  }

  // Right edge
  if (bottomRight) {
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  } else {
    ctx.lineTo(x + width, y + height);
  }

  // Bottom edge
  if (bottomLeft) {
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  } else {
    ctx.lineTo(x, y + height);
  }

  // Left edge
  if (topLeft) {
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
  } else {
    ctx.lineTo(x, y);
  }

  ctx.closePath();
  ctx.fill();
};

const BARS_GAP = 5;
const BORDER_RADIUS = 2;
const WAVEFORM_COLOR = '#224e90';

const Waveform: FC<Props> = ({
  sampleRate,
  binSize,
  totalDuration,
  waveformData,
}) => {
  const binsCount = totalDuration / binSize;
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    drawWaveform();
  }, [waveformData]);

  // Scale the waveform vertically, simply because it looks better
  const scaleWaveform = (n) => Math.sqrt(n);

  const drawWaveform = () => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const width = canvas.width;
      const height = canvas.height;

      // Create bins and calculate mean amplitudes
      const binnedData: number[] = [];
      const samplesPerBin = Math.floor(sampleRate * binSize);
      for (
        let i = 0;
        i <
        Math.min(binsCount, Math.floor(waveformData.length / samplesPerBin));
        i++
      ) {
        const startIndex = i * samplesPerBin;
        const endIndex = startIndex + samplesPerBin;
        const bin = waveformData.slice(startIndex, endIndex);
        // const meanAmplitude =bin.reduce((sum, val) => sum + Math.abs(val), 0) / bin.length;
        // binnedData.push(meanAmplitude);
        binnedData.push(scaleWaveform(Math.max(...bin)));
      }

      // Find max amplitude for scaling
      const maxAmplitude = scaleWaveform(Math.max(...binnedData, 0.01)); // Avoid division by zero

      // Calculate bar width based on available bins
      const barWidth = width / binsCount;

      // Draw bars
      binnedData.forEach((amplitude, i) => {
        const normalizedAmplitude = amplitude / maxAmplitude;
        const barHeight = normalizedAmplitude * (height / 2); // Use half of the height for each direction

        ctx.fillStyle = WAVEFORM_COLOR;

        // Draw upper bar with top-left and top-right rounded corners
        drawRoundedRect(
          ctx,
          i * barWidth,
          height / 2 - barHeight,
          barWidth - BARS_GAP,
          barHeight,
          BORDER_RADIUS,
          true, // topLeft
          true, // topRight
          false, // bottomLeft
          false // bottomRight
        );

        // Draw lower bar with bottom-left and bottom-right rounded corners
        drawRoundedRect(
          ctx,
          i * barWidth,
          height / 2,
          barWidth - BARS_GAP,
          barHeight,
          BORDER_RADIUS,
          false, // topLeft
          false, // topRight
          true, // bottomLeft
          true // bottomRight
        );
      });
    }
  };

  return (
    <canvas
      ref={canvasRef}
      width={'1000px'}
      height={'200px'}
      className={styles.waveformCanvas}
    />
  );
};

export default Waveform;
