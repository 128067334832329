import { Node, NodeProps, useUpdateNodeInternals } from '@xyflow/react';
import React, { useEffect } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';

import { AS_NODE_TYPES, AsNodesWithGateway, GenericNodeData } from "./types";
import { generateHandles } from './utils';
import { emptyFlowData } from '../../../../../../store/workbench/flowDesigner.slice';
import Button from '../../../../../atoms/button/Button';
import { AS_DATA_TYPES } from "../data.types";
import { useSubflowUtils } from '../hooks';
import { FlowData, ParameterDefinition } from '../types';

export type GatewayNodeData = GenericNodeData & {
  gatewayType: 'in' | 'out';
  editable: boolean;
};

export type GatewayNode = Node<GatewayNodeData, 'gateway'>;

export function isSubflowGatewayNode(
  node: AsNodesWithGateway
): node is GatewayNode {
  return node.type === AS_NODE_TYPES.GATEWAY;
}

export function createEmptySubflow(
  filePath: string,
  parentNodeId: string,
  inputs: ParameterDefinition[],
  outputs: ParameterDefinition[],
  editable: boolean
): FlowData {
  return {
    ...emptyFlowData,
    id: uuidv4(),
    parentNodeId,
    nodes: [
      {
        id: uuidv4(),
        type: 'gateway',
        data: {
          filePath,
          connections: {
            inputs: [],
            // inversion because subflow inputs are input gateway node outputs
            outputs: inputs,
          },
          editable,
          gatewayType: 'in',
        },
        position: {
          x: 0,
          y: -250,
        },
      } satisfies GatewayNode,
      {
        id: uuidv4(),
        type: 'gateway',
        data: {
          filePath,
          connections: {
            outputs: [],
            // inversion because subflow outputs are output gateway node inputs
            inputs: outputs,
          },
          editable,
          gatewayType: 'out',
        },
        position: {
          x: 0,
          y: 250,
        },
      } satisfies GatewayNode,
    ],
  };
}

export function GatewayNode({
  id,
  data,
}: NodeProps<GatewayNode>) {
  const {
    filePath,
    editable,
    gatewayType,
    connections: { inputs, outputs },
  } = data;

  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    // We need to call updateNodeIntervals after adding/or removing handles (see https://reactflow.dev/api-reference/hooks/use-update-node-internals for more info)
    // Because we use redux for the flow state, calling updateNodeInternals directly after adding handles doesn't work because the Redux event runs async
    // Therefore, we need to wait for the event to take effect and watch for changes in data.connections to then call updateNodeIntervals
    updateNodeInternals(id);
    // DO NOT REMOVE THE data.connections DEPENDENCY
  }, [updateNodeInternals, id, data.connections]);

  const { addParameter, removeParameter } = useSubflowUtils(filePath);

  const addHandle = () => {
    addParameter(id, gatewayType, {
      id: uuidv4(),
      type: AS_DATA_TYPES.ANY,
    });
  };

  const removeHandle = () => {
    const lastHandle = gatewayType === 'in' ? outputs.at(-1) : inputs.at(-1);
    if (lastHandle) {
      removeParameter(id, gatewayType, lastHandle.id);
    }
  };

  return (
    // We add this class to use the same styles as React Flow's default nodes.
    <div
      className='react-flow__node-default'
      style={{
        width: '350px',
        height: '30px',
        background: 'lightgray',
      }}
    >
      {editable && (
        <Button
          form={'squared'}
          title={'Add handle'}
          Icon={FiPlus}
          style={{
            position: 'absolute',
            right: '8px',
            top: '50%',
            transform: 'translate(0, -50%)',
            height: '16px',
            width: '16px',
          }}
          onClick={addHandle}
        />
      )}
      {editable && (
        <Button
          form={'squared'}
          title={'Remove handle'}
          Icon={FiMinus}
          style={{
            position: 'absolute',
            left: '8px',
            top: '50%',
            transform: 'translate(0, -50%)',
            height: '16px',
            width: '16px',
          }}
          onClick={removeHandle}
        />
      )}
      {gatewayType === 'in' && generateHandles(outputs, 'source')}
      {gatewayType === 'out' && generateHandles(inputs, 'target')}
    </div>
  );
}
