import { useQueryClient } from '@tanstack/react-query';
import { PullConflicts } from 'common/dist/types/repository';
import React, { FC, useEffect } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';

import PullConflictsModal from './PullConflictsModal';
import vars from '../../../../../../../scss/base/var.module.scss';
import { useGitPull } from '../../../../../../core/api/notebook';
import {
  useCommits,
  versionControlKeys,
} from '../../../../../../core/api/versionControl';
import { CommitFilter } from '../../../../../../core/api/workbench/git.notebook';
import {
  hidePullConflictsModal,
  showPullConflictsModal,
} from '../../../../../../redux/workbench/modules/container-interactions.module';
import { RootState } from '../../../../../../store/store';
import Button from '../../../../../atoms/button/Button';

export interface Props {
  repositoryPath: string;
  activeBranch: string;
}
export interface GitPullMutation {
  repositoryPath: string;
  activeBranch: string;
  force: boolean;
}
export type gitNotPulledCommits = {
  show?: boolean;
  branch?: string;
  conflicts?: string[];
  error?: string;
};
const GitNotPulled: FC<Props> = ({ repositoryPath, activeBranch }) => {
  const dispatch = useDispatch();

  const { mutate, error: pullError } = useGitPull();
  useEffect(() => {
    if (pullError) {
      //Try to parse the custom thrown error so we can extract the branch and commits
      const details: PullConflicts = JSON.parse(pullError?.message);
      dispatch(
        showPullConflictsModal(
          details.error,
          //@ts-ignore
          activeBranch,
          details.conflicts
        )
      );
    }
  }, [pullError]);
  const forcePull = () => {
    mutate({ repositoryPath, activeBranch, force: true });
  };

  const queryClient = useQueryClient();
  async function invalidateCommits() {
    await queryClient.invalidateQueries(
      versionControlKeys.commits(
        repositoryPath,
        activeBranch,
        CommitFilter.NotPulled
      )
    );
  }
  const { data: commits, error, isLoading } = useCommits(
    repositoryPath,
    activeBranch,
    CommitFilter.NotPulled
  );
  const pullConflictsModal = useSelector<
    RootState,
    gitNotPulledCommits
    //@ts-ignore
  >((state) => state.workbench.pullConflictsModal);

  function renderLoaded() {
    return (
      <div className={'git-list-not-pulled-commits'}>
        <div className={'git-unpulled-status'}>
          {commits && commits.length > 0 ? (
            commits.length > 1 ? (
              <span>There are {commits.length} unpulled commits.</span>
            ) : (
              <span>There is {commits.length} unpulled commit.</span>
            )
          ) : (
            <span>There are no unpulled commits.</span>
          )}
        </div>

        <div className={'git-button-bar'}>
          <Button
            color={'primary'}
            label={'Pull'}
            disabled={!commits?.length}
            onClick={() =>
              mutate({ repositoryPath, activeBranch, force: false })
            }
          />
        </div>
      </div>
    );
  }

  function renderLoading() {
    return (
      <div className={'git-list-commits'}>
        <ReactLoading
          className={'starting-stopping-spinner'}
          type={'cylon'}
          color={vars.colorPrimary}
        />
      </div>
    );
  }

  function renderEmpty() {
    return (
      <div className={'git-list-commits'}>
        There are no commits for this repository yet
      </div>
    );
  }

  function renderError() {
    return (
      <div className={'git-list-commits'}>
        {error}

        <Button
          Icon={() => <FiRefreshCw size={16} />}
          color={'transparent'}
          onClick={() => void invalidateCommits()}
        />
      </div>
    );
  }

  function renderComponent() {
    if (isLoading) return renderLoading();
    if (!isLoading) return renderLoaded();
    if (error) return renderError();
    return renderEmpty();
  }

  return (
    <>
      {renderComponent()}

      <PullConflictsModal
        show={pullConflictsModal?.show}
        error={pullConflictsModal?.error}
        conflicts={pullConflictsModal?.conflicts}
        onClose={() => dispatch(hidePullConflictsModal())}
        forcePull={forcePull}
      />
    </>
  );
};

export default GitNotPulled;
