import './style.css';
import { useBooleanFlagValue } from '@openfeature/react-sdk';
import { ReactFlowProvider } from '@xyflow/react';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import React, { FC, useEffect, useRef, useState } from 'react';
import { HotKeys } from 'react-hotkeys';
import { useSelector } from 'react-redux';

import ButtonBar from './ButtonBar';
import { codegen } from './codegen';
import { FlowConfig } from './config.types';
import { useCustomNodes, useWebSocketConnection } from './hooks';
import NodeResultPreview from './nodes/NodeResultPreview';
import { AsNodesWithGateway } from './nodes/types';
import Prototype from './Prototype';
import { flowConfigToFlowData, flowDataToFlowConfig } from './transformation';
import { FlowData } from './types';
import { saveNotebook } from '../../../../../redux/workbench/modules/notebook.module';
import { changeCodeContent } from '../../../../../redux/workbench/modules/notebook.source.module';
import { RootState, useAppDispatch } from '../../../../../store/store';
import {
  connectionCompleted,
  edgesChanged,
  flowOpened,
  nodesChanged,
  pathSelectionCleared,
  selectFlow,
  selectSelectedFlow,
  selectSelectedFlowPath,
  subflowDeselected,
} from '../../../../../store/workbench/flowDesigner.slice';
import {
  JUPYTER_CONTENT_TYPE,
  Session,
} from '../../../../../store/workbench/state.types';
import Busy from '../../../../atoms/busy/Busy';
import ErrorBoundary from '../../../../pages/error-boundary/ErrorBoundary';
import CloseConfirm from '../../../part-right/editor/close-confirm/CloseConfirm.container';

export type Props = {
  path: string;
  content: string;
  unsavedChanges: boolean;
  showCloseConfirm: boolean;
  paneId: string;
  session?: Session;
};

const FlowDesigner: FC<Props> = ({
  path,
  content,
  unsavedChanges,
  showCloseConfirm,
  paneId,
  session,
}) => {
  const flowDesignerEnabled = useBooleanFlagValue('flow-designer', false);
  const dispatch = useAppDispatch();

  const flow = useSelector((state: RootState) => selectFlow(state, path));
  const selectedFlow = useSelector((state: RootState) =>
    selectSelectedFlow(state, path)
  );
  const selectedFlowPath = useSelector((state: RootState) =>
    selectSelectedFlowPath(state, path)
  );

  const {
    data: nodeDefinitions,
    isSuccess: isNodeDefinitionsSuccess,
  } = useCustomNodes();

  useEffect(() => {
    // FIXME this initializes the redux state, but this is not a good way to do it
    //   This MUST only happen once as otherwise
    if (isNodeDefinitionsSuccess && !flow) {
      let initialFlowData: FlowData;
      try {
        const parsedContent: FlowConfig = JSON.parse(content);
        initialFlowData = flowConfigToFlowData(
          parsedContent,
          path,
          nodeDefinitions || []
        );
      } catch (e) {
        console.warn(e);
      }

      dispatch(flowOpened({ filePath: path, flow: initialFlowData }));
    }
  }, [
    dispatch,
    path,
    content,
    flow,
    nodeDefinitions,
    isNodeDefinitionsSuccess,
  ]);

  // FIXME this is just to save, remove later
  useEffect(() => {
    if (!flow) return;

    const cleanedFlow: FlowConfig = flowDataToFlowConfig(flow);

    dispatch(
      changeCodeContent({
        path,
        content: JSON.stringify(cleanedFlow),
      })
    );
  }, [dispatch, path, flow]);

  const [selectedNode, setSelectedNode] = useState<AsNodesWithGateway | null>(
    null
  );
  const [activeSidebarTab, setActiveSidebarTab] = useState<'source' | 'log'>(
    'log'
  );

  const { log, sendExecuteRequest } = useWebSocketConnection(
    session?.id,
    session?.kernel?.id
  );

  // Filled in a callback of the <AceEditor/>
  const keyMap = {
    save: 'command+s',
  };
  const keyHandlers = {
    save: (e) => {
      // @ts-ignore
      dispatch(saveNotebook(path, content, 'file'));
      e.preventDefault();
    },
  };

  // Is the close confirm supposed to be shown?
  if (showCloseConfirm) {
    return (
      <CloseConfirm
        path={path}
        content={content}
        paneId={paneId}
        type={JUPYTER_CONTENT_TYPE.FILE}
      />
    );
  }

  if (!flowDesignerEnabled) {
    return <div>Flow Designer disabled</div>;
  }

  if (!selectedFlow) {
    return <Busy isBusy />;
  }

  const source = codegen(selectedFlow, nodeDefinitions || []).join('\n');

  return (
    <div className={'code-content'}>
      <HotKeys className={'hotkeys'} keyMap={keyMap} handlers={keyHandlers}>
        <ButtonBar
          path={path}
          content={content}
          unsavedChanges={unsavedChanges}
          toggleSource={() => setActiveSidebarTab('source')}
          toggleLog={() => setActiveSidebarTab('log')}
          sendExecuteRequest={() =>
            sendExecuteRequest(codegen(flow, nodeDefinitions || []).join('\n'))
          }
          onGoBackFlowPath={() =>
            dispatch(
              subflowDeselected({
                filePath: path,
              })
            )
          }
          onClearFlowPath={() =>
            dispatch(
              pathSelectionCleared({
                filePath: path,
              })
            )
          }
          activeSidebarTab={activeSidebarTab}
        />

        <ReactFlowProvider>
          <div style={{ height: '100%', display: 'flex' }}>
            <div
              style={{
                height: '100%',
                width: '70%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Prototype
                key={selectedFlow.id}
                filePath={path}
                nodes={selectedFlow.nodes}
                edges={selectedFlow.edges}
                onNodesChanged={(changes) => {
                  dispatch(
                    nodesChanged({
                      filePath: path,
                      changes: changes,
                      selectedFlowPath,
                    })
                  );
                }}
                onEdgesChanged={(changes) =>
                  dispatch(
                    edgesChanged({
                      filePath: path,
                      changes,
                      selectedFlowPath,
                    })
                  )
                }
                onConnect={(connection) =>
                  dispatch(
                    connectionCompleted({
                      filePath: path,
                      selectedFlowPath,
                      connection,
                    })
                  )
                }
                setSelectedNode={setSelectedNode}
              />
              <ErrorBoundary>
                <NodeResultPreview
                  selectedNode={selectedNode}
                  flowPath={path}
                />
              </ErrorBoundary>
            </div>
            {/* Sidebar for Source/Log */}
            <div
              style={{
                height: '100%',
                color: 'white',
                backgroundColor: 'rgb(47, 47, 47)',
                width: '30%',
                padding: '8px',
                borderLeft: 'solid grey 1px',
                overflow: 'auto',
              }}
            >
              <h3 style={{ color: '#fff' }}>
                {activeSidebarTab === 'source' && 'Source'}
                {activeSidebarTab === 'log' && 'Log'}
              </h3>
              <div style={{ marginTop: '10px' }}>
                {activeSidebarTab === 'source' && <pre>{source}</pre>}
                {activeSidebarTab === 'log' && <pre>{log}</pre>}
              </div>
            </div>
          </div>
        </ReactFlowProvider>
      </HotKeys>
    </div>
  );
};

export default FlowDesigner;
