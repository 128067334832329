import { ResourceNames } from 'common/dist/types/utils';

// do not allow .asr, otherwise all hell will break loose
const directoryRegex = /^[a-zA-Z0-9- ]+$/;

/**
 * Validates the repository name
 * 'true' = valid
 * @param name
 * @param resourceNames
 * @param isResourceNamesLoading
 * @returns {string|boolean}
 */
export function validateRepositoryName(
  name: string,
  resourceNames: ResourceNames,
  isResourceNamesLoading: boolean
) {
  // No empty names
  if (!name) {
    return 'Please enter a name';
  }
  // Maximum length of 255 characters
  if (name && name.length > 255) {
    return 'At most 255 characters are allowed';
  }
  // Specify the allowed characters
  if (!directoryRegex.test(name)) {
    return 'Please enter valid name / remove invalid characters';
  }

  // Check if name already exists in resource names (Apps / CodeCapsules / PlainRepositories)
  if (isResourceNamesLoading) {
    return false;
  } else if (resourceNames && Object.values(resourceNames).includes(name)) {
    return 'A repository with the given name already exists!';
  }

  return true;
}

/**
 * Validates the repository name
 * 'true' = valid
 * @param slug
 * @param slugNames
 * @param isSlugNamesLoading
 * @returns {string|boolean}
 */
export function validateRepositorySlug(
  slug: string,
  slugNames: ResourceNames,
  isSlugNamesLoading: boolean
) {
  // No empty names
  if (!slug) {
    return 'Please enter a slug.';
  }
  // Minimum length of 3 characters
  if (slug && slug.length < 3) {
    return 'The slug must be at least 3 characters long.';
  }
  // Minimum length of 64 characters
  if (slug && slug.length > 64) {
    return '64 characters are the maximum length.';
  }
  // Check if slug already exists
  if (isSlugNamesLoading) {
    return false;
  } else if (slugNames && Object.values(slugNames).includes(slug)) {
    return 'A repository with the given slug already exists!';
  }

  return true;
}

/**
 * Validates the repository description
 * 'true' = valid
 * @param value
 * @returns {string|boolean}
 */
export function validateRepositoryDescription(value) {
  // Maximum length of 255 characters
  if (value && value.length > 255) {
    return 'At most 255 characters are allowed';
  }

  return true;
}

/**
 * Validates the repository type
 * 'true' = valid
 * @param value
 * @returns {string|boolean}
 */
export function validateRepositoryType(value) {
  if (!value || value === '') {
    return 'Please select a repository type';
  }

  return true;
}

/**
 * Valides the selected habitat
 * 'true' = valid
 * @param value
 * @param selectedType
 */
export function validateRepositoryHabitat(value) {
  if (!value || !value.code) {
    return 'Please select a Habitat';
  }

  return true;
}
