// --- Constants
const routePrefix = '/app';

export const mainTabSubPaths = {
  models: 'models',
  apps: 'apps',
  workbench: 'workbench',
  collab: 'collab',
  orchestration: 'orchestration',
  data: 'data',
  assistants: 'assistants',
};

// --- Derived paths
const mainTabs = {
  models: routePrefix + '/' + mainTabSubPaths.models,
  apps: routePrefix + '/' + mainTabSubPaths.apps,
  workbench: routePrefix + '/' + mainTabSubPaths.workbench,
  collab: routePrefix + '/' + mainTabSubPaths.collab,
  orchestration: routePrefix + '/' + mainTabSubPaths.orchestration,
  data: routePrefix + '/' + mainTabSubPaths.data,
  assistants: routePrefix + '/' + mainTabSubPaths.assistants,
};

// --- Export object
export const routes = {
  mainTabs,
  noMainTabs: routePrefix + '/noPermissions',
};
