import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { useCodeCapsuleExecutions } from '../../../core/api/codeCapsules';
import Busy from '../../atoms/busy/Busy';
import {
  FormattedDateTime,
  FormattedRelativeTimeConvenient,
} from '../../atoms/formatted-date-time/FormattedDateTime';
import { CodeCapsuleDetailsRouteParams } from '../../index/routes';
import { orchestrationRoutes } from '../../orchestration/routes';

const Executions: FC = () => {
  const history = useHistory();
  const { codeCapsuleCode } = useParams<CodeCapsuleDetailsRouteParams>();

  const {
    data: executions,
    error,
    isError,
    isSuccess,
    isLoading,
  } = useCodeCapsuleExecutions(codeCapsuleCode);

  const renderLoading = () => {
    return (
      <div className={'code-capsule-container executions-container'}>
        <Busy isBusy />
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <div className={'code-capsule-container executions-container'}>
        <div className={'executions-empty'}>
          <div className={'executions-empty-elements'}>
            <div className={'executions-empty-pic'}>&nbsp;</div>
            <span className={'headline'}>
              There are no Executions for this Code Capsule yet
            </span>
            <span className={'description'}>
              Once this Code Capsule was executed, the results will be listed
              here
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderLoaded = () => {
    return (
      <div className={'code-capsule-container executions-container'}>
        <div className={'code-capsules custom-table'}>
          <div className={'ct-row ct-headline'}>
            <div
              className={'ct-col ct-col-200px'}
              style={{
                justifyContent: 'start',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                margin: 'auto',
              }}
            >
              <span>Version Number</span>
            </div>
            <div
              className={'ct-col ct-col-130px'}
              style={{
                justifyContent: 'start',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                margin: 'auto',
              }}
            >
              <span>Status</span>
            </div>
            <div
              className={'ct-col ct-col-200px'}
              style={{
                justifyContent: 'start',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                margin: 'auto',
              }}
            >
              <span>Finished At</span>
            </div>
            <div
              className={'ct-col ct-col-130px'}
              style={{
                justifyContent: 'start',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                margin: 'auto',
              }}
            >
              <span>Job Details</span>
            </div>
          </div>

          {executions?.map((execution, index) => (
            <div className={'ct-row'} key={index}>
              <div
                className={'ct-col ct-col-200px'}
                style={{
                  justifyContent: 'start',
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  margin: 'auto',
                }}
              >
                <div className={'ct-value'}>
                  <span>{execution.codeCapsuleVersion?.number}</span>
                </div>
              </div>

              <div
                className={'ct-col ct-col-130px'}
                style={{
                  justifyContent: 'start',
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  margin: 'auto',
                }}
              >
                <div className={'ct-value'}>
                  <span>{execution.status}</span>
                </div>
              </div>
              <div
                className={'ct-col ct-col-200px'}
                style={{
                  justifyContent: 'start',
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  margin: 'auto',
                }}
              >
                <div
                  className={'ct-value'}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <>
                    <strong
                      style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      <FormattedRelativeTimeConvenient
                        date={new Date(execution.createdAt || '')}
                        tooltip={false}
                      />
                    </strong>
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      <FormattedDateTime
                        date={new Date(execution.createdAt || '')}
                      />
                    </span>
                  </>
                </div>
              </div>

              <div
                className={'ct-col ct-col-130px'}
                style={{
                  justifyContent: 'start',
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  margin: 'auto',
                }}
              >
                <div className={'ct-value executions-link'}>
                  <span
                    onClick={() =>
                      history.push(
                        `${orchestrationRoutes.basePath}/${orchestrationRoutes.jobDetails.path}/${execution.jobCode}`
                      )
                    }
                  >
                    Open Details
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderError = () => {
    return (
      <div className={'code-capsule-container executions-container'}>
        <span className={'error'}>
          <FormattedMessage {...error.formattedMessage} />
        </span>
      </div>
    );
  };

  if (isSuccess) {
    if (executions.length === 0) {
      return renderEmpty();
    } else {
      return renderLoaded();
    }
  } else if (isError) return renderError();
  else if (isLoading) return renderLoading();
  else return <div />;
};

export default Executions;
