import classNames from 'classnames';
import messages from 'common/dist/messages/dataManagement';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import styles from './styles.module.scss';
import { moveObject } from '../../../../redux/modules/data.s3.module';
import { ButtonProps } from '../../../atoms/button/Button';
import { IntlTextInputLine } from '../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import Modal from '../../../organisms/modal/Modal';

type Props = {
  isOpen: boolean;
  closeDialog: () => void;
  fileName: string;
  filePath: string;
  fileBucket: string;
  fileDataSourceCode: string;
};

const nameRegex = /^[a-zA-Z_0-9-.]+$/;

const ModalDialog: FC<Props> = ({
  isOpen,
  closeDialog,
  fileName,
  fileBucket,
  filePath,
  fileDataSourceCode,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    reset,
  } = useForm({
    defaultValues: {
      ['name']: { inputValue: fileName },
    },
    mode: 'all',
  });

  useEffect(() => {
    if (isOpen) {
      reset({ ['name']: { inputValue: fileName } });
    }
  }, [isOpen, fileName, reset]);

  const onSubmit = (data: { ['name']: { inputValue: string } }) => {
    dispatch(
      moveObject(
        fileBucket,
        //@ts-ignore
        filePath,
        fileBucket,
        filePath.replace(new RegExp(`${fileName}$`), data['name'].inputValue),
        fileDataSourceCode
      )
    );
    closeDialog();
  };

  const buttons: ButtonProps[] = [
    {
      color: 'white',
      label: 'Cancel',
      onClick: closeDialog,
    },
    {
      color: 'primary',
      label: 'OK',
      disabled: !isValid || !isDirty,
      onClick: handleSubmit(onSubmit),
    },
  ];
  return (
    <Modal show={isOpen} buttons={buttons} hideModal={closeDialog}>
      <div className={classNames('modal-parent', styles.renameModal)}>
        <div
          className={classNames('modal-body-headline', styles.modalHeadline)}
        >
          <span>Rename file {fileName}</span>
        </div>
        <div>
          <Controller
            name={`${'name'}.inputValue`}
            control={control}
            rules={{
              required: messages.msgRenameErrorEmpty.defaultMessage,
              validate: (value) => {
                if (value.length < 1) {
                  return messages.msgRenameErrorEmpty.defaultMessage;
                }
                if (!nameRegex.test(value)) {
                  return messages.msgRenameErrorValidName.defaultMessage;
                }
                return true;
              },
            }}
            render={({ field, fieldState }) => {
              const { ref, ...rest } = field; // extract ref to pass as inputRef
              return (
                <IntlTextInputLine
                  placeholder={'Name'}
                  {...rest}
                  {...fieldState}
                  inputRef={ref}
                  error={fieldState.error?.message}
                  isTouched={true}
                />
              );
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalDialog;
