import { RepoType } from 'common/dist/types/repository';
import React, { Component } from 'react';
import { HotKeys } from 'react-hotkeys';

import ButtonBar from './ButtonBar.container';
import NotebookCells from './NotebookCells';
import {
  AltaSigmaCell,
  JUPYTER_CONTENT_TYPE,
  Session,
} from '../../../../../../store/workbench/state.types';
import CloseConfirm from '../../../../part-right/editor/close-confirm/CloseConfirm.container';
import WarningSaveNotebookModal from '../../../../part-right/wizards/file/WarningSaveNotebookModal';
import { WARNING_SIZE } from '../../../../utils';

interface Props {
  name: string;
  path: string;
  content: {
    metadata: {};
    cells: AltaSigmaCell[];
  };
  selectedCells: string[];
  session?: Session;
  unsavedChanges?: boolean;
  openSocket(...args: unknown[]): unknown;
  saveNotebook(...args: unknown[]): unknown;
  selectCells: (path: string, cellIds: string[]) => void;
  executeCells: (path: string, sessionId: string, cellId: string[]) => void;
  changeSource(...args: unknown[]): unknown;
  parentRepository?: {
    repoCode?: string;
    repoType?: RepoType;
    codeCapsuleCode?: string;
    appCode?: string;
  };
  requestCodeCompletion: (
    path: string,
    cellId: string,
    sessionId: string,
    currentRowSource: string,
    column: number,
    row: number
  ) => void;
  clearCodeCompletion: (path: string, cellId: string) => void;
  showCloseConfirm?: boolean;
  paneId: string;
  getSessionDetails(...args: unknown[]): unknown;
  metadata: Record<string, unknown>;
}

export default class NotebookContent extends Component<Props> {
  // Timer to fetch the sessions every x seconds
  fetchSessionInterval = undefined;
  FETCH_SESSION_INTERVAL = 5000; // every 3 seconds
  private notebookContentRef: React.RefObject<unknown>;

  state = {
    showSaveWarningModal: false,
  };

  constructor(props) {
    super(props);
    if (props.session && props.session.kernel) {
      this.props.openSocket(props.session.id, props.session.kernel.id); // Probably this is never called (since during constructing there is no session set yet)
    }

    this.notebookContentRef = React.createRef();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // If nextProps don't contain session information -> no need to open a socket
    // If there is no socket opened for this notebook -> Open a socket
    // If there is a session that's different from the next session id -> Open a new socket
    if (
      nextProps.session &&
      nextProps.session.kernel &&
      (!this.props.session || this.props.session.id !== nextProps.session.id)
    ) {
      this.props.openSocket(nextProps.session.id, nextProps.session.kernel.id);
    }
  }

  componentDidMount() {
    const { getSessionDetails } = this.props;
    this.fetchSessionInterval = setInterval(() => {
      const { session } = this.props;
      if (session && session.id) {
        getSessionDetails(session.id);
      }
    }, this.FETCH_SESSION_INTERVAL);
  }

  componentWillUnmount() {
    clearTimeout(this.fetchSessionInterval);
  }

  showSaveWarningModal = (show: boolean) => {
    this.setState({ showSaveWarningModal: show });
  };

  calculateContentSize = (content) => {
    return new TextEncoder().encode(JSON.stringify(content)).length;
  };

  keyMap = {
    executeCell: 'shift+enter',
    saveNotebook: 'command+s',
  };

  keyHandlers = {
    executeCell: (e) => {
      // Set focus BEFORE new cell may be created to avoid stealing focus from new cells.
      const hotkeysRef: HTMLElement = document.querySelector('.hotkeys');
      hotkeysRef.focus();
      this.props.executeCells(
        this.props.path,
        this.props.session ? this.props.session.id : null,
        this.props.selectedCells
      );
      e.preventDefault();
    },
    saveNotebook: (e) => {
      const contentSize = this.calculateContentSize(this.props.content);

      if (contentSize > WARNING_SIZE) {
        this.showSaveWarningModal(true);
      } else {
        this.props.saveNotebook(this.props.path, this.props.content);
      }
      e.preventDefault();
    },
  };

  render() {
    const {
      name,
      path,
      content,
      session,
      selectedCells,
      unsavedChanges,
      parentRepository,
      selectCells,
      executeCells,
      changeSource,
      requestCodeCompletion,
      clearCodeCompletion,
      showCloseConfirm,
      paneId,
      metadata,
    } = this.props;

    const contentSize = this.calculateContentSize(this.props.content);

    // Is the close confirm supposed to be shown?
    if (showCloseConfirm) {
      return (
        <CloseConfirm
          path={path}
          content={content}
          paneId={paneId}
          type={JUPYTER_CONTENT_TYPE.NOTEBOOK}
        />
      );
    }

    return (
      <div className={'notebook-content'}>
        <HotKeys
          className={'hotkeys'}
          keyMap={this.keyMap}
          handlers={this.keyHandlers}
        >
          <WarningSaveNotebookModal
            isOpen={this.state.showSaveWarningModal}
            onRequestSubmit={() => {
              this.props.saveNotebook(this.props.path, this.props.content);
              this.showSaveWarningModal(false);
            }}
            onRequestClose={() => {
              this.showSaveWarningModal(false);
            }}
          />
          <ButtonBar
            name={name}
            path={path}
            content={content}
            contentSize={contentSize}
            session={session}
            selectedCells={selectedCells}
            unsavedChanges={unsavedChanges}
            parentRepository={parentRepository}
            paneId={paneId}
            showSaveWarningModal={this.showSaveWarningModal}
          />
          <NotebookCells
            content={content}
            path={path}
            metadata={metadata}
            session={session}
            selectedCells={selectedCells}
            selectCells={selectCells}
            executeCells={executeCells}
            changeSource={changeSource}
            requestCodeCompletion={requestCodeCompletion}
            clearCodeCompletion={clearCodeCompletion}
          />
        </HotKeys>
      </div>
    );
  }
}
