import augurMsgs from 'common/dist/messages/augurs';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { HierarchyPointNode } from 'd3';
import React, { FC, useReducer } from 'react';
import { FormattedMessage } from 'react-intl';

import { TreeNode } from './Node';
import styles from './styles.module.scss';
import Tree from './Tree';
import { elbowLink, linearLinkThickness } from './treeChart/link';
import * as tree from './treeChart/tree';
import { expansionStrategy, getPath } from './treeChart/tree';
import TreePath, { Path } from './TreePath';
import { ClassificationTreeConfig, ClassificationTreeReportData } from './type';
import { buildSingleReportWrapper } from '../../common/SingleReportWrapper';
import { ReportElementProps } from '../../types/meta';

export type Props = ClassificationTreeReportData & ClassificationTreeConfig;

export type BinaryTreeState = {
  selectedNode: HierarchyPointNode<TreeNode>;
  selectedNodePath: Path;
  previewNode: HierarchyPointNode<TreeNode>;
  previewNodePath: Path;
};

export const selectNode = (node: HierarchyPointNode<TreeNode>) => ({
  type: 'selectNode',
  payload: node,
});

export const deselectNode = () => ({
  type: 'deselectNode',
});

export const showSelectedNodePath = () => ({
  type: 'showSelectedNodePath',
});

export const hideSelectedNodePath = () => ({
  type: 'hideSelectedNodePath',
});

export const selectPreviewNode = (node: HierarchyPointNode<TreeNode>) => ({
  type: 'selectPreviewNode',
  payload: node,
});

export const deselectPreviewNode = () => ({
  type: 'deselectPreviewNode',
});

export const showPreviewNodePath = () => ({
  type: 'showPreviewNodePath',
});

export const hidePreviewNodePath = () => ({
  type: 'hidePreviewNodePath',
});

export const reducer = (
  state: BinaryTreeState,
  action: ToBeRefined
): BinaryTreeState => {
  switch (action.type) {
    case 'selectNode':
      return {
        ...state,
        selectedNode: action.payload,
      };
    case 'deselectNode':
      return {
        ...state,
        selectedNode: {} as HierarchyPointNode<TreeNode>,
      };
    case 'selectPreviewNode':
      return {
        ...state,
        previewNode: action.payload,
      };
    case 'deselectPreviewNode':
      return {
        ...state,
        previewNode: {} as HierarchyPointNode<TreeNode>,
      };
    case 'showSelectedNodePath':
      return {
        ...state,
        selectedNodePath: getPath(state.selectedNode),
      };
    case 'hideSelectedNodePath':
      return {
        ...state,
        selectedNodePath: tree.DEFAULT_PATH,
      };
    case 'showPreviewNodePath':
      return {
        ...state,
        previewNodePath: getPath(state.previewNode),
      };
    case 'hidePreviewNodePath':
      return {
        ...state,
        previewNodePath: tree.DEFAULT_PATH,
      };
    default:
      return state;
  }
};

export const BinaryTreeShadowModel: FC<Props> = (props: Props) => {
  const { data } = props;

  const [state, dispatch] = useReducer(reducer, {
    selectedNode: {} as HierarchyPointNode<TreeNode>,
    selectedNodePath: [] as Path,
    previewNode: {} as HierarchyPointNode<TreeNode>,
    previewNodePath: [] as Path,
  } as BinaryTreeState);

  return (
    <div className={styles.pageSelection} style={{ height: '100%' }}>
      <div className={styles.selectionWrap}>
        <div className={styles.selectionInfo}>
          <TreePath state={state} />
        </div>
        <div className={styles.selectionGraph}>
          <div className={styles.selectionGraphHistory}>
            <FormattedMessage {...augurMsgs.msgModelViewerBinaryTreeSeeMore} />
          </div>
          <Tree
            state={state}
            dispatch={dispatch}
            data={{ root: data.root }}
            linkShapeFunc={elbowLink(1)}
            linkThicknessFunc={linearLinkThickness([0, 100], [1, 4.5])}
            adjustTreeFunc={expansionStrategy}
            animationDuration={{
              mount: { delay: 0, duration: 200 },
              update: { delay: 0, duration: 200 },
              exit: { delay: 0, duration: 200 },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const BinaryTreeShadowModelSingle: FC<
  ReportElementProps<ClassificationTreeReportData, ClassificationTreeConfig>
> = buildSingleReportWrapper<
  ClassificationTreeReportData,
  ClassificationTreeConfig
>(BinaryTreeShadowModel);

export default BinaryTreeShadowModel;
