import { defineMessages } from 'react-intl';

export default defineMessages({
  msgSecretKey: {
    id: 'dataman.s3.auth.secretKey',
    defaultMessage: 'Secretkey',
  },
  msgAccessKey: {
    id: 'dataman.s3.auth.accessKey',
    defaultMessage: 'Accesskey',
  },
  msgS3UploadHeader: {
    id: 'dataMan.s3.upload.header',
    defaultMessage: 'Upload File to S3',
  },
  msgS3UploadBucketTitle: {
    id: 'dataMan.s3.upload.bucketTitle',
    defaultMessage: 'Select Bucket',
  },
  msgS3UploadBucketDescription: {
    id: 'dataMan.s3.upload.bucketDescription',
    defaultMessage: 'Select the Bucket you want to upload your data to.',
  },
  msgS3UploadPathTitle: {
    id: 'dataMan.s3.upload.pathTitle',
    defaultMessage: 'Select Path',
  },
  msgS3UploadPathDescription: {
    id: 'dataMan.s3.upload.pathDescription',
    defaultMessage:
      'Select the Path you want to upload your files to. It will be added as a prefix to the S3 keys.',
  },
  msgS3UploadUploadTitle: {
    id: 'dataMan.s3.upload.uploadTitle',
    defaultMessage: 'Select Files',
  },
  msgS3UploadUploadDescription: {
    id: 'dataMan.s3.upload.uploadDescription',
    defaultMessage:
      'Select the Files you want to upload. When clicking the Upload\n' +
      'button, the files will be uploaded into the AltaSigma system.',
  },
  msgS3UploadConfirm: {
    id: 'dataMan.s3.upload.confirm',
    defaultMessage: 'Upload File',
  },
  dataManS3UploadValidationErrorNoBucket: {
    id: 'dataMan.s3.upload.validation_error.no_bucket',
    defaultMessage: 'Please select a Bucket',
  },
  dataManS3UploadValidationErrorMaxPath: {
    id: 'dataMan.s3.upload.validation_error.max_path',
    defaultMessage:
      'Please choose a shorter path. (maximum {pathMaxLength} characters)',
  },
  dataManS3UploadValidationErrorPathSeparators: {
    id: 'dataMan.s3.upload.validation_error.path_separators',
    defaultMessage: 'Please remove any duplicate directory separators /',
  },
  dataManS3UploadValidationErrorNoFile: {
    id: 'dataMan.s3.upload.validation_error.no_file',
    defaultMessage: 'Please pick a file to upload',
  },
  dataManS3UploadValidationErrorPathExists: {
    id: 'dataMan.s3.upload.validation_error.path_exists',
    defaultMessage: 'Path already exists',
  },
  msgS3PermissionsHeadline: {
    id: 'dataman.s3.auth.permissions.headline',
    defaultMessage: 'S3 Bucket Permissions',
  },
  msgS3PermissionsRowDataSourceName: {
    id: 'dataman.s3.auth.permissions.row.data_source_name',
    defaultMessage: 'Data Source Name:',
  },
  msgS3PermissionsRowBucketName: {
    id: 'dataman.s3.auth.permissions.row.bucket_name',
    defaultMessage: 'Bucket Name:',
  },
  msgS3PermissionsGroupSubTitle: {
    id: 'dataman.s3.auth.permissions.group.sub_title',
    defaultMessage: 'Assign group-wise permissions to the Bucket',
  },
  msgS3PermissionsGroupDescription: {
    id: 'dataman.s3.auth.permissions.group.description',
    defaultMessage:
      'If you are a member of this group you have view/edit rights on this Bucket',
  },
  msgS3PermissionsUserSubTitle: {
    id: 'dataman.s3.auth.permissions.user.sub_title',
    defaultMessage: 'Assign user-wise permissions to the Bucket',
  },
  msgCassandraPermissionsHeadline: {
    id: 'dataman.cassandra.auth.permissions.headline',
    defaultMessage: 'Cassandra Keyspace Permissions',
  },
  msgCassandraPermissionsRowKeyspaceName: {
    id: 'dataman.cassandra.auth.permissions.row.keyspace_name',
    defaultMessage: 'Keyspace Name:',
  },
  msgCassandraPermissionsRowAugurName: {
    id: 'dataman.cassandra.auth.permissions.row.augur_name',
    defaultMessage: 'Augur Name:',
  },
  msgCassandraPermissionsGroupSubTitle: {
    id: 'dataman.cassandra.auth.permissions.group.sub_title',
    defaultMessage: 'Assign group-wise permissions to the Cassandra Keyspace',
  },
  msgCassandraPermissionsGroupDescription: {
    id: 'dataman.cassandra.auth.permissions.group.description',
    defaultMessage:
      'If you are a member of this group you have alter/create/... rights on this Cassandra Keyspace',
  },
  msgCassandraPermissionsUserSubTitle: {
    id: 'dataman.cassandra.auth.permissions.user.sub_title',
    defaultMessage: 'Assign user-wise permissions to the Cassandra Keyspace',
  },
  msgCassandraUploadCommitDataTitle: {
    id: 'dataMan.cassandra.upload.commit.data.title',
    defaultMessage: 'Validate your data',
  },
  msgCassandraUploadCommitDataDescription: {
    id: 'dataMan.cassandra.upload.commit.data.description',
    defaultMessage:
      'Validate the data as well as the estimated column types. Click the edit button to edit.',
  },
  msgCassandraUploadCommitDataError: {
    id: 'dataMan.cassandra.upload.commit.data.error',
    defaultMessage:
      "Something went wrong, the data can't be previewed. Please check your settings and retry.",
  },
  msgCassandraUploadCommitKeysTitle: {
    id: 'dataMan.cassandra.upload.commit.keys.title',
    defaultMessage: 'Select Clustering & Partition Keys',
  },
  msgCassandraUploadCommitKeysDescription: {
    id: 'dataMan.cassandra.upload.commit.keys.description',
    defaultMessage:
      'Drag and drop the columns you want to use for the Clustering and the Partition Keys for your new table.',
  },
  msgErrorTablePreview: {
    id: 'dataMan.s3.table.preview.error',
    defaultMessage: ' An error occured while reading the table preview:',
  },
  msgConfirmationDeleteTitle: {
    id: 'dataMan.s3.confirmation.delete.title',
    defaultMessage: 'Delete Object',
  },
  msgConfirmationDeleteDescription: {
    id: 'dataMan.s3.confirmation.delete.description',
    defaultMessage:
      'You are about to delete this object. Please confirm that you are sure.',
  },
  msgRenameErrorEmpty: {
    id: 'dataman.s3.error.empty',
    defaultMessage: 'The name must not be empty',
  },
  msgRenameErrorValidName: {
    id: 'dataman.s3.error.valid.name',
    defaultMessage: 'Enter a valid name',
  },
});
