import classNames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';

import styles from './styles.module.scss';

export type Props = {
  submitSearchQuery: (search: string | undefined) => void;
  initialValue?: string;
  submitOnChange?: boolean;
};

const TextInputSearch: FC<Props> = ({
  submitSearchQuery,
  initialValue = '',
  submitOnChange,
}) => {
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevent default button action
    submitSearchQuery(inputRef.current?.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default Enter key action
      handleClick(e as unknown as React.MouseEvent<HTMLButtonElement>);
    }
  };

  const reset = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevent default button action
    setValue('');
    submitSearchQuery('');
  };

  return (
    <div className={styles.TextInputSearch}>
      <div className={styles.SearchBarInputGroup}>
        <div className={styles.SearchBarInputGroupButton}>
          <button
            data-testid={'SearchBtn'}
            type='button'
            className={styles.SearchBarButton}
            disabled={!value}
            onClick={handleClick}
          >
            <span className={classNames('icon-search', styles.iconSearch)} />
          </button>
        </div>
        <input
          data-testid={'Search'}
          ref={inputRef}
          type='text'
          className={styles.TextInputSearchInputGroupField}
          placeholder={'Search'}
          autoComplete='off'
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            if (submitOnChange) {
              submitSearchQuery(e.target.value);
            }
          }}
          onKeyDown={handleKeyDown}
        />
        <div className={styles.fixedContainer}>
          {value && (
            <div className={styles.SearchBarInputGroupButton}>
              <button
                data-testid={'SearchReset'}
                type='button'
                className={styles.SearchBarButton}
                onClick={reset}
              >
                <FiX size={16} className={styles.SearchBarIconCross} />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TextInputSearch;
