import { Handle, Position } from '@xyflow/react';
import React from 'react';

import styles from './styles.module.scss';
import { AS_DATA_TYPES, AsDataTypes } from '../data.types';
import { ParameterDefinition } from '../types';

export const getNodeTypeStyle = (type: AsDataTypes) => {
  switch (type) {
    case AS_DATA_TYPES.BOOL:
      return styles.boolean;
    case AS_DATA_TYPES.NUMBER:
      return styles.number;
    case AS_DATA_TYPES.STRING:
      return styles.string;
    case AS_DATA_TYPES.TABLE:
      return styles.table;
    default:
      return styles.any;
  }
};

export function generateHandles(
  parameters: ParameterDefinition[],
  handleType: 'target' | 'source'
) {
  const getPositioningPercentage = (index: number): string =>
    `${(100 / (parameters.length + 1)) * (index + 1)}%`;
  return (
    <>
      {parameters.map((parameter, i) => (
        <Handle
          key={parameter.id}
          id={parameter.id}
          type={handleType}
          className={getNodeTypeStyle(parameter.type)}
          style={{ left: getPositioningPercentage(i) }}
          position={handleType === 'target' ? Position.Top : Position.Bottom}
        />
      ))}
    </>
  );
}
