import { PYTHON3_NOTEBOOK } from 'common/dist/constants/notebookTypes';
import React, { FC, useEffect } from 'react';

import Button from '../../../atoms/button/Button';
import { ContentElement } from '../../../organisms/workbench-browser/generic-file-browser/GenericFileBrowser';
import { WORKBENCH_FILENAMES } from '../../part-right/config';
import { RepoMeta } from '../../part-right/repository/info/types/types';

export type Props = {
  selectedContent: ContentElement[];
  selectedDirPath: string;
  repoMeta: RepoMeta;
  loadRepoMeta: (path: string) => void;
  addNotebook: (
    notebookName: string,
    dirPath: string,
    kernelName: string,
    kernelDisplayName: string
  ) => void;
  saveEditNotebook: (
    notebookName: string,
    dirPath: string,
    kernelName: string,
    kernelDisplayName: string,
    notebookOldPath: string
  ) => void;
};

const RepositoryWarning: FC<Props> = ({
  repoMeta,
  loadRepoMeta,
  addNotebook,
  selectedContent,
  selectedDirPath,
  saveEditNotebook,
}) => {
  // Returns the meta file if it exists, and the main.asapp is missing (-> This means the warning needs to be displayed)
  const metaFile = (selectedContent || []).find(
    (sc) => sc.name === WORKBENCH_FILENAMES.REPOSITORY_META
  );

  useEffect(() => {
    if (metaFile && metaFile.path) {
      loadRepoMeta(metaFile.path);
    }
  }, [loadRepoMeta, metaFile]);

  if (!metaFile) return null;

  function renderDepthWarning() {
    // Inside a repository AND folder depth > 5.
    return (
      <div className={'repository-warning-parent'}>
        <span className={'rw-headline'}>
          Warning: Repository depth limit exceeded{' '}
        </span>
        <span className={'rw-description'}>
          The folder depth for repositories was exceeded. AltaSigma only
          supports up to five levels deep.
        </span>
      </div>
    );
  }

  function renderForApp() {
    // Inside a repository AND no main.asapp notebook.
    // --> Check whether it's an app
    return (
      <div className={'repository-warning-parent'}>
        <span className={'rw-headline'}>Warning: Missing Main Notebook</span>
        <span className={'rw-description'}>
          The Main Notebook is required to build AI Apps. Do you want to create
          it?
        </span>
        <div className={'rw-button-container'}>
          <Button
            color={'orange'}
            label={'Create Main Notebook'}
            onClick={() =>
              addNotebook(
                'main.asapp',
                selectedDirPath,
                PYTHON3_NOTEBOOK,
                'Python 3'
              )
            }
          />
        </div>
      </div>
    );
  }

  function renderDeprecatedMainAppFileExtensionWarning() {
    if (!selectedContent) return null; // Fast exit.
    const oldMainAppPath =
      selectedContent.find((item) => item.name === 'main.ipynb')?.path ?? null;

    return (
      <div className={'repository-warning-parent'}>
        <span className={'rw-headline'}>Warning: Deprecated File-Ending</span>
        <span className={'rw-description'}>
          Your Main Notebook seems to have the file-ending ".iypnb", which is
          deprecated and might cause dataloss when opened with the VS Code view.
          Do you want to change it to ".asapp"?
        </span>
        <div className={'rw-button-container'}>
          <Button
            color={'orange'}
            label={'Rename Main Notebook'}
            onClick={() =>
              saveEditNotebook(
                'main.asapp',
                selectedDirPath,
                PYTHON3_NOTEBOOK,
                'Python Default',
                oldMainAppPath
              )
            }
          />
        </div>
      </div>
    );
  }

  // e.g. '/folder/project' -> depth 2
  // paths are prefixed with '/' -> -1
  // warning is rendered inside project folder -> -1
  const projectPathDepth = selectedDirPath.split('/').length - 2;
  if (repoMeta && projectPathDepth > 5) {
    return renderDepthWarning();
  } else if (
    repoMeta &&
    ['app'].includes(repoMeta.repoType) &&
    selectedContent.map((sc) => sc.name).includes('main.ipynb')
  ) {
    return renderDeprecatedMainAppFileExtensionWarning();
  } else if (
    repoMeta &&
    ['app'].includes(repoMeta.repoType) &&
    !selectedContent.map((sc) => sc.name).includes('main.asapp')
  ) {
    return renderForApp();
  } else {
    return null;
  }
};

export default RepositoryWarning;
