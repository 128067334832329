import messages from 'common/dist/messages/dataManagement';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import BackButton from './BackButton';
import { CProps } from './BucketBrowser.container';
import BucketHeadline from './BucketHeadline';
import FileBrowser from './file-browser/FileBrowser';
import ModalDialog from './ModalDialog';
import Button from '../../../atoms/button/Button';
import ConfirmationModal from '../../../organisms/confirmation-modal/ConfirmationModal';
import { dataManagementRoutes } from '../../routes';
import { UPLOAD_WIZARDS } from '../upload/uploadWizards';

export interface Props {
  dataSourceCode: string;
  /** If not provided the container will extract it from the path (normal case) */
  bucket?: string;
  /** If not provided the container will extract it from the path (normal case) */
  bucketPath?: string;
  showBackButton?: boolean;
  showUploadButton?: boolean;
}

export default class BucketBrowser extends Component<
  Props & CProps & RouteComponentProps,
  {
    renameOpen: boolean;
    renameFile: string;
    renamePath: string;
    showDeleteModal: boolean;
    deletePath: string;
  }
> {
  static defaultProps = {
    showBackButton: true,
    showUploadButton: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      renameOpen: false,
      renameFile: '',
      renamePath: '',
      showDeleteModal: false,
      deletePath: '',
    };
  }

  componentDidMount() {
    const {
      fetchBucketContent,
      dataSourceCode,
      bucket,
      bucketPath,
      fetchS3Permissions,
      allowedToRead,
      allowedToWrite,
    } = this.props;
    fetchS3Permissions(dataSourceCode);
    if (allowedToRead || allowedToWrite) {
      fetchBucketContent(dataSourceCode, bucket, bucketPath);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props & CProps & RouteComponentProps>
  ) {
    const {
      fetchBucketContent,
      dataSourceCode,
      bucket,
      bucketPath,
      allowedToRead,
      allowedToWrite,
    } = this.props;
    if (
      (allowedToRead || allowedToWrite) &&
      (allowedToRead !== prevProps.allowedToRead ||
        allowedToWrite !== prevProps.allowedToWrite)
    ) {
      fetchBucketContent(dataSourceCode, bucket, bucketPath);
    }
  }

  renderError() {
    const { error } = this.props;
    return (
      <div className={'bucket-content'}>
        <span className={'error'}>{JSON.stringify(error)}</span>
      </div>
    );
  }

  renderLoaded() {
    const {
      dataSourceCode,
      bucket,
      bucketPath,
      data,
      loading,
      setActiveUploadWizard,
      allowedToRead,
      allowedToWrite,
      showBackButton,
      showUploadButton,
    } = this.props;

    return (
      <div className={'bucket-content'}>
        <ConfirmationModal
          secure={false}
          show={this.state.showDeleteModal}
          payload={{ deletePath: this.state.deletePath }}
          buttonConfirmAction={({ deletePath }) => {
            this.props.deleteObject(
              this.props.bucket,
              deletePath,
              this.props.dataSourceCode
            );
          }}
          hideModal={() =>
            this.setState({ ...this.state, showDeleteModal: false })
          }
          buttonConfirmText={{
            id: 'todo',
            defaultMessage: 'Delete',
          }}
          buttonConfirmColor={'red'}
          description={{
            id: messages.msgConfirmationDeleteDescription.id,
            defaultMessage:
              messages.msgConfirmationDeleteDescription.defaultMessage,
          }}
          headline={{
            id: messages.msgConfirmationDeleteTitle.id,
            defaultMessage: messages.msgConfirmationDeleteTitle.defaultMessage,
          }}
          headlineColor={'red'}
        />
        <ModalDialog
          isOpen={this.state.renameOpen}
          closeDialog={() =>
            this.setState({ ...this.state, renameOpen: false })
          }
          fileName={this.state.renameFile}
          filePath={this.state.renamePath}
          fileBucket={bucket}
          fileDataSourceCode={dataSourceCode}
        ></ModalDialog>
        <div className={'bucket-content-header'}>
          {showBackButton && <BackButton dataSourceCode={dataSourceCode} />}

          <div className={'headline-buttons'}>
            {showUploadButton && allowedToWrite && (
              <Button
                color={'secondary'}
                label={'Upload Data'}
                linkTo={
                  `${dataManagementRoutes.basePath}/s3/${dataSourceCode}/upload?bucketName=${bucket}` +
                  (bucketPath.length > 0 ? `&bucketPath=${bucketPath}` : '')
                }
                onClick={() =>
                  setActiveUploadWizard(UPLOAD_WIZARDS.UPLOAD, undefined)
                }
              />
            )}
          </div>
        </div>

        {(allowedToRead || allowedToWrite) && (
          <>
            <BucketHeadline
              dataSourceCode={dataSourceCode}
              bucket={bucket}
              bucketPath={bucketPath}
              loading={loading}
            />

            <FileBrowser
              bucket={bucket}
              bucketPath={bucketPath}
              dataSourceCode={dataSourceCode}
              data={data}
              loading={loading}
              showRenameModal={(renameFile, renamePath) => {
                this.setState({
                  ...this.state,
                  renameOpen: true,
                  renameFile,
                  renamePath,
                });
              }}
              showDeleteModal={(deletePath) => {
                this.setState({
                  ...this.state,
                  showDeleteModal: true,
                  deletePath,
                });
              }}
            />
          </>
        )}
        {!(allowedToRead || allowedToWrite) && <div>Forbidden</div>}
      </div>
    );
  }

  render() {
    const { error } = this.props;
    if (error) return this.renderError();
    return this.renderLoaded();
  }
}
