import {
  Background,
  Connection,
  Controls,
  Edge,
  EdgeChange,
  getOutgoers,
  MiniMap,
  Node,
  NodeChange,
  ReactFlow,
  useReactFlow,
} from '@xyflow/react';
import React, { FC, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import '@xyflow/react/dist/base.css';
import { useDrop } from 'react-dnd';

import { PersistedNode } from './config.types';
import { AS_DATA_TYPES } from './data.types';
import { edgeTypes } from './edges/types';
import { useCustomNodes } from './hooks';
import { AS_NODE_TYPES, AsNodesWithGateway, nodeTypes } from './nodes/types';
import { Toolbar, ToolbarNode } from './Toolbar';
import { fromPersisted } from './transformation';

export type PrototypeProps = {
  filePath: string;
  nodes: AsNodesWithGateway[];
  edges: Edge[];
  onNodesChanged: (changes: NodeChange<AsNodesWithGateway>[]) => void;
  onEdgesChanged: (changes: EdgeChange[]) => void;
  onConnect: (connection: Connection) => void;
  setSelectedNode: (node: AsNodesWithGateway) => void;
};

const Prototype: FC<PrototypeProps> = ({
  filePath,
  nodes,
  edges,
  onNodesChanged,
  onEdgesChanged,
  onConnect,
  setSelectedNode,
}) => {
  const { getNodes, getEdges, screenToFlowPosition } = useReactFlow();

  const { data: nodeDefinitions } = useCustomNodes();

  const toolbarNodes: ToolbarNode[] = [
    {
      type: AS_NODE_TYPES.CONDITIONAL,
    },
    {
      type: AS_NODE_TYPES.SUBFLOW,
      config: {
        connections: {
          inputs: [],
          outputs: [],
        },
      },
    },
    ...(nodeDefinitions || []).map((x) => ({
      type: AS_NODE_TYPES.PYTHON_NODE,
      name: x.title,
      config: {
        type: x.type,
      },
    })),
  ];

  const [_, drop] = useDrop({
    accept: 'fdNode',
    drop: (item, monitor) => {
      // @ts-ignore fixme-fd
      const node: ToolbarNode = item.node;

      const clientOffset = monitor.getClientOffset();

      const position = screenToFlowPosition({
        x: clientOffset.x,
        y: clientOffset.y,
      });

      const newNode: PersistedNode = {
        id: uuidv4(),
        position,
        type: node.type,
        config: node.config,
      } as PersistedNode;

      const nodeWithData = fromPersisted(
        newNode,
        filePath,
        nodeDefinitions || []
      );

      onNodesChanged([
        {
          type: 'add',
          item: nodeWithData,
        },
      ]);
    },
  });

  const validNoCycle = useCallback(
    (connection: Connection | Edge) => {
      const nodes = getNodes();
      const edges = getEdges();
      const target = nodes.find((node) => node.id === connection.target);
      if (target === undefined) return true;
      const hasCycle = (node: Node, visited = new Set()) => {
        if (visited.has(node.id)) return false;

        visited.add(node.id);

        for (const outgoer of getOutgoers(node, nodes, edges)) {
          if (outgoer.id === connection.source) return true;
          if (hasCycle(outgoer, visited)) return true;
        }
      };

      if (target.id === connection.source) return false;
      return !hasCycle(target);
    },
    [getNodes, getEdges]
  );

  const handleNodeClick = (
    event: React.MouseEvent,
    node: AsNodesWithGateway
  ) => {
    setSelectedNode(node);
  };

  const handlePaneClick = () => {
    setSelectedNode(null);
  };

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <ReactFlow
          ref={drop}
          isValidConnection={(conn) => {
            // no cycle
            if (!validNoCycle(conn)) return false;
            // not more than one incoming edge
            if (
              edges.find(
                (e) =>
                  e.target === conn.target &&
                  e.targetHandle === conn.targetHandle
              )
            )
              return false;

            const sourceNode = nodes.find((n) => conn.source === n.id);
            const targetNode = nodes.find((n) => conn.target === n.id);

            const sourceOutputs = sourceNode.data.connections.outputs;
            const sourceType =
              sourceOutputs.length === 1
                ? sourceOutputs[0].type
                : sourceOutputs.find(
                    (output) => output.id === conn.sourceHandle
                  ).type;
            const targetInputs = targetNode.data.connections.inputs;
            const targetType =
              targetInputs.length === 1
                ? targetInputs[0].type
                : targetInputs.find((output) => output.id === conn.targetHandle)
                    .type;

            return (
              sourceType === AS_DATA_TYPES.ANY ||
              targetType === AS_DATA_TYPES.ANY ||
              sourceType === targetType
            );
          }}
          nodes={nodes}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChanged}
          edges={edges}
          edgeTypes={edgeTypes}
          onEdgesChange={onEdgesChanged}
          onConnect={onConnect}
          onNodeClick={handleNodeClick}
          onPaneClick={handlePaneClick}
          fitView
        >
          <Background />
          <MiniMap />
          <Controls />
        </ReactFlow>
      </div>
      <Toolbar nodes={toolbarNodes} />
    </div>
  );
};

export default Prototype;
