import React, { FC } from 'react';

import {
  useDimensions,
  usePrimaryColorScale,
  useThemeColor,
} from '../../../../../../../utils';
import EChartWrapper, {
  ReactEChartsProps,
} from '../../../../../e-chart-wrapper/EChartWrapper';
import { showLegendSize } from '../../../common/sizes';
import styles from '../../../styles.module.scss';
import { ReportElementProps } from '../../../types/meta';
import { PieChartConfig, PieChartData, PieChartReportData } from '../type';

export type Props = PieChartData & PieChartConfig;

export const PieChart: FC<Props> = ({ data, legend }) => {
  const [ref, { width, height }] = useDimensions<HTMLDivElement>(1);

  const keys = Object.keys(data);

  const primary = useThemeColor('primary');
  const primaryHighlight = useThemeColor('primary-highlight');
  const secondary = useThemeColor('secondary');

  const colorScale = usePrimaryColorScale(keys.length);

  const legendColors: { [label: string]: string } = {};
  legend?.forEach((legendEntry) => {
    let color: string;
    switch (legendEntry.color) {
      case 'primary':
        color = primary;
        break;
      case 'secondary':
        color = secondary;
        break;
      case 'primaryHighlight':
        color = primaryHighlight;
        break;
      default:
        color = legendEntry.color;
    }

    legendColors[legendEntry.label] = color;
  });

  const isSmall = height < showLegendSize && width < showLegendSize;

  const option: ReactEChartsProps['option'] = {
    animation: true,

    tooltip: {
      appendToBody: true,
      trigger: 'item',
    },
    legend: {
      width: width,
      top: '0px',
      left: 'center',
      show: !isSmall,
    },

    series: [
      {
        cursor: 'default',
        animation: true,
        animationEasing: 'linear',
        animationDuration: 0,
        top: isSmall ? 0 : 37,
        height: isSmall ? height : height - 20,

        name: '',
        type: 'pie',
        radius: isSmall ? ['45%', '95%'] : ['38%', '75%'],
        avoidLabelOverlap: false,

        itemStyle: {
          borderRadius: 0,
          borderColor: '#fff',
          borderWidth: 0,

          color: (val) => {
            // @ts-ignorename name prop is not recognized by echart
            const label = val?.data?.name;
            // Use legendColor if defined
            const legendColor = legendColors[label];
            if (legendColor) return legendColor;

            return colorScale(val.dataIndex);
          },
        },
        label: {
          show: false,
        },

        data: keys.map((key) => {
          return { value: data[key], name: key };
        }),
      },
    ],
  };

  return (
    <div ref={ref} className={styles.container}>
      <EChartWrapper option={option} style={{ width: width, height: height }} />
    </div>
  );
};

export type SingleProps = ReportElementProps<
  PieChartReportData,
  PieChartConfig
>;

export const PieChartSingle: FC<SingleProps> = ({
  input,
  config,
  portalTarget,
}) => {
  return <PieChart {...input.reportValue} {...config} />;
};
