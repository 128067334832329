import { EChartsOption } from 'echarts';
import React, { FC } from 'react';

import { useDimensions, useThemeColor } from '../../../../../../../utils';
import EChartWrapper, {
  ReactEChartsProps,
} from '../../../../../e-chart-wrapper/EChartWrapper';
import commonStyles from '../../../styles.module.scss';
import { ReportElementProps } from '../../../types/meta';
import { LineChartConfig, LineChartReportData } from '../type';

export type LineChartDevProps = {
  legend?: string;
};

export type Props = LineChartReportData & LineChartConfig & LineChartDevProps;

export const LineChart: FC<Props> = (props) => {
  const { data, xLabel, yLabel, legend, baseLine, helpLine, yAxisMin } = props;

  const primary = useThemeColor('primary');

  const [ref, { width, height }] = useDimensions<HTMLDivElement>();

  const markLine: echarts.MarkLineComponentOption['data'] = [];
  if (baseLine) {
    markLine.push({
      yAxis: baseLine,
    });
  }
  if (helpLine) {
    markLine.push([
      {
        coord: helpLine[0],
      },
      {
        coord: helpLine[1],
      },
    ]);
  }

  const formattedData: EChartsOption['series'] = {
    name: legend || '',
    type: 'line',
    showSymbol: false,
    data: data,
    color: primary,
    areaStyle: {
      opacity: 0.3,
      color: {
        x: 0,
        y: 1,
        x2: 0,
        y2: 0,
        type: 'linear',
        colorStops: [
          { offset: 0, color: '#fff' },
          { offset: 0.9, color: primary },
        ],
      },
    },

    markLine: {
      data: markLine,
      symbol: 'none',
      label: {
        position: 'start',
        offset: [-3, 0],
        backgroundColor: 'white',
        color: '#666', // To match the color of the other labels
        formatter: (value) => {
          if (typeof value.value === 'number') {
            return String(Number(parseFloat(value.value.toFixed(2))));
          } else if (!value.value) {
            return '';
          } else {
            return String(value.value);
          }
        },
      },
    },
  };

  const option: ReactEChartsProps['option'] = {
    tooltip: {
      appendToBody: true,
      trigger: 'axis',
      confine: true,
      valueFormatter: (value) => {
        if (typeof value === 'number') {
          return String(parseFloat(value.toFixed(2)));
        } else {
          return '';
        }
      },
      formatter(params) {
        const x = xLabel ?? 'x-Axis';

        let xValue;
        let yValue;
        let marker;

        if (Array.isArray(params)) {
          if (Array.isArray(params[0].data)) {
            xValue = parseFloat(Number(params[0].data[0]).toFixed(2));
            yValue = parseFloat(Number(params[0].data[1]).toFixed(2));
          }
          marker = params[0].marker;
        }

        return `${x}: <strong> ${xValue} </strong> </br>
        ${marker?.toString()} <strong> ${yValue} </strong> </br>

        `;
      },
    },
    grid: {
      containLabel: true,
      left: 22,
      top: 10,
      right: 12,
      bottom: 12,
    },
    xAxis: {
      name: xLabel,
      type: 'value',
      nameLocation: 'middle',
      nameGap: 22,
      min: 'dataMin',
      max: 'dataMax',
      axisLabel: {
        // 1.81192837132 => 1.81 but also 1 => 1. Number casting after parseFloat removes the trailing 0s
        formatter: (value) => String(Number(parseFloat(value.toFixed(2)))),
      },
    },
    yAxis: {
      name: yLabel,
      type: 'value',
      nameLocation: 'middle',
      nameGap: 34,
      nameTextStyle: {
        align: 'center',
      },
      min: yAxisMin ?? 0,
      axisLabel: {
        // 1.81192837132 => 1.81 but also 1 => 1. Number casting after parseFloat removes the trailing 0s
        formatter: (value) => String(Number(parseFloat(value.toFixed(2)))),
      },
    },
    series: formattedData,
    animation: false,
  };

  return (
    <div ref={ref} className={commonStyles.chart}>
      <EChartWrapper option={option} style={{ width: width, height: height }} />
    </div>
  );
};

export type SingleProps = ReportElementProps<
  LineChartReportData,
  LineChartConfig
>;

export const LineChartSingle: FC<SingleProps> = ({ input, config }) => {
  return (
    <LineChart {...input.reportValue} {...config} legend={input.jobCode} />
  );
};
