import PropTypes from 'prop-types';
import React from 'react';
import { Tabs } from 'react-tabs-redux';

import TabContentApps from './main-container/TabContentApps';
import TabContentAssistants from './main-container/TabContentAssistants';
import TabContentCollaborationSpace from './main-container/TabContentCollaborationSpace';
import TabContentDataMan from './main-container/TabContentDataMan.container';
import TabContentModelMan from './main-container/TabContentModelMan';
import TapContentOrchestration from './main-container/TabContentOrchestration';
import TabContentWorkbench from './main-container/TabContentWorkbench';
import Busy from '../atoms/busy/Busy';

class Dashboard extends React.Component {
  render() {
    const { initialized } = this.props;
    if (!initialized) {
      return (
        <div className='fullViewportHeight'>
          <Busy isBusy />
        </div>
      );
    }

    return (
      <div>
        <Tabs name={'main-tab'} renderActiveTabContentOnly={false}>
          {/* Contains all TabLinks */}
          {/* <SubHeader hasOverlay={false} /> */}

          <TabContentModelMan {...this.props} />
          <TabContentApps {...this.props} />
          <TabContentWorkbench {...this.props} />
          <TabContentCollaborationSpace {...this.props} />
          <TapContentOrchestration {...this.props} />
          <TabContentDataMan {...this.props} />
          <TabContentAssistants {...this.props} />
        </Tabs>
      </div>
    );
  }
}

Dashboard.propTypes = {
  initialized: PropTypes.bool.isRequired,
  match: PropTypes.shape({}).isRequired,
};

export default Dashboard;
