import { useQueryClient } from '@tanstack/react-query';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import ReactLoading from 'react-loading';
import { useSelector } from 'react-redux';

import CreateBranchModal from './create-branch-modal/CreateBranchModal';
import DeleteBranchModal from './delete-branch-modal/DeleteBranchModal';
import FetchBranchModal from './fetch-branch-modal/FetchBranchModal';
import vars from '../../../../../../../scss/base/var.module.scss';
import {
  useGitGetRemote,
  useGitSwitchBranch,
  useListBranches,
} from '../../../../../../core/api/notebook';
import { useGetBranches } from '../../../../../../core/api/versionControl';
import { RootState } from '../../../../../../store/store';
import { getActiveProjectPath } from '../../../../../../store/workbench/activeProject.slice';
import Button from '../../../../../atoms/button/Button';
import DropdownSelectInput from '../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { WORKBENCH_FILENAMES } from '../../../config';
import { Branch } from '../types/types';

type GitBranchProps = {
  activeBranch: string;
};

const GitBranches: FC<GitBranchProps> = ({ activeBranch }) => {
  const queryClient = useQueryClient();
  // State for managing modal visibility
  const [isFetchBranchModalOpen, setIsFetchBranchModalOpen] = useState(false);
  const [isDeleteBranchModalOpen, setIsDeleteBranchModalOpen] = useState(false);
  const [isCreateBranchModalOpen, setIsCreateBranchModalOpen] = useState(false);
  const [bothBranches, setBothBranches] = useState<Branch[]>();

  // Callbacks for hiding modals
  const hideFetchBranchModal = useCallback(() => {
    setIsFetchBranchModalOpen(false);
  }, []);
  const hideDeleteBranchModal = useCallback(() => {
    setIsDeleteBranchModalOpen(false);
  }, []);
  const hideCreateBranchModal = useCallback(() => {
    setIsCreateBranchModalOpen(false);
  }, []);
  const [selectedBranch, setSelectedBranch] = useState('');
  useEffect(() => {
    setSelectedBranch(activeBranch);
  }, [activeBranch]);
  // Get active project path from Redux store
  const activeProjectPath: string = useSelector<RootState, string>(
    getActiveProjectPath
  );

  // Construct paths for repository info
  const infoFilePath = `${activeProjectPath}/${WORKBENCH_FILENAMES.REPOSITORY_META}`;
  const parts = infoFilePath.split('/');
  const index = parts.findIndex((x) => x.endsWith('.asr'));

  // Memoize repository path
  const repositoryPath = useMemo(
    () => contentArrayToPath(parts.slice(0, index + 1), false),
    [index, parts]
  );

  // Fetch local branches
  const {
    isLoading,
    data: localBranches,
    error,
  } = useListBranches(repositoryPath);
  // Fetch remote
  const { data: remote } = useGitGetRemote(repositoryPath);
  // Fetch remote branches
  const { data: remoteBranches, refetch: refetchRemoteBranches } =
    useGetBranches(remote);
  // Hook to switch the branch
  const { mutate, error: pullError } = useGitSwitchBranch();
  // Refetch remote branches when remote changes
  useEffect(() => {
    void refetchRemoteBranches();
  }, [refetchRemoteBranches, remote]);

  // Handle branch change
  const handleBranchChange = useCallback(
    (branch: Branch) => {
      setSelectedBranch(branch.name);
      if (branch.remote) {
        setIsFetchBranchModalOpen(true);
      } else {
        mutate({ repositoryPath, branchName: branch.name });
      }
    },
    [queryClient, mutate]
  );

  // Combine local and remote branches
  useEffect(() => {
    const local =
      localBranches?.map((branch: Branch) => ({
        label: branch.name,
        value: branch.name,
        name: branch.name,
        remote: false,
      })) || [];

    const remoteFormattedBranches =
      remoteBranches?.map((branch) => ({
        label: `origin/${branch.name}`,
        value: `origin/${branch.name}`,
        name: branch.name,
        remote: true,
      })) || [];
    const newBranches: Branch[] = [...local, ...remoteFormattedBranches];
    setBothBranches(newBranches);
  }, [remoteBranches, localBranches]);

  const renderLoading = () => (
    <div className='git-list-commits'>
      <ReactLoading
        className='starting-stopping-spinner'
        type='cylon'
        color={vars.colorPrimary}
      />
    </div>
  );

  const renderEmpty = () => (
    <div className='git-list-commits'>
      There are no branches for this repository yet
    </div>
  );

  const renderError = () => (
    <div className='git-list-commits'>
      {error}
      <Button Icon={() => <FiRefreshCw size={16} />} />
    </div>
  );

  // Render functions for different states
  const renderLoaded = () => {
    if (!bothBranches || bothBranches.length === 0) {
      return renderEmpty();
    }
    return (
      <div className='git-branches'>
        {/* Render modals */}
        <FetchBranchModal
          infoFilePath={infoFilePath}
          isFetchBranchModalOpen={isFetchBranchModalOpen}
          branch={selectedBranch}
          hideFetchBranchModal={hideFetchBranchModal}
        />
        <CreateBranchModal
          isCreateBranchModalOpen={isCreateBranchModalOpen}
          hideCreateBranchModal={hideCreateBranchModal}
          infoFilePath={infoFilePath}
          existingBranches={remoteBranches}
        />
        <DeleteBranchModal
          infoFilePath={infoFilePath}
          isDeleteBranchModalOpen={isDeleteBranchModalOpen}
          activeBranch={selectedBranch}
          hideDeleteBranchModal={hideDeleteBranchModal}
        />
        {/* Render branch selector */}
        <div className='git-active-branch'>
          <span>Active Branch:</span>
          <DropdownSelectInput
            id='git-branch-selector'
            name='active-branch'
            options={bothBranches}
            value={bothBranches?.find((o) => o.value === selectedBranch)}
            onChange={handleBranchChange}
            className='git-branch-selector'
            clearable={false}
          />
        </div>
        {/* Render action buttons */}
        <div className='branch-button-bar'>
          <Button
            color={'primary'}
            label={'Create Branch'}
            onClick={() => setIsCreateBranchModalOpen(true)}
          />
          <Button
            color={'red'}
            label={'Delete Branch'}
            disabled={!selectedBranch || selectedBranch === 'master'}
            onClick={() => setIsDeleteBranchModalOpen(true)}
          />
        </div>
      </div>
    );
  };

  if (isLoading) return renderLoading();
  if (!isLoading) return renderLoaded();
  if (error) return renderError();
  return renderEmpty();
};

export default GitBranches;
