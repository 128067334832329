import { Edge } from '@xyflow/react';

import { CustomPythonNodeDefinition } from './customNodes.types';
import {
  ConditionalNode,
  HANDLE_ID_CONDITION,
  HANDLE_ID_FALSE,
  HANDLE_ID_TRUE,
  isConditionalNode,
} from './nodes/ConditionalNode';
import {
  generatePythonFunctionNodeCode,
  isCustomPythonNode,
} from './nodes/CustomPythonNode';
import { GatewayNode, isSubflowGatewayNode } from './nodes/GatewayNode';
import { generateSubflowCode } from './nodes/SubflowNode';
import { AsNodes, AsNodesWithGateway } from './nodes/types';
import { FlowData } from './types';

/**
 * Context needed for naming of variables
 * Maps from node/subflow id to index
 */
export type CodeGenContext = {
  nodeIndices: Record<string, number>;
  subflowIndices: Record<string, number>;
};

export const indentStr = '    ';

function assert(condition: unknown, msg?: string): asserts condition {
  if (!condition) {
    throw new Error(msg);
  }
}

/**
 * Deterministic way to label variables. The index is an important part and determined by the order when a node was added.
 * If for example the second of 3 nodes is deleted, the 3rd node will take its place and index. So all variables after
 * that are no in sync i.e. they would be different if rerun
 * @param context
 * @param node
 * @param handleId
 */
export function varname(
  context: CodeGenContext,
  node: AsNodesWithGateway,
  handleId?: string
): string {
  // special handling for gateway nodes, these are always parameters for subflows
  if (isSubflowGatewayNode(node)) {
    const index = node.data.connections.outputs.findIndex(
      (param) => param.id === handleId
    );
    const parameter = node.data.connections.outputs[index];
    if (parameter.name) return parameter.name;
    return parameter.name ?? `param_${index}`;
  }

  const nodeType = isCustomPythonNode(node) ? node.data.type : node.type;
  const cleanedNodeType = nodeType.replaceAll('-', '_');

  const nodeIndex = context.nodeIndices[node.id];
  if (!handleId) {
    return `val_${cleanedNodeType}_${nodeIndex}`;
  }

  const handleIndex = node.data.connections.outputs.findIndex(
    (handle) => handle.id === handleId
  );
  const handle = node.data.connections.outputs[handleIndex];
  const multipleHandles = node.data.connections.outputs.length > 1;

  // if there are multiple output handles we add its index
  return (
    handle.name ??
    `val_${cleanedNodeType}_${nodeIndex}${
      multipleHandles ? `_${handleIndex}` : ''
    }`
  );
}

export function subflowFn(
  context?: CodeGenContext,
  subflow?: FlowData
): string {
  if (!subflow) return '???';

  if (subflow.name) {
    return `fn_${subflow.name}`;
  }

  if (context?.subflowIndices[subflow.id] != null) {
    return `fn_subflow_${context.subflowIndices[subflow.id]}`;
  }

  return `fn_subflow`;
}

export function nodeFn(nodeType: string): string {
  const cleanedNodeType = nodeType.replaceAll('-', '_');
  return `fn_${cleanedNodeType}`;
}

/**
 * Calculate the varname again for the incoming node. Because the varnames are deterministic, the name will
 * be the same here for consuming nodes as where the variable was used for executing the producing node.
 *
 * varname = producing_node()
 *
 * def consuming_node(placeholder):
 *    ...
 *
 * ... = consuming_node(varname)
 *
 *
 * @param context
 * @param nodes
 * @param edges
 * @param node
 * @param handleId
 */
export function getIncomingVarNameByHandleId(
  context: CodeGenContext,
  { nodes, edges }: Pick<FlowData, 'nodes' | 'edges'>,
  node: AsNodesWithGateway,
  handleId: string
): string {
  const ie = edges.find(
    (edge) => edge.target === node.id && edge.targetHandle === handleId
  );

  if (!ie) return 'None';

  const sourceNode = nodes.find((node) => node.id === ie.source);
  return varname(context, sourceNode, ie.sourceHandle);
}

/**
 * Topologically sort the graph into a simple array of nodes.
 * This gives us the order in which nodes can be executed
 * It also requires that the graph is a DAG, which is why we don't allow cycle connections
 * @param nodes
 * @param edges
 */
function topsort(
  nodes: AsNodesWithGateway[],
  edges: Edge[]
): AsNodesWithGateway[] {
  const incomingEdges: Record<string, number> = {};
  nodes.forEach((node) => {
    incomingEdges[node.id] = 0;
  });

  edges.forEach((edge) => {
    if (incomingEdges[edge.target] !== undefined) {
      incomingEdges[edge.target]++;
    }
  });

  const queue: AsNodesWithGateway[] = [];
  nodes.forEach((node) => {
    if (incomingEdges[node.id] === 0) {
      queue.push(node);
    }
  });

  const topologicalOrder: AsNodesWithGateway[] = [];

  while (queue.length > 0) {
    const currentNode = queue.shift();
    assert(currentNode !== undefined, 'currentNode undefined');
    topologicalOrder.push(currentNode);

    const outgoingEdges = edges.filter(
      (edge) => edge.source === currentNode.id
    );
    outgoingEdges.forEach((edge) => {
      incomingEdges[edge.target]--;
      if (incomingEdges[edge.target] === 0) {
        const targetNode = nodes.find((node) => node.id === edge.target);
        assert(targetNode !== undefined, 'targetNode undefined');
        queue.push(targetNode);
      }
    });
  }
  assert(topologicalOrder.length === nodes.length, 'cycle??');

  return topologicalOrder;
}

/**
 * Identifies nodes in a directed graph that exclusively lead to a specific condition path
 * by performing a backwards traversal from a conditional node.
 *
 * @param nodes - Array of nodes in the graph, each must have a unique 'id' property
 * @param edges - Array of directed edges, each with 'source' and 'target' properties
 * @param conditionalNode - ID of the gateway/conditional node to start traversal from
 * @param condition - Specifies which path to follow (HANDLE_ID_TRUE or HANDLE_ID_FALSE)
 *
 * @returns Array of node IDs that exclusively lead to the specified condition path
 *
 * @algorithm
 * 1. Creates a map of incoming edges for each node
 * 2. Performs depth-first search backwards from conditional node
 * 3. For each unvisited node encountered:
 *    - Checks if all its outgoing edges lead to the current path
 *    - Adds node to result if it has no connections to other paths
 */
function propagateConditionBackwards(
  nodes: AsNodesWithGateway[],
  edges: Edge[],
  conditionalNode: string,
  condition: typeof HANDLE_ID_TRUE | typeof HANDLE_ID_FALSE
) {
  const incomingEdges: Record<string, string[]> = {};
  nodes.forEach((node) => {
    incomingEdges[node.id] = [];
  });

  edges.forEach((edge) => {
    if (incomingEdges[edge.target] !== undefined) {
      incomingEdges[edge.target].push(edge.source);
    }
  });

  const visited = new Set();
  const maximalSet: string[] = [];

  /**
   * Performs a depth-first search traversal backwards from a given node,
   * collecting nodes that exclusively lead to a specific condition path.
   *
   * @param nodeId - The ID of the current node being processed
   *
   * @details
   * The function traverses the graph backwards, starting from the conditional node,
   * and adds nodes to the maximalSet if they meet these criteria:
   * 1. The node hasn't been visited before
   * 2. The node's outgoing edges either:
   *    - Lead exclusively to the current path
   *    - Connect to the conditional node with the specified condition
   *
   * @mutates
   * - visited Set: Marks nodes as processed
   * - maximalSet Array: Collects qualifying nodes
   */
  function dfs(nodeId: string) {
    visited.add(nodeId);
    maximalSet.push(nodeId);

    const incomingNodes = incomingEdges[nodeId];
    incomingNodes.forEach((incomingNodeId) => {
      if (!visited.has(incomingNodeId)) {
        const outgoingEdges = edges.filter(
          (edge) => edge.source === incomingNodeId
        );
        const isConnectedToOtherNodes = outgoingEdges.some(
          (edge) =>
            (!visited.has(edge.target) && edge.target !== nodeId) ||
            (edge.target === conditionalNode && edge.targetHandle !== condition)
        );

        if (!isConnectedToOtherNodes) {
          dfs(incomingNodeId);
        }
      }
    });
  }

  dfs(conditionalNode);

  return maximalSet;
}

/**
 * e.g. 'return (val_a, val_b)
 */
function generateSubflowReturn(
  context: CodeGenContext,
  flow: FlowData,
  node: GatewayNode
) {
  assert(
    node.data.gatewayType === 'out',
    'Cannot generate return statement for gateway with type in.'
  );

  const gateways = node.data.connections.inputs;
  if (gateways.length === 0) {
    return 'pass';
  } else if (gateways.length === 1) {
    return `return ${getIncomingVarNameByHandleId(
      context,
      flow,
      node,
      gateways[0].id
    )}`;
  } else {
    const varNames = gateways.map((gateway) =>
      getIncomingVarNameByHandleId(context, flow, node, gateway.id)
    );
    return `return (${varNames.join(', ')})`;
  }
}

/**
 * e.g. 'def fn_a(param_0, param_1):
 */
function generateSubflowDefinition(
  subflow: FlowData,
  node: GatewayNode,
  context?: CodeGenContext
) {
  assert(
    node.data.gatewayType === 'in',
    'Cannot generate definition statement for gateway with type out.'
  );

  const params = node.data.connections.outputs.map((parameter, i) => {
    if (parameter.name) return parameter.name;
    return parameter.name ?? `param_${i}`;
  });
  return `def ${subflowFn(context, subflow)}(${params.join(', ')}):`;
}

/**
 * e.g. '(val_0, val_1) = fn()'
 */
export function generateReturnValues(
  context: CodeGenContext,
  node: AsNodes
): string {
  const outputs = node.data.connections.outputs;
  const vars = outputs.map((output) => varname(context, node, output.id));

  if (outputs.length === 0) {
    return null;
  } else if (outputs.length === 1) {
    return `${vars[0]}`;
  } else {
    return `(${vars.join(', ')})`;
  }
}

/**
 * Aggregate the statements for a given conditional node branch.
 */
function aggregateConditionalBlock(
  nodes: AsNodesWithGateway[],
  edges: Edge[],
  statements: [AsNodes, string[]][],
  node: ConditionalNode,
  conditional: typeof HANDLE_ID_TRUE | typeof HANDLE_ID_FALSE
): string[] {
  const propagated = propagateConditionBackwards(
    nodes,
    edges,
    node.id,
    conditional
  );
  const propStatements = statements.filter(([n]) =>
    propagated.slice(1).includes(n.id)
  );
  return propStatements.flatMap(([, ss]) => ss.map((s) => `${indentStr}${s}`));
}

/**
 * Sort the generated script so that only the minimally needed nodes are executed for ifs
 * That means moving any ifs as far up the list of nodes/statement batches as possible
 *
 * @param context
 * @param nodes all
 * @param edges all
 * @param statements ordered/topologically sorted list of nodes and their generated code
 * @param visited
 */
function optimizeConditionals(
  context: CodeGenContext,
  nodes: AsNodes[],
  edges: Edge[],
  statements: [AsNodes, string[]][],
  visited: string[] = []
): string[] {
  // collect the node, statements, and index of every conditional node
  const conditionals = statements
    .map(([node, statement], i) => [node, statement, i] as const)
    .filter((statement): statement is [ConditionalNode, string[], number] =>
      isConditionalNode(statement[0])
    )
    .filter(([node, ,]) => !visited.includes(node.id));
  // termination condition: no more conditionals left to process
  // flatten the bundled statements and return the newly ordered list of statements
  if (conditionals.length <= 0)
    return statements.flatMap(([, statement]) => statement);
  // take the next conditional according to the topological sort
  const [node, , i] = conditionals[0];
  // get all nodes that exclusively lead to a conditional branch of this node
  // slice(1) is to remove the current conditional node from the lists
  const propagated = [
    ...propagateConditionBackwards(nodes, edges, node.id, HANDLE_ID_TRUE).slice(
      1
    ),
    ...propagateConditionBackwards(
      nodes,
      edges,
      node.id,
      HANDLE_ID_FALSE
    ).slice(1),
  ];
  // collect statements before and after the conditional block
  const beforeConditional = statements
    .slice(0, i)
    .filter(([n]) => !propagated.includes(n.id));
  const afterConditional = statements
    .slice(i + 1)
    .filter(([n]) => !propagated.includes(n.id));
  // build statements for the true and false block of the conditional node
  const aggregateTrueBlock = aggregateConditionalBlock(
    nodes,
    edges,
    statements,
    node,
    HANDLE_ID_TRUE
  );
  const aggregateFalseBlock = aggregateConditionalBlock(
    nodes,
    edges,
    statements,
    node,
    HANDLE_ID_FALSE
  );

  // get variable names
  const flow = { nodes, edges };
  const conditionVar = getIncomingVarNameByHandleId(
    context,
    flow,
    node,
    HANDLE_ID_CONDITION
  );
  const trueVar = getIncomingVarNameByHandleId(
    context,
    flow,
    node,
    HANDLE_ID_TRUE
  );
  const falseVar = getIncomingVarNameByHandleId(
    context,
    flow,
    node,
    HANDLE_ID_FALSE
  );

  // put together all statements for the current conditional node
  const outputVar = generateReturnValues(context, node);
  const statement = [
    `${outputVar} = 'None'`,
    `if ${conditionVar}:`,
    ...aggregateTrueBlock,
    `${indentStr}${outputVar} = ${trueVar}`,
    'else:',
    ...aggregateFalseBlock,
    `${indentStr}${outputVar} = ${falseVar}`,
    `save_var_to_results('${node.id}', [${outputVar}])`,
  ].filter((line) => line.length > 0);
  // continue optimization for remaining conditional nodes
  // the statements of this conditional node are bundled for this purpose
  return optimizeConditionals(
    context,
    nodes,
    edges,
    [...beforeConditional, [node, statement], ...afterConditional],
    [...visited, node.id]
  );
}

/**
 * Take a flow of Nodes and Edges and produce a script as an array of lines
 * @param flow
 * @param nodeDefinitions
 * @param oldContext
 */
export function codegen(
  flow: FlowData,
  nodeDefinitions: CustomPythonNodeDefinition[],
  oldContext?: CodeGenContext
): string[] {
  const { nodes, edges, subflows } = flow;

  const nodeIndices = Object.fromEntries(nodes.map((node, i) => [node.id, i]));
  const subflowIndices = Object.fromEntries(
    subflows.map((subflow, i) => [subflow.id, i])
  );
  const context: CodeGenContext = {
    nodeIndices,
    subflowIndices,
  };

  // we define all subflows as separate functions at the top of each function definition
  const subflowStatements = subflows.flatMap((subflow) => {
    return codegen(subflow, nodeDefinitions, context);
  });

  // we also define separate functions for all custom python nodes that are used in this subflow
  const usedCustomPythonNodeTypes = [
    ...new Set(nodes.filter(isCustomPythonNode).map((node) => node.data.type)),
  ];
  const customPythonFunctionStatements = usedCustomPythonNodeTypes.flatMap(
    (nodeType) => {
      const template = nodeDefinitions.find((nd) => nd.type === nodeType)
        .functionTemplate;
      // replace function name, split at line break
      const statements = template
        .replace('_my_function', nodeFn(nodeType))
        .split('\n');
      return [...statements, ''];
    }
  );

  const orderedNodes = topsort(nodes, edges);
  // remove gateway nodes because they are 'pseudo-nodes' that don't generate a statement
  const orderedNodesWithoutGateways = orderedNodes.filter(
    (node): node is AsNodes => !isSubflowGatewayNode(node)
  );
  // List of nodes and the corresponding generated code of that node
  const orderedStatementLists: [
    AsNodes,
    string[]
  ][] = orderedNodesWithoutGateways.map((node) => {
    switch (node.type) {
      case 'python_node': {
        const statements = generatePythonFunctionNodeCode({
          context,
          node,
          flow,
        });
        return [node, statements];
      }
      case 'subflow': {
        const statements = generateSubflowCode({
          context,
          node,
          flow,
        });
        return [node, statements];
      }
      case 'conditional': {
        // custom handling
        return [node, []];
      }
    }
  });
  const nonGatewayNodes = nodes.filter(
    (node): node is AsNodes => !isSubflowGatewayNode(node)
  );
  const optimizedStatements = optimizeConditionals(
    context,
    nonGatewayNodes,
    edges,
    orderedStatementLists
  );

  const statements = [
    ...subflowStatements,
    ...customPythonFunctionStatements,
    ...optimizedStatements,
  ];

  //TODO: Maybe move this to a separate file
  const saveResultsHelperFunction = `import json, os, pandas as pd
def save_var_to_results(node_id, values):
      os.makedirs('results', exist_ok=True)
      
      outputs = []
      for value in values:
        if isinstance(value, pd.DataFrame):
          output = value.to_dict(orient='records')
        elif isinstance(value, (bool, int, float, str)):
          output = value
        else:
          output = repr(value)
        outputs.append(output)      
      
      with open(f'results/{node_id}.json', 'w') as file:
        json.dump(outputs, file)
    `;

  const gatewayNodes = nodes.filter(isSubflowGatewayNode);
  const isSubflow = gatewayNodes.length !== 0;
  if (isSubflow) {
    const inGatewayNode = gatewayNodes.find(
      (node) => node.data.gatewayType === 'in'
    );
    const definitionStatement = generateSubflowDefinition(
      flow,
      inGatewayNode,
      oldContext // old context here because the subflow definition is used in the parent
    );

    const outGatewayNode = gatewayNodes.find(
      (node) => node.data.gatewayType === 'out'
    );
    const returnStatement = generateSubflowReturn(
      context,
      flow,
      outGatewayNode
    );

    // root level subflow -> indent all statements except the subflow function definition
    return [
      definitionStatement,
      ...statements.map((str) => `${indentStr}${str}`),
      `${indentStr}${returnStatement}`,
      '',
    ];
  } else {
    //Add helper function to the top of the generated code
    statements.unshift(saveResultsHelperFunction);
  }

  // no indentation on the first level
  return statements;
}
