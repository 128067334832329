import { defineMessages } from 'react-intl';

export default defineMessages({
  workbenchNotebookNotebookCellMarkdownEdit: {
    id: 'workbench.notebook.notebook_cell.markdown.edit',
    defaultMessage: '*Click here to edit Markdown*',
  },
  workbenchRepositoryGitNotPulledPullConflictsModalHeadline: {
    id: 'workbench.repository.git_not_pulled.pull_conflicts_modal.headline',
    defaultMessage: 'Merge conflicts during pull',
  },
  workbenchRepositoryGitNotPulledPullConflictsModalLine1: {
    id: 'workbench.repository.git_not_pulled.pull_conflicts_modal.line1',
    defaultMessage: 'Merge conflicts occurred during the git pull.',
  },
  workbenchRepositoryGitNotPulledPullConflictsModalLine2: {
    id: 'workbench.repository.git_not_pulled.pull_conflicts_modal.line2',
    defaultMessage:
      'Do you want to "force pull" and overwrite some of your local changes?',
  },
  workbenchRepositoryGitNotPulledPullConflictsModalLine3: {
    id: 'workbench.repository.git_not_pulled.pull_conflicts_modal.line3',
    defaultMessage:
      'A backup copy of your local files will be created before they are overwritten.',
  },
  workbenchRepositoryGitNotPulledPullConflictsModalLine4: {
    id: 'workbench.repository.git_not_pulled.pull_conflicts_modal.line4',
    defaultMessage: 'The following files are affected:',
  },
  workbenchRepositoryGitNotPushedPushConflictsModalHeadline: {
    id: 'workbench.repository.git_not_pushed.push_conflicts_modal.headline',
    defaultMessage: 'Local branch is behind',
  },
  workbenchRepositoryGitNotPushedPushConflictsModalLine1: {
    id: 'workbench.repository.git_not_pushed.push_conflicts_modal.line1',
    defaultMessage:
      'You are currently {commitsBehind} commit{pluralSuffix} behind the remote branch you want to push to.',
  },
  workbenchRepositoryGitNotPushedPushConflictsModalLine2: {
    id: 'workbench.repository.git_not_pushed.push_conflicts_modal.line2',
    defaultMessage: 'Please pull the remote commits first.',
  },
  workbenchSessionsEmpty: {
    id: 'workbench.sessions.empty',
    defaultMessage: 'There are no running Sessions',
  },
  workbenchSelectImage: {
    id: 'workbench.start.select_image',
    defaultMessage:
      'Select the image / environment for your Workbench instance',
  },
  terminalNoSettings: {
    id: 'workbench.terminal.no_settings',
    defaultMessage: 'No settings: Save settings in the Dev Augur',
  },
  terminalNoModel: {
    id: 'workbench.terminal.no_model',
    defaultMessage: 'No model: Run a learning job',
  },
  terminalNoEntrypoint: {
    id: 'workbench.terminal.no_entrypoint',
    defaultMessage: 'No entrypoint: Add main.py',
  },
  terminalNoVenv: {
    id: 'workbench.terminal.no_venv',
    defaultMessage: 'No or broken venv: Run Build (venv)',
  },
  notebookEnvironmentSaveLargeFile: {
    id: 'workbench.notebook.environment.save_large_file',
    defaultMessage:
      'Are you sure you want to save this large file (> {filesize})? It may lead to instability.',
  },
  notebookEnvironmentSaveFile: {
    id: 'workbench.notebook.environment.save_file',
    defaultMessage: 'Save File',
  },
});
