import {
  DASHBOARD_COMPONENT_SCOPES,
  RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_ASSISTANTS_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX,
} from 'common/dist/constants/keycloak';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { detailsRoute } from './DomainPermissions';
import ResourcePermissions from '../../../organisms/permissions/ressource-permissions/ResourcePermissions.container';

const descriptionsMap = {
  [RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX]: {
    headline: 'Model Management Permissions',
    groupPermissionsSubTitle:
      'Assign group-wise permissions to the Domain Model Management',
    groupPermissionsDescription:
      'If a group has Access permissions on the Domain Model Management, the Users that belong to this Group will have access to the Domain Model Management.',
    userPermissionsSubTitle:
      'Assign user-wise permissions to the Domain Model Management',
  },
  [RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX]: {
    headline: 'App Permissions',
    groupPermissionsSubTitle:
      'Assign group-wise permissions to the Domain Apps',
    groupPermissionsDescription:
      'If a group has Access permissions on the Domain Apps, the Users that belong to this Group will have access to the Domain Apps.',
    userPermissionsSubTitle: 'Assign user-wise permissions to the Domain Apps',
  },
  [RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX]: {
    headline: 'Workbench & Collaboration Space Permissions',
    groupPermissionsSubTitle:
      'Assign group-wise permissions to the Domain Workbench and Collaboration Space',
    groupPermissionsDescription:
      'If a group has Access permissions on the Domain Workbench and Collaboration Space, the Users that belong to this Group will have access to the Domain Workbench and Collaboration Space.',
    userPermissionsSubTitle:
      'Assign user-wise permissions to the Domain Workbench and Collaboration Space',
  },
  [RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX]: {
    headline: 'Job Orchestration Permissions',
    groupPermissionsSubTitle:
      'Assign group-wise permissions to the Domain Orchestration',
    groupPermissionsDescription:
      'If a group has Access permissions on the Domain Orchestration, the Users that belong to this Group will have access to the Domain Orchestration.',
    userPermissionsSubTitle:
      'Assign user-wise permissions to the Domain Orchestration',
  },
  [RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX]: {
    headline: 'Data Management Permissions',
    groupPermissionsSubTitle:
      'Assign group-wise permissions to the Domain Data Management',
    groupPermissionsDescription:
      'If a group has Access permissions on the Domain Data Management, the Users that belong to this Group will have access to the Domain Data Management.',
    userPermissionsSubTitle:
      'Assign user-wise permissions to the Domain Data Management',
  },
  [RESOURCE_TYPE_DASHBOARD_ASSISTANTS_SUFFIX]: {
    headline: 'Assistants Permissions',
    groupPermissionsSubTitle:
      'Assign group-wise permissions to the Domain Assistants',
    groupPermissionsDescription:
      'If a group has Access permissions on the Domain Assistants, the Users that belong to this Group will have access to the Domain Assistants.',
    userPermissionsSubTitle:
      'Assign user-wise permissions to the Domain Assistants',
  },
};

const GeneralDetails: FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <ResourcePermissions
      baseUrl={detailsRoute(id)}
      showBackButton={false}
      resourceType={'dashboard-component'}
      resourceName={`dashboard-component:${id}`}
      details={{
        headline: { id: 'no-id', defaultMessage: descriptionsMap[id].headline },
        rows: [],
      }}
      scopes={DASHBOARD_COMPONENT_SCOPES}
      messagesGroupPermissions={{
        subTitle: {
          id: 'no-id',
          defaultMessage: descriptionsMap[id].groupPermissionsSubTitle,
        },
        description: {
          id: 'no-id',
          defaultMessage: descriptionsMap[id].groupPermissionsDescription,
        },
      }}
      messagesUserPermissions={{
        subTitle: {
          id: 'no-id',
          defaultMessage: descriptionsMap[id].userPermissionsSubTitle,
        },
      }}
    />
  );
};

export default GeneralDetails;
