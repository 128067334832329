import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';
import ReactModal from 'react-modal';

import infoStyles from './../../../info/styles.module.scss';
import { useGitFetchAndTrackRemoteBranch } from '../../../../../../../core/api/notebook';
import Button from '../../../../../../atoms/button/Button';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
export type Props = {
  isFetchBranchModalOpen: boolean;
  branch: string;
  infoFilePath: string;
  hideFetchBranchModal: () => void;
};

const FetchBranchModal: FC<Props> = ({
  branch,
  isFetchBranchModalOpen,
  infoFilePath,
  hideFetchBranchModal,
}) => {
  const parts = infoFilePath.split('/');
  const repositoryPath = parts.slice(0, parts.length - 1).join('/');

  const { mutate, error: pullError } = useGitFetchAndTrackRemoteBranch();
  const handleClose = () => {
    hideFetchBranchModal();
  };

  const handleClick = () => {
    mutate({ repositoryPath, branchName: 'origin/' + branch });
    handleClose();
  };

  return (
    <ReactModal
      isOpen={isFetchBranchModalOpen}
      contentLabel='Fetch Branch Modal'
      onRequestClose={handleClose}
      style={customStyles}
      appElement={document.getElementById('react-app')}
      parentSelector={
        () => document.querySelector('#MainContainer')
        /* Function that will be called to get the parent element
           that the modal will be attached to. */
      }
    >
      <div className={infoStyles.modalCommitParent}>
        <div
          title={'Close Dialog'}
          className={'workbench-button close-button'}
          onClick={handleClose}
        >
          <FiX className={'icon'} size={'20px'} />
        </div>

        <div className={infoStyles.modalCommitElements}>
          Do you want to fetch the remote branch origin/{branch}?
        </div>

        <div className={infoStyles.modalCommitButtons}>
          <Button color={'secondary'} label={'Fetch'} onClick={handleClick} />
        </div>
      </div>
    </ReactModal>
  );
};

export default FetchBranchModal;
