import classNames from 'classnames';
import React, { FC } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import ReactLoading from 'react-loading';
import { Link, useLocation } from 'react-router-dom';

import { MAIN_MENU_QUERY_WHITELIST } from './MainMenu';
import { menuTooltipId, SubMenuEntriesType } from './MenuEntries';
import styles from './styles.module.scss';
import { toLinkWhitelistQuery } from '../../../utils';
import ProjectLink from '../../molecules/project-link/ProjectLink';

type Props = {
  subMenuEntry: SubMenuEntriesType;
  matchingPath?: string;
  intl: IntlShape;
};

const SubMenuEntry: FC<Props> = ({ subMenuEntry, matchingPath, intl }) => {
  const location = useLocation();
  if (subMenuEntry.type === 'entry') {
    return (
      <Link
        to={toLinkWhitelistQuery(
          subMenuEntry.path,
          location,
          MAIN_MENU_QUERY_WHITELIST
        )}
        className={classNames(styles.entry, styles.subMenuEntry, {
          [styles.isActive]: matchingPath && matchingPath === subMenuEntry.path,
        })}
        data-tooltip-id={menuTooltipId}
        data-tooltip-content={intl.formatMessage(subMenuEntry.title)}
      >
        <div className={styles.icon}>
          <subMenuEntry.Icon />
        </div>
        <FormattedMessage {...subMenuEntry.title}>
          {(text) => <span className={styles.title}>{text}</span>}
        </FormattedMessage>
      </Link>
    );
  } else if (subMenuEntry.type === 'projectLink') {
    return (
      <Link
        to={toLinkWhitelistQuery(
          subMenuEntry.path,
          location,
          MAIN_MENU_QUERY_WHITELIST
        )}
        className={classNames(
          styles.entry,
          styles.subMenuEntry,
          styles.linkEntry,
          {
            [styles.isActive]:
              matchingPath && matchingPath === subMenuEntry.path,
          }
        )}
        data-tooltip-id={menuTooltipId}
        data-tooltip-content={intl.formatMessage(subMenuEntry.title)}
      >
        <ProjectLink
          name={intl.formatMessage(subMenuEntry.title)}
          repoType={subMenuEntry.repoType}
          avatar={subMenuEntry.avatar}
          isLoading={subMenuEntry.isLoading}
          isActiveProject={subMenuEntry.isActiveProject}
        />
      </Link>
    );
  } else if (subMenuEntry.type === 'loading') {
    return (
      <div className={classNames(styles.subMenuEntry, styles.loadingEntry)}>
        <div className={styles.icon}>
          <ReactLoading
            width={24}
            height={24}
            className={'busy'}
            type={'bubbles'}
            color={'#394d63'}
          />
        </div>
        <span className={styles.title}>Loading</span>
      </div>
    );
  } else if (subMenuEntry.type === 'hr') {
    return <hr className={styles.subMenuHr} />;
  } else if (subMenuEntry.type === 'headline') {
    return (
      <div className={styles.subMenuHeadline}>
        <FormattedMessage {...subMenuEntry.headline}>
          {(text) => <span className={styles.headline}>{text}</span>}
        </FormattedMessage>
      </div>
    );
  } else {
    return null;
  }
};

export default SubMenuEntry;
