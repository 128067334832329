import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import ReactLoading from 'react-loading';

import styles from './styles.module.scss';
import keycloak from '../../../../../keycloak';
import { useAugur } from '../../../../core/api/augurs';
import BackTo from '../../../atoms/back-to/BackTo';
import Button from '../../../atoms/button/Button';
import { assistantsRoutes } from '../../routes';
import { useAugurCode } from '../utils';

type Props = {};

const FruitObjectDetection: FC<Props> = () => {
  const augurCode = useAugurCode();
  const { data: augur } = useAugur(augurCode);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [resultImage, setResultImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      setPreviewImage(URL.createObjectURL(file));
      setResultImage(null);
      setError(null);
    }
  };

  const handleDetect = async () => {
    if (!selectedImage) {
      setError('Please select an image first.');
      return;
    }

    setIsLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('image', selectedImage);

    try {
      const response = await fetch(
        `/orchestration/realtime/augurs/${augurCode}/serving/detect`,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Detection failed');
      }

      const blob = await response.blob();
      setResultImage(URL.createObjectURL(blob));
    } catch (err) {
      setError('An error occurred during detection. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.fruitObjectDetection}>
      <div className={styles.backButtonParent}>
        <BackTo
          label={'Back to all Assistants'}
          linkTo={assistantsRoutes.basePath}
        />
      </div>

      <div className={styles.headlineBar}>
        <span className={styles.headline}>{augur?.name}</span>
      </div>

      <div className={styles.body}>
        <div className={styles.buttonBar}>
          <label
            htmlFor={'file-upload'} // See https://stackoverflow.com/a/25825731
            className={styles.customFileInput}
          >
            <span>Upload Image</span>
            <FiUpload size={16} />
          </label>
          <input
            id='file-upload'
            type='file'
            accept='.png,.jpg,.jpeg'
            onChange={handleImageUpload}
            className={styles.fileInput}
          />

          <Button
            color={'secondary'}
            onClick={() => {
              selectedImage && !isLoading && handleDetect();
            }}
            className={classNames(styles.detectButton, {
              [styles.disabled]: !selectedImage || isLoading,
            })}
            label={{
              id: 'no-id',
              defaultMessage: isLoading ? 'Detecting...' : 'Detect Objects',
            }}
          />
        </div>

        <div className={styles.imagesContainer}>
          <div className={styles.imageColumn}>
            {previewImage && (
              <div className={styles.imagePreview}>
                <span className={styles.imageHeadline}>Uploaded Image</span>
                <img
                  src={previewImage}
                  alt='Preview'
                  className={styles.image}
                />
              </div>
            )}
          </div>

          <div className={styles.imageColumn}>
            {isLoading && (
              <ReactLoading type={'bars'} color={'#dce2eb'} width={32} />
            )}

            {!isLoading && error && <p className={styles.error}>{error}</p>}

            {!isLoading && resultImage && (
              <div className={styles.resultImage}>
                <span className={styles.imageHeadline}>Detection Result</span>
                <img src={resultImage} alt='Result' className={styles.image} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FruitObjectDetection;
