import commonMessages from 'common/dist/messages/common';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import { isEqual } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styles from './styles.module.scss';
import { useContent } from '../../../../../../core/api/workbench/content';
import {
  addDirectory,
  hideEditDirectory,
  saveEditDirectory,
} from '../../../../../../redux/workbench/modules/directory.module';
import { RootState } from '../../../../../../store/store';
import { workbenchRoutes } from '../../../../../../workbench/common/workbenchRoutes';
import IntlTextInputLine from '../../../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import BubbleStep from '../../../../../molecules/bubble-step/BubbleStep';
import SelectedDirs from '../../../../../molecules/selected-dirs/SelectedDirs';
import Wizard from '../../../../../pages/wizard/Wizard';
import { useSelectedDirPath } from '../../../../hooks';
import { validateDirectoryName } from '../validation';

interface FormValues {
  directoryName: string;
}

interface Props {
  isEditMode?: boolean;
}

const DirectoryWizard: FC<Props> = ({ isEditMode = false }) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const selectedDirPath = useSelectedDirPath();
  const previousSelectedDirPathRef = useRef();

  const showEditDirectory = useSelector(
    (state: RootState) => state.workbench.showEditDirectory
  );
  const openedNotebooks = useSelector((state: RootState) =>
    Object.keys(state.workbench.notebooks || {})
  );

  const headline = isEditMode ? 'Edit Directory' : 'Add new Directory';
  const initialDirectoryName = isEditMode ? showEditDirectory?.name : '';
  const currentPath = showEditDirectory?.path || '';

  const methods = useForm<FormValues>({
    values: {
      directoryName: initialDirectoryName || '',
    },
    mode: 'all',
  });

  const {
    handleSubmit,
    watch,
    control,
    formState: { isDirty, isValid },
    trigger,
  } = methods;

  useEffect(() => {
    if (!isEqual(previousSelectedDirPathRef.current, selectedDirPath)) {
      void trigger();
      // @ts-ignore
      previousSelectedDirPathRef.current = selectedDirPath;
    }
  }, [history, selectedDirPath, trigger]);

  const directoryName = watch('directoryName');

  const filledCompletely = !!directoryName && isValid;
  const notebookInsideDirOpened = !!openedNotebooks.find((nbPath) =>
    nbPath.startsWith(currentPath + '/')
  );

  const fileBrowserPath = `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}`;

  const onSubmit = (data: FormValues) => {
    if (filledCompletely) {
      const action = isEditMode ? saveEditDirectory : addDirectory;

      dispatch(
        //@ts-ignore
        action(data.directoryName, contentArrayToPath(selectedDirPath))
      );
      history.goBack();
    }
  };

  const onCancel = () => {
    if (isEditMode) {
      dispatch(hideEditDirectory());
    }
  };

  const { data } = useContent(contentArrayToPath(selectedDirPath, false));
  const content = useMemo(() => data?.content ?? [], [data]);

  const validate = useCallback(
    (value: string) => {
      const error = validateDirectoryName(
        value,
        content,
        isEditMode,
        initialDirectoryName
      );
      return error || true;
    },
    [content, isEditMode, initialDirectoryName]
  );

  if (isEditMode && showEditDirectory?.name === undefined) {
    // Handle redirect for edit mode when name is undefined
    return null; // or return some redirect component
  }

  return (
    <FormProvider {...methods}>
      <div className={styles.dialogContainer}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
        >
          <Wizard
            headline={headline}
            buttons={[
              {
                color: 'white',
                label: commonMessages.cancel,
                onClick: () => {
                  onCancel;
                },
                linkTo: fileBrowserPath,
              },
              {
                color: 'secondary',
                type: 'submit',
                label: 'Finish',
                disabled: !isDirty || !isValid,
                onClick: () => {
                  handleSubmit(onSubmit);
                },
              },
            ]}
          >
            {notebookInsideDirOpened && (
              <div className={styles.notebookContainer}>
                <span>
                  Please close all notebooks from inside this directory before
                  editing
                </span>
              </div>
            )}

            <div className={'step-container step-1-container'}>
              <Controller
                name='directoryName'
                control={control}
                rules={{ validate }}
                render={({ field, fieldState }) => {
                  const { ref, ...rest } = field; // extract ref to pass as inputRef
                  return (
                    <BubbleStep
                      title={'Select Name'}
                      description={
                        'Select a name for the directory. Browse on the left to change the path where the directory will be created.'
                      }
                      stepNumber={1}
                      isErroneous={fieldState.isDirty && !!fieldState.error}
                      isValid={fieldState.isDirty && !fieldState.invalid}
                    >
                      <IntlTextInputLine
                        label={'Directory name'}
                        placeholder={'Please enter a directory name'}
                        {...rest}
                        {...fieldState}
                        isTouched={isDirty}
                        inputRef={ref}
                        error={fieldState.error?.message}
                      />
                    </BubbleStep>
                  );
                }}
              />
              <SelectedDirs
                dirs={[...selectedDirPath, directoryName]}
                highlightLastEntry
              />
            </div>
          </Wizard>
        </form>
      </div>
    </FormProvider>
  );
};

export default DirectoryWizard;
