/*
 * This module contains the redux actions / sagas to interact with the Jupyter Notebook container.
 * This happens by:
 * 1. Creating a new Python Console Session
 * 2. Sending the Python command to execute a specific Python file / function that's located at
 * /container-interactions/*.py in the Notebok
 */

import { createAction } from 'redux-act';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { ensureNotebookIsClosed } from './notebook.module';
import { SingleCommandSession } from './singleCommandSession';
import { contentKeys } from '../../../core/api/workbench/content';
import NotebookApi from '../../../core/api/workbench/git.notebook';
import { error as errorType } from '../../../core/notifications';
import { sendNotification } from '../../modules/notifications.module';
import { invalidateQueries } from '../../modules/reactQuery.module';
import { notebookUser } from '../selectors/notebookUser.selector';

export const gitConfig = createAction(
  'git config',
  (gitDisplayName, gitMail) => ({ gitDisplayName, gitMail })
);

export const showPullConflictsModal = createAction(
  'show pull conflicts modal',
  (error, branch, conflicts) => ({ error, branch, conflicts })
);

export const hidePullConflictsModal = createAction('hide pull conflicts modal');

export const cloneGitRepository = createAction(
  'clone git repository',
  (
    notebookPath,
    repoName,
    repoFullPath,
    gitDisplayName,
    gitMail,
    metaFileContent,
    branch
  ) => ({
    notebookPath,
    repoName,
    repoFullPath,
    gitDisplayName,
    gitMail,
    metaFileContent,
    branch,
  })
);

export const deleteContent = createAction(
  'container interactions - delete content',
  (path, permanently = false) => ({
    path,
    permanently,
  })
);

/**
 * Runs "mkdir -p $targetDirPath" in the Notebook Container.
 * Ensures that all required directories are available (used for example before uploading files into a specific directory)
 */
export const ensureDirectoryExists = createAction(
  'container interactions - ensure directory path',
  (targetDirPath, parentType, appVersionCode) => ({
    targetDirPath,
    parentType,
    appVersionCode,
  })
);

export const checkGitRefFormat = createAction(
  'check git ref format for validity',
  (ref, callbacks) => ({ ref, callbacks })
);

export const reducer = {
  [showPullConflictsModal]: (state, { error, branch, conflicts }) => ({
    ...state,
    pullConflictsModal: {
      show: true,
      error,
      branch,
      conflicts,
    },
  }),
  [hidePullConflictsModal]: (state) => ({
    ...state,
    pullConflictsModal: {
      show: false,
    },
  }),
};

export function* gitConfigSaga({ payload: { gitDisplayName, gitMail } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.config],
    gitDisplayName,
    gitMail
  );
  if (response) {
    //Nothing to do here
  } else {
    yield put(
      sendNotification(
        'Git config failed to change',
        JSON.stringify(error),
        errorType
      )
    );
  }
}

export function* watchGitConfig() {
  yield takeEvery(gitConfig.getType(), gitConfigSaga);
}

export function* cloneGitRepositorySaga({
  payload: {
    notebookPath,
    repoName,
    repoFullPath,
    gitDisplayName,
    gitMail,
    metaFileContent,
    branch,
  },
}) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);

  // api expects a trailing slash here
  const fixedNotebookPath = `${notebookPath}${
    notebookPath.endsWith('/') ? '' : '/'
  }`;

  const { response, error } = yield call(
    [notebookApi, notebookApi.clone],
    fixedNotebookPath,
    repoName,
    repoFullPath,
    gitDisplayName,
    gitMail,
    metaFileContent,
    branch
  );
  if (response) {
    yield put(invalidateQueries(contentKeys.all()));
  } else {
    yield put(
      sendNotification(
        'Cloning repository failed',
        JSON.stringify(error),
        errorType
      )
    );
  }
}

export function* watchCloneGitRepository() {
  yield takeEvery(cloneGitRepository.getType(), cloneGitRepositorySaga);
}

export function* deleteContentSaga({ payload: { path, permanently } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.deleteContent],
    path,
    permanently
  );
  yield put(invalidateQueries(contentKeys.all()));
  if (response) {
    if (!permanently) {
      yield put(ensureNotebookIsClosed(path));
    }
  }
}

export function* watchDeleteContent() {
  yield takeEvery(deleteContent.getType(), deleteContentSaga);
}

export function* checkGitRefFormatSaga({ payload: { ref, callbacks } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.checkRefFormat],
    ref
  );
  if (response) {
    callbacks.resolve(response);
  } else {
    callbacks.reject(error);
  }
}

export function* watchCheckGitRefFormat() {
  yield takeEvery(checkGitRefFormat.getType(), checkGitRefFormatSaga);
}

/**
 * Makes sure the directory exists in the notebook for the given path
 * @param joinedPath
 * @param parentType
 * @param appVersionCode
 * @returns {Generator<<"CALL", CallEffectDescriptor>, void, *>}
 */
/**
 * Makes sure the directory exists in the notebook for the given path
 * @param joinedPath
 * @param parentType
 * @param appVersionCode
 * @returns {Generator<<"CALL", CallEffectDescriptor>, void, *>}
 */
export function* ensureDirectoryExistsCall(
  joinedPath,
  parentType,
  appVersionCode
) {
  const jupyterUser = yield select((state) => notebookUser(state));

  if (parentType === 'notebook') {
    const notebookApi = new NotebookApi(jupyterUser);
    const { response, error } = yield call(
      [notebookApi, notebookApi.ensureDir],
      joinedPath
    );
  } else if (parentType === 'app') {
    const singleCommandSession = new SingleCommandSession(
      parentType,
      appVersionCode
    );
    const onSuccessActions = [];
    const onFailureActions = [];
    const onStreamActions = [];

    const NOTEBOOK_BASE_PATH = '/workbench';
    const command = `import pathlib; pathlib.Path('${NOTEBOOK_BASE_PATH}/${joinedPath}').mkdir(parents=True, exist_ok=True)`;

    yield call(
      singleCommandSession.executeCommand,
      command,
      onSuccessActions,
      onFailureActions,
      onStreamActions
    );
  }
}

export function* ensureDirectoryExistsSaga({
  payload: { targetDirPath, parentType, appVersionCode },
}) {
  yield call(
    ensureDirectoryExistsCall,
    targetDirPath,
    parentType,
    appVersionCode
  );
}

export function* watchEnsureDirectoryExistsSaga() {
  yield takeEvery(ensureDirectoryExists.getType(), ensureDirectoryExistsSaga);
}
