import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { TabContent } from 'react-tabs-redux';

import Assistants from '../../assistants/Assistants';
import ErrorBoundary from '../../pages/error-boundary/ErrorBoundary';

const TabContentAssistants: FC<RouteComponentProps<{ mainTab: string }>> = ({
  match,
}) => {
  const isVisible = 'assistants' === match.params.mainTab;

  return (
    <TabContent
      className={'assistants-tab-content'}
      for={'assistants'}
      isVisible={isVisible}
      renderActiveTabContentOnly={true}
    >
      <ErrorBoundary
        errorDescription={{
          id: 'error.tab.description',
        }}
        fullScreenHeight={true}
      >
        <Assistants />
      </ErrorBoundary>
    </TabContent>
  );
};

export default TabContentAssistants;
