import notificationsMsgs from 'common/dist/messages/notifications';
import React, { FC } from 'react';
import { FiDownload, FiEdit, FiTrash } from 'react-icons/fi';

import styles from './styles.module.scss';
import keycloak, { updateToken } from '../../../../../../keycloak';
import { error as errorType } from '../../../../../core/notifications';
import { sendNotification } from '../../../../../redux/modules/notifications.module';
import { S3Object } from '../../../../../store/dataManagement/state.types';
import { useAppDispatch } from '../../../../../store/store';
import Button from '../../../../atoms/button/Button';

type Props = {
  element: S3Object;
  dataSourceCode: string;
  bucket: string;
  showRenameModal: (renameFile: string, renamePath: string) => void;
  showDeleteModal: (deletePath: string) => void;
};

const FileActions: FC<Props> = (props) => {
  const { element, dataSourceCode, bucket, showRenameModal, showDeleteModal } =
    props;
  const dispatch = useAppDispatch();

  return (
    <div className={styles.fileActions} onClick={(e) => e.stopPropagation()}>
      <Button
        Icon={() => <FiEdit size={16} />}
        shape={'squared'}
        onClick={() => showRenameModal(element.name, element.path)}
      />
      <Button
        Icon={() => <FiDownload size={16} />}
        shape={'squared'}
        onClick={() => {
          // sorry for what is about to happen here.
          // there were no good options. trust me.
          (async () => {
            await updateToken();
            const res = await fetch(
              `/dataman/s3/${dataSourceCode}/download/bucket/${bucket}${element.path}`,
              {
                headers: {
                  Authorization: `Bearer ${keycloak.token}`,
                },
              }
            );
            if (!res.ok) {
              const text = await res.text();
              dispatch(
                sendNotification(
                  notificationsMsgs.notificationsTitleError.id,
                  // @ts-ignore
                  notificationsMsgs.notificationsDescriptionError.id,
                  errorType,
                  { errorString: text }
                )
              );
              return;
            }
            const blob = await res.blob();
            const el = document.createElement('a');
            el.setAttribute('href', URL.createObjectURL(blob));
            el.setAttribute('download', element.name);
            el.style.display = 'none';
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
          })().catch((err) => console.error(err));
        }}
        // For a discussion about the download problem, see:
        // https://gitlab.sigmalto.com/altasigma-platform/ticket-system/-/issues/1233
      />
      <Button
        Icon={() => <FiTrash size={16} />}
        shape={'squared'}
        onClick={() => showDeleteModal(element.path)}
      />
    </div>
  );
};

export default FileActions;
