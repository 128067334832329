import {
  EChartsOption,
  TooltipComponentFormatterCallbackParams,
} from 'echarts';
import React, { FC } from 'react';

import { useDimensions, useThemeColor } from '../../../../../../../utils';
import EChartWrapper, {
  ReactEChartsProps,
} from '../../../../../e-chart-wrapper/EChartWrapper';
import { buildSingleReportWrapper } from '../../../common/SingleReportWrapper';
import commonStyles from '../../../styles.module.scss';
import { ReportElementProps } from '../../../types/meta';
import { BarChartConfig, BarChartReportData } from '../type';

export type Props = BarChartReportData & BarChartConfig;

export const BarChart: FC<Props> = ({ data, xLabel, yLabel }) => {
  const [ref, { width, height }] = useDimensions<HTMLDivElement>(1);
  const primary = useThemeColor('primary-highlight');

  const formattedData: EChartsOption['series'] = {
    name: '',
    type: 'bar',
    data: data.map((dataEntry) => dataEntry[1]),
    color: primary,
    barWidth: '85%',
  };

  const option: ReactEChartsProps['option'] = {
    tooltip: {
      appendToBody: true,
      trigger: 'axis',
      confine: true,
      axisPointer: {
        type: 'none',
      },
      formatter: (parameter: TooltipComponentFormatterCallbackParams) => {
        if (parameter && Array.isArray(parameter)) {
          const xAxis = xLabel ?? 'x-Axis';
          const xAxisVal = parameter[0].name;

          return `
          ${xAxis}: <strong> ${xAxisVal} </strong>
          </br>${parameter[0].marker?.toString()} <strong>${parameter[0].data?.toString()}</strong>`;
        }
        return '';
      },
    },
    grid: {
      containLabel: true,
      left: 22,
      top: 10,
      right: 12,
      bottom: 12,
    },
    xAxis: {
      name: xLabel,
      nameGap: 22,
      nameLocation: 'middle',
      type: 'category',
      ...{ data: data?.map((dataEntry) => dataEntry[0]) },
    },
    yAxis: {
      axisTick: {
        show: true,
      },
      axisLine: {
        show: true,
      },
      name: yLabel,
      type: 'value',
      nameLocation: 'middle',
      offset: 1,
      nameRotate: 90,
      nameGap: 34,
    },
    series: formattedData,
    animation: false,
  };

  return (
    <div ref={ref} className={commonStyles.chart}>
      <EChartWrapper option={option} style={{ width: width, height: height }} />
    </div>
  );
};

export type SingleProps = ReportElementProps<
  BarChartReportData,
  BarChartConfig
>;

export const BarChartSingle: FC<SingleProps> = buildSingleReportWrapper<
  BarChartReportData,
  BarChartConfig
>(BarChart);
