import { PushConflicts } from 'common/dist/types/repository';
import React, { FC, Fragment, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

import PushConflictsModal from './PushConflictsModal';
import vars from '../../../../../../../scss/base/var.module.scss';
import { useGitPushHook } from '../../../../../../core/api/notebook';
import { useCommits } from '../../../../../../core/api/versionControl';
import { CommitFilter } from '../../../../../../core/api/workbench/git.notebook';
import Button from '../../../../../atoms/button/Button';

interface PushConflictsModalProps extends PushConflicts {
  show: boolean;
}
interface GitNotPushedProps {
  repositoryPath: string;
  activeBranch: string;
  disabled?: boolean;
}

const GitNotPushed: FC<GitNotPushedProps> = ({
  repositoryPath,
  activeBranch,
  disabled = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [
    pushConflictsModal,
    setPushConflictsModal,
  ] = useState<PushConflictsModalProps>();
  const {
    data: unpushedCommits,
    error: unpushedCommitsError,
    isLoading: unpushedCommitsLoading,
  } = useCommits(repositoryPath, activeBranch, CommitFilter.NotPushed);

  const { mutate, error } = useGitPushHook();

  const handlePush = (repositoryPath: string, activeBranch: string) => {
    mutate({ repositoryPath, activeBranch });
  };
  useEffect(() => {
    if (error) {
      //Try to parse the custom thrown error so we can extract the branch and commits
      const details: PushConflicts = JSON.parse(error?.message);

      setPushConflictsModal({
        show: true,
        branch: details.branch,
        commits_behind: details.commits_behind,
      });

      setIsModalOpen(true);
    }
  }, [error]);

  const renderLoaded = () => (
    <div className={'git-list-not-pushed-commits'}>
      <div className={'git-unpushed-status'}>
        {unpushedCommits && unpushedCommits.length > 0 ? (
          unpushedCommits.length > 1 ? (
            <span>There are {unpushedCommits.length} unpushed commits.</span>
          ) : (
            <span>There is {unpushedCommits.length} unpushed commit.</span>
          )
        ) : (
          <span>There are no unpushed commits.</span>
        )}
      </div>

      <div className={'git-button-bar'}>
        <Button
          color={'primary'}
          label={'Push'}
          disabled={disabled || !unpushedCommits?.length}
          onClick={() => handlePush(repositoryPath, activeBranch)}
        />
      </div>
    </div>
  );

  const renderLoading = () => (
    <div className={'git-list-commits'}>
      <ReactLoading
        className={'starting-stopping-spinner'}
        type={'cylon'}
        color={vars.colorPrimary}
      />
    </div>
  );

  const renderEmpty = () => (
    <div className={'git-list-commits'}>
      There are no commits for this repository yet
    </div>
  );

  const renderError = () => (
    <div className={'git-list-commits'}>{unpushedCommitsError}</div>
  );

  const renderComponent = () => {
    if (unpushedCommitsLoading) return renderLoading();
    if (!unpushedCommitsLoading) return renderLoaded();
    if (unpushedCommitsError) return renderError();
    return renderEmpty();
  };

  return (
    <Fragment>
      {renderComponent()}

      {pushConflictsModal && (
        <PushConflictsModal
          onClose={() => setIsModalOpen(false)}
          show={isModalOpen}
          activeBranch={activeBranch}
          commitsBehind={Number.parseInt(pushConflictsModal.commits_behind)}
          repositoryPath={repositoryPath}
        />
      )}
    </Fragment>
  );
};

export default GitNotPushed;
