import { useQueryClient } from '@tanstack/react-query';
import React, { FC, Fragment, useState } from 'react';
import ReactLoading from 'react-loading';

import vars from '../../../../../../../scss/base/var.module.scss';
import { useGitAddAllAndCommitHook } from '../../../../../../core/api/notebook';
import {
  useFileStatus,
  versionControlKeys,
} from '../../../../../../core/api/versionControl';
import { CommitFilter } from '../../../../../../core/api/workbench/git.notebook';
import Button from '../../../../../atoms/button/Button';
import GitCommitModal from '../git-commit/GitCommitModal';
import { speakingChangeType, styledPath } from '../utils/utils';

interface GitFileStatusProps {
  repositoryPath: string;
  activeBranch: string;
  disabled?: boolean;
}
export type CommitFormData = {
  repositoryPath: string;
  commitMessage: string;
  activeBranch: string;
};
const GitFileStatus: FC<GitFileStatusProps> = ({
  repositoryPath,
  activeBranch,
  disabled = false,
}) => {
  // Fetch file status data
  const { data: fileStatus, error, isLoading } = useFileStatus(repositoryPath);
  const queryClient = useQueryClient();

  // State for managing commit modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to invalidate file status cache
  async function invalidateFileStatus() {
    await queryClient.invalidateQueries(
      versionControlKeys.fileStatus(repositoryPath)
    );
  }

  // Function to invalidate commits cache
  async function invalidateCommits() {
    await queryClient.invalidateQueries(
      versionControlKeys.commits(
        repositoryPath,
        activeBranch,
        CommitFilter.NotPushed
      )
    );
  }

  // Hook for git add and commit operation
  const gitAddAllAndCommitHook = useGitAddAllAndCommitHook();

  // Handle commit action
  const handleCommit = async (
    repositoryPath: string,
    commitMessage: string,
    activeBranch: string
  ) => {
    const data: CommitFormData = {
      repositoryPath,
      commitMessage,
      activeBranch,
    };
    await gitAddAllAndCommitHook.mutateAsync(data).then(async () => {
      await invalidateFileStatus();
      await invalidateCommits();
      setIsModalOpen(false);
    });
  };

  // Render function for loaded state
  const renderLoaded = () => {
    // Check if there are any changes
    const atLeastOneChange =
      fileStatus &&
      ((fileStatus.staged && fileStatus.staged.length) ||
        (fileStatus.not_staged && fileStatus.not_staged.length > 0) ||
        (fileStatus.untracked_files && fileStatus.untracked_files.length > 0));

    return (
      <Fragment>
        {/* Render commit modal */}
        <GitCommitModal
          repositoryPath={repositoryPath}
          activeBranch={activeBranch}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onCommit={handleCommit}
        />

        <div className={'git-file-status'}>
          {/* Render staged files */}
          {fileStatus && fileStatus.staged && fileStatus.staged.length > 0 && (
            <div className={'git-file-status-category staged-files'}>
              <span className={'git-file-status-title'}>
                Changes to be committed:
              </span>
              <div className={'git-file-status-container'}>
                {fileStatus.staged.map((f, i) => (
                  <div className={'git-file-status-row staged-files'} key={i}>
                    <div className={'change-type'}>
                      <span>{speakingChangeType(f.change_type)}:</span>
                    </div>
                    <div className={'file-name'}>
                      <span>{styledPath(f)}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* Render unstaged files */}
          {fileStatus &&
            fileStatus.not_staged &&
            fileStatus.not_staged.length > 0 && (
              <div className={'git-file-status-category not-staged-files'}>
                <span className={'git-file-status-title'}>
                  Changes not staged for commit:
                </span>
                <div className={'git-file-status-container'}>
                  {fileStatus.not_staged.map((f, i) => (
                    <div
                      className={'git-file-status-row not-staged-files'}
                      key={i}
                    >
                      <div className={'change-type'}>
                        <span>{speakingChangeType(f.change_type)}:</span>
                      </div>
                      <div className={'file-name'}>
                        <span>{styledPath(f)}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          {/* Render untracked files */}
          {fileStatus &&
            fileStatus.untracked_files &&
            fileStatus.untracked_files.length > 0 && (
              <div className={'git-file-status-category untracked-files'}>
                <span className={'git-file-status-title'}>
                  Untracked Files:
                </span>

                <div className={'git-file-status-container'}>
                  {fileStatus.untracked_files.map((f, i) => (
                    <div
                      className={'git-file-status-row untracked-files'}
                      key={i}
                    >
                      <div className={'file-name'}>
                        <span>{f}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          {/* Display message if no changes */}
          {!atLeastOneChange && (
            <div className={'git-file-status-category'}>
              <span className={'git-file-status-title'}>
                No changes to commit
              </span>
            </div>
          )}
          {/* Render commit button */}
          <div className={'git-button-bar'}>
            <Button
              color={'primary'}
              label={'Add & Commit'}
              disabled={disabled || !atLeastOneChange}
              onClick={() => setIsModalOpen(true)}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  // Render function for loading state
  const renderLoading = () => (
    <div className={'git-file-status'}>
      <ReactLoading
        className={'starting-stopping-spinner'}
        type={'cylon'}
        color={vars.colorPrimary}
      />
    </div>
  );

  // Render function for empty state
  const renderEmpty = () => <div className={'git-file-status'}>Empty</div>;

  // Render function for error state
  const renderError = () => <div className={'git-file-status'}>{error}</div>;

  // Conditional rendering based on component state
  if (isLoading) return renderLoading();
  if (!isLoading) return renderLoaded();
  if (error) return renderError();
  return renderEmpty();
};

export default GitFileStatus;
