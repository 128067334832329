import classNames from 'classnames';
import { SearchResults } from 'common/dist/types/responseBodies/dashboard';
import { debounce } from 'lodash';
import React, { FC, useMemo, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { Field } from 'redux-form';

import QueryInput from './QueryInput';
import styles from './styles.module.scss';
import { ClickOutsideDetector } from '../../../atoms/click-outside-detector/ClickOutsideDetector';
import SearchResult from '../search-result/SearchResult';

export type Props = {
  reset: () => void;
  handleSubmit: (query: string) => void;
  loading: boolean;
  loaded: boolean;
  result: SearchResults;
  search: (query: string) => void;
  query?: string;
  error?: string;
};

const SearchBar: FC<Props> = ({
  reset,
  handleSubmit,
  query,
  loading,
  result,
  search,
}) => {
  const [closed, setClosed] = useState(true);
  const intl = useIntl();
  const ref = useRef(null);

  const searchDebounced = useMemo(
    () => debounce((query: string) => search(query), 500),
    [search]
  );

  const showSuggestions = () => {
    if (closed) {
      setClosed(false);
    }
  };

  const closeSuggestions = () => {
    if (!closed) {
      setClosed(true);
    }
  };

  const handleReset = () => {
    reset();
    search('');
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (query) {
      handleSubmit(query);
    }
  };

  return (
    <ClickOutsideDetector onClickOutside={() => setClosed(true)}>
      <form
        className={styles.searchBar}
        onSubmit={onSubmit}
        data-testid={'searchBar'}
        ref={ref}
      >
        <div className={styles.inputGroup}>
          <div className={styles.inputGroupButton}>
            <button type='submit' className={styles.button} disabled={!query}>
              {!loading && <span className='icon-search' />}
              {loading && (
                <span className={classNames('icon-refresh', 'icon-spin')} />
              )}
            </button>
          </div>
          <Field
            name='query'
            component={QueryInput}
            search={searchDebounced}
            showSuggestions={showSuggestions}
            intl={intl}
          />
          {query && (
            <div className={styles.inputGroupButton}>
              <button
                type='button'
                className={styles.button}
                onClick={handleReset}
              >
                <FiX size={16} className={styles.iconCross} />
              </button>
            </div>
          )}
        </div>
        {!closed && query && (
          <SearchResult result={result} onSuggestionClick={closeSuggestions} />
        )}
      </form>
    </ClickOutsideDetector>
  );
};

export default SearchBar;
