import { init, getInstanceByDom } from 'echarts';
import type { EChartsOption, ECharts, SetOptionOpts } from 'echarts';
import React, { useRef, useEffect } from 'react';
import type { CSSProperties, FC } from 'react';

export interface ReactEChartsProps {
  option: EChartsOption;
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
}

// Component copied from
// https://dev.to/manufac/using-apache-echarts-with-react-and-typescript-353k
const EChartWrapper: FC<ReactEChartsProps> = ({
  option,
  style,
  settings,
  loading,
}): JSX.Element => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<ECharts | null>(null);

  useEffect(() => {
    if (chartRef.current !== null) {
      chartInstance.current =
        getInstanceByDom(chartRef.current) ?? init(chartRef.current);
    }

    function resizeChart() {
      chartInstance.current?.resize();
    }

    const observer = new ResizeObserver(resizeChart);
    if (chartRef.current) observer.observe(chartRef.current);

    return () => {
      observer.disconnect();
      if (chartInstance.current) {
        chartInstance.current.dispose();
        chartInstance.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.setOption(option as unknown, settings);
    }
  }, [option, settings]);

  useEffect(() => {
    if (chartInstance.current) {
      if (loading) {
        chartInstance.current.showLoading();
      } else {
        chartInstance.current.hideLoading();
      }
    }
  }, [loading]);

  return (
    <div
      ref={chartRef}
      style={{ overflow: 'clip', width: '100%', height: '100%', ...style }}
    />
  );
};

export default EChartWrapper;
