import { useQueryClient } from '@tanstack/react-query';
import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import GitFileStatus from './file-status/GitFileStatus';
import GitBranches from './git-branches/GitBranches';
import GitNotPulled from './git-not-pulled/GitNotPulled';
import GitNotPushed from './git-not-pushed/GitNotPushed';
import GitListCommits from './list-commits/GitListCommits';
import styles from './styles.module.scss';
import { useModules } from '../../../../../core/api/modules';
import { notebookKeys } from '../../../../../core/api/notebook';
import {
  useActiveBranch,
  useCommits,
  versionControlKeys,
} from '../../../../../core/api/versionControl';
import { CommitFilter } from '../../../../../core/api/workbench/merger';
import { showConfigureCodeCapsuleModal } from '../../../../../redux/workbench/modules/codeCapsule.module';
import { loadRepoMeta } from '../../../../../redux/workbench/modules/notebook.module';
import { RootState } from '../../../../../store/store';
import { getActiveProjectPath } from '../../../../../store/workbench/activeProject.slice';
import { workbenchRoutes } from '../../../../../workbench/common/workbenchRoutes';
import Button from '../../../../atoms/button/Button';
import {
  generateRepositoryDetailsLink,
  moduleDetailsLink,
  modulesBuildLink,
} from '../../../../collaborationSpace/routes';
import { WORKBENCH_FILENAMES } from '../../config';

export type RepositoryMeta = {
  repoName: string;
  repoFullName: string;
  name: string;
  repoType: string;
  codeCapsuleCode: string;
  appCode: string;
};

const RepositoryInfo: FC = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isRefreshing, setIsRefreshing] = useState(false);

  const repoMetas = useSelector<
    RootState,
    {
      [path: string]: RepositoryMeta;
    }
  >((state) => state.workbench.repoMetas);

  const activeProjectPath: string = useSelector(getActiveProjectPath);

  const repositoryPath = useMemo(() => activeProjectPath, [activeProjectPath]);
  const infoFilePath = useMemo(
    () => `${repositoryPath}/${WORKBENCH_FILENAMES.REPOSITORY_META}`,
    [repositoryPath]
  );
  //We cannot filter the modules directly. A module containing a hyphen will not get recognized e.g. "BC - Python" will be safed and displayed as "bc-python"
  //Therefore we will fetch all modules and check the repositoryname
  const modules = (useModules({ has_repository: 'true' })?.data || []).filter(
    (m) =>
      m?.repository?.repoFullName === repoMetas?.[infoFilePath]?.repoFullName
  );
  const { data: activeBranch } = useActiveBranch(repositoryPath);
  const { data: unpushedCommits } = useCommits(
    repositoryPath,
    activeBranch?.name ?? '',
    CommitFilter.NotPushed
  );
  const { data: unpulledCommits } = useCommits(
    repositoryPath,
    activeBranch?.name ?? '',
    CommitFilter.NotPulled
  );
  useEffect(() => {
    if (!infoFilePath) return;
    dispatch(loadRepoMeta(infoFilePath));
  }, [infoFilePath, dispatch]);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      await queryClient.invalidateQueries(versionControlKeys.all());
      await queryClient.invalidateQueries(notebookKeys.all());
    } finally {
      setIsRefreshing(false);
    }
  };
  function renderCodeCapsuleMetaIfRequired() {
    const meta = repoMetas?.[infoFilePath];
    const { repoFullName, repoType } = meta || {};
    const [group, repoName] = repoFullName?.split('/') || [];
    let buildVersionLinkRoute: string;
    let detailsPageLinkRoute: string;
    const moduleCode = modules?.[0]?.code;
    switch (repoType) {
      case 'code-capsule':
      case 'app':
      case 'plain':
        detailsPageLinkRoute = generateRepositoryDetailsLink(repoFullName);
        break;
      case 'module':
        buildVersionLinkRoute = moduleCode
          ? modulesBuildLink(moduleCode, group, repoName)
          : undefined;
        detailsPageLinkRoute = moduleCode
          ? moduleDetailsLink(moduleCode)
          : undefined;
        break;
    }

    return (
      <Fragment>
        <h2 className='repository-info-category-title'>Repository Meta</h2>
        <div className='repository-meta'>
          {repoType === 'code-capsule' && (
            <div className={'repository-meta-buttons'}>
              <Button
                label={'Configure Code Capsule'}
                color={'primary'}
                onClick={() => {
                  dispatch(showConfigureCodeCapsuleModal(infoFilePath));
                }}
              />
            </div>
          )}
          <div className='buttons-horizontal'>
            {repoType === 'module' && (
              <Button
                label={'Build Version'}
                color='primary'
                disabled={!buildVersionLinkRoute}
                linkTo={buildVersionLinkRoute}
              />
            )}
            <Button
              label={'Show in Collaboration Space'}
              color='secondary'
              linkTo={detailsPageLinkRoute}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  if (!infoFilePath) {
    return <Redirect to={workbenchRoutes.basePath} />;
  }
  return (
    <div className='workbench-repository-info'>
      <div
        className='dialog-container'
        style={{
          paddingTop: '40px', // This wizard requires additional space in the top to look good
        }}
      >
        <div className='dialog-inner-container'>
          <div style={{ display: 'flex' }}>
            <div className='dialog-header' style={{ flexGrow: 1 }}>
              <div className='dialog-headline dialog-header-item'>
                Repository Info for{' '}
                {repoMetas[infoFilePath]?.name ||
                  repoMetas[infoFilePath]?.repoName ||
                  (repositoryPath || '.').split('.').slice(0, -1).join('.')}
              </div>
            </div>
            <div className={'refresh-button-container'}>
              <Button
                title={'Refresh all'}
                Icon={() => (
                  <FiRefreshCw
                    size={16}
                    className={isRefreshing ? styles.spin : ''}
                  />
                )}
                color={'transparent'}
                onClick={handleRefresh}
                disabled={isRefreshing}
              />
            </div>
          </div>

          {renderCodeCapsuleMetaIfRequired()}

          <span className='repository-info-category-title'>
            Branches
            <br />
          </span>
          <GitBranches activeBranch={activeBranch?.name} />
          {unpulledCommits && unpulledCommits.length > 0 && (
            <div>
              <span className='repository-info-category-title'>
                Remote Changes
              </span>
              <GitNotPulled
                repositoryPath={repositoryPath}
                activeBranch={activeBranch?.name}
              />
            </div>
          )}

          <span className='repository-info-category-title'>Local Changes</span>
          <GitFileStatus
            repositoryPath={repositoryPath}
            activeBranch={activeBranch?.name}
          />
          {unpushedCommits && unpushedCommits.length > 0 && (
            <div>
              <span className='repository-info-category-title'>
                Latest local Commits
              </span>
              <GitNotPushed
                repositoryPath={repositoryPath}
                activeBranch={activeBranch?.name}
              />
            </div>
          )}

          <GitListCommits
            repositoryPath={repositoryPath}
            activeBranch={activeBranch?.name}
          />
        </div>
      </div>
    </div>
  );
};

export default RepositoryInfo;
