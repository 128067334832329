import {
  TableV1ReportData as TableReportData,
  TableEntryV1ReportData as TableEntry,
} from 'common/dist/types/reportData/tableV1ReportData';

import { personTable } from './single/exampleData';
import { Config } from '../../../../../pages/augur/type';

export type { TableReportData, TableEntry };

export type TableConfig = {
  pageRowSize?: number;
  columnLimit?: number;
  hasSorting?: boolean;
  isStriped?: boolean;
};
export type ConfigTableConfig = Config<TableConfig>;

export const configTypeTable = `{
  pageRowSize?: number;
  columnLimit?: number;
  hasSorting?: boolean;
  isStriped?: boolean;
}`;
export const reportDataTypeTable = `{[columnName: string]: string | number | boolean }[]`;

export const defaultConfigTable: ConfigTableConfig = {
  pageRowSize: { source: 'hard-coded', value: 100 },
  columnLimit: { source: 'hard-coded', value: 100 },
  hasSorting: { source: 'hard-coded', value: false },
  isStriped: { source: 'hard-coded', value: true },
};

export const defaultReportDataDefaultTable: TableReportData = {
  data: personTable,
};
