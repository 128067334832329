import type { Node, NodeProps } from '@xyflow/react';
import classNames from 'classnames';
import React from 'react';

import { createEmptySubflow } from './GatewayNode';
import styles from './styles.module.scss';
import { AS_NODE_TYPES, AsNodesWithGateway, GenericNodeData } from './types';
import { generateHandles } from './utils';
import Button from '../../../../../atoms/button/Button';
import {
  getIncomingVarNameByHandleId,
  nodeFn,
  subflowFn,
  varname,
} from '../codegen';
import { useSubflowUtils } from '../hooks';
import { ConnectionMeta, GenerationProps } from '../types';

export type CustomPythonNodeData = {
  type: string;
  subflowId?: string;
  subflowConnections: ConnectionMeta;
} & GenericNodeData;

export type CustomPythonNode = Node<
  CustomPythonNodeData,
  typeof AS_NODE_TYPES.PYTHON_NODE
>;

export function isCustomPythonNode(
  node: AsNodesWithGateway
): node is CustomPythonNode {
  return node.type === AS_NODE_TYPES.PYTHON_NODE;
}

export const generatePythonFunctionNodeCode = ({
  context,
  node,
  flow,
}: GenerationProps<CustomPythonNode>) => {
  const paramNames = node.data.connections.inputs.map((input) =>
    getIncomingVarNameByHandleId(context, flow, node, input.id)
  );
  const returnValueNames = node.data.connections.outputs.map((output) =>
    varname(context, node, output.id)
  );
  const fnName = nodeFn(node.data.type);

  const subflow = flow.subflows.find((sf) => sf.id === node.data.subflowId);
  const subflowParameter = subflow
    ? `, subflow = ${subflowFn(context, subflow)}`
    : '';

  const parameters = `${paramNames.join(', ')}${subflowParameter}`;

  if (returnValueNames.length === 0) {
    return [`${fnName}(${parameters})`];
  } else if (returnValueNames.length === 1) {
    return [
      `${returnValueNames[0]} = ${fnName}(${parameters})`,
      `save_var_to_results('${node.id}', [${returnValueNames.join(', ')}])`,
    ];
  } else {
    return [
      `(${returnValueNames.join(', ')}) = ${fnName}(${parameters})`,
      `save_var_to_results('${node.id}', [${returnValueNames.join(', ')}])`,
    ];
  }
};

export function CustomPythonNode({ id, data }: NodeProps<CustomPythonNode>) {
  const {
    filePath,
    name,
    subflowId,
    connections: { outputs, inputs },
    subflowConnections,
  } = data;

  const { addSubflow, openSubflow } = useSubflowUtils(filePath);

  return (
    <div
      className={classNames(
        'react-flow__node-default',
        styles.defaultNodeStyle
      )}
      style={data.highlight ? { backgroundColor: 'rgb(222, 222, 222)' } : {}}
    >
      {generateHandles(inputs, 'target')}
      <div>{name}</div>
      {!!subflowConnections && (
        <>
          {!subflowId && (
            <Button
              label={'Create Subflow'}
              onClick={() => {
                const subflow = createEmptySubflow(
                  filePath,
                  id,
                  subflowConnections.inputs,
                  subflowConnections.outputs,
                  false
                );
                addSubflow(id, subflow);
                openSubflow(id, subflow.id);
              }}
            />
          )}
          {subflowId && (
            <Button
              label={'Open Subflow'}
              onClick={() => openSubflow(id, subflowId)}
            />
          )}
        </>
      )}
      {generateHandles(outputs, 'source')}
    </div>
  );
}
