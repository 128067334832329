import {
  RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_ASSISTANTS_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX,
} from 'common/dist/constants/keycloak';
import React, { FC } from 'react';
import {
  FiDatabase,
  FiEdit,
  FiGrid,
  FiLayers,
  FiMonitor,
  FiTarget,
} from 'react-icons/fi';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import SingleDomainPermissions from './SingleDomainPermissions';
import styles from './styles.module.scss';
import { toLinkWhitelistQuery } from '../../../../utils';
import * as routes from '../../../index/routes';
import { MenuEntryType } from '../../../molecules/with-sidemenu/SideMenu';
import WithSidemenu from '../../../molecules/with-sidemenu/WithSidemenu';
import { MAIN_MENU_QUERY_WHITELIST } from '../../../organisms/main-menu/MainMenu';
import MainContainer from '../../../pages/main-container/MainContainer';
import NotFound from '../../../pages/not-found/NotFound.container';
import { usersRoutes } from '../routes';

type Props = {};

export const detailsRoute = (id) =>
  `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.users}/${usersRoutes.domainPermissions.path}/${id}`;

function getActiveCategory(path: string) {
  if (!path) return null;
  const rep = path.replace(
    `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.users}/${usersRoutes.domainPermissions.path}/`,
    ''
  );
  const parts = rep.split('/');
  return parts.length >= 1 ? parts[0] : null;
}

const DomainPermissions: FC<Props> = () => {
  const location = useLocation();
  const menuEntries: MenuEntryType[] = [
    {
      type: 'link',
      id: RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX,
      to: detailsRoute(RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX),
      nameIntlId: 'dashboard.tab.modelManagement',
      nameDefault: 'Model Management',
      icon: FiGrid,
    },
    {
      type: 'link',
      id: RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX,
      to: detailsRoute(RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX),
      nameIntlId: 'dashboard.tab.apps',
      nameDefault: 'AI Apps',
      icon: FiMonitor,
    },
    {
      type: 'link',
      id: RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX,
      to: detailsRoute(RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX),
      nameIntlId: 'todo',
      nameDefault: 'Workbench & Collaboration',
      icon: FiEdit,
    },
    {
      type: 'link',
      id: RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX,
      to: detailsRoute(RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX),
      nameIntlId: 'dashboard.tab.orchestration',
      nameDefault: 'Orchestration',
      icon: FiLayers,
    },
    {
      type: 'link',
      id: RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX,
      to: detailsRoute(RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX),
      nameIntlId: 'dashboard.tab.data_management',
      nameDefault: 'Data Management',
      icon: FiDatabase,
    },
    {
      type: 'link',
      id: RESOURCE_TYPE_DASHBOARD_ASSISTANTS_SUFFIX,
      to: detailsRoute(RESOURCE_TYPE_DASHBOARD_ASSISTANTS_SUFFIX),
      nameIntlId: 'dashboard.tab.assistants',
      nameDefault: 'Assistants',
      icon: FiTarget,
    },
  ];

  const history = useHistory();
  const selectedCategory = getActiveCategory(history.location.pathname);

  return (
    <MainContainer fullWidth transparent>
      <div className={styles.domainPermissions}>
        <WithSidemenu
          menuEntries={menuEntries}
          selectedTab={selectedCategory}
          preserveWhitelistQueryOnLink={['search']}
        >
          <div className={styles.permissionsContainer}>
            <Switch>
              <Route
                path={`${usersRoutes.basePath}/${usersRoutes.domainPermissions.path}/:id`}
                component={SingleDomainPermissions}
              />
              <Redirect
                to={toLinkWhitelistQuery(
                  `${usersRoutes.basePath}/${usersRoutes.domainPermissions.path}/${RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX}`,
                  location,
                  MAIN_MENU_QUERY_WHITELIST
                )}
                path={`${usersRoutes.basePath}/${usersRoutes.domainPermissions.path}/`}
                exact
              />
              {/*Fallback: 404*/}
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </div>
        </WithSidemenu>
      </div>
    </MainContainer>
  );
};

export default DomainPermissions;
