import { REPO_TYPE } from 'common/dist/types/repository';
import qs from 'qs';
import { generatePath } from 'react-router';

/**
 * Per level there is one basePath and the other paths are relative to that basePath.
 * E.g. collaborationSpaceRoutes.modules.create can be found under collaborationSpaceRoutes.basePath + collaborationSpaceRoutes.modules.basePath + collaborationSpaceRoutes.modules.create
 *
 * Guideline: Routes should be used relatively and Links/urls should be absolute and build here with generatePath
 * DON'T BUILD LINKS OUTSIDE OF HERE. IT IS UNMAINTAINABLE
 */
export const collaborationSpaceRoutes = {
  basePath: '/app/collab',

  overviewModules: 'modules',
  overviewCodeCapsules: 'code-capsules',
  overviewApps: 'apps',
  overviewPlain: 'repositories',

  repositories: {
    basePath: 'repositories',
    details: 'details',

    create: 'repositories/create',
    clone: 'clone',
    merge: 'merge',
    mergeWizardEdit: 'merge/edit/:id',
    branches: 'branches',
    mergeRequest: 'merge-requests',
    resolve: 'resolve',
    buildApp: 'build-app',
    buildCodeCapsule: 'build-cc',
  },
  apps: {
    basePath: 'apps',
    create: 'apps/create',
  },
  codeCapsules: {
    basePath: 'code-capsules',
    create: 'code-capsules/create',
  },
  modules: {
    basePath: 'modules',

    create: 'create',
    edit: 'edit/:moduleCode',
    clone: ':moduleCode/:group/:repositoryName/clone',
    build: ':moduleCode/:group/:repositoryName/build',

    details: {
      basePath: 'details/:moduleCode',

      versions: 'versions',
      files: 'files',
      branches: 'branches',
      mergeRequest: 'merge-requests',
      merge: ':group/:repositoryName/merge',
      mergeWizardEdit: ':group/:repositoryName/merge/edit/:id',
      mergeRequestDetails: ':group/:repositoryName/merge-requests/:id',
    },
  },
};

export const overviewModulesLink = () =>
  generatePath(
    `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.overviewModules}`
  );

export const modulesCreateLink = () =>
  generatePath(
    `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.modules.basePath}/${collaborationSpaceRoutes.modules.create}`
  );

export const modulesEditLink = (moduleCode: string) =>
  generatePath(
    `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.modules.basePath}/${collaborationSpaceRoutes.modules.edit}`,
    { moduleCode }
  );

export const modulesBuildLink = (
  moduleCode: string,
  group: string,
  repositoryName: string
) =>
  generatePath(
    `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.modules.basePath}/${collaborationSpaceRoutes.modules.build}`,
    { moduleCode, group, repositoryName }
  );

export const moduleDetailsLink = (moduleCode: string) =>
  generatePath(
    `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.modules.basePath}/${collaborationSpaceRoutes.modules.details.basePath}`,
    { moduleCode }
  );

export const repositoryCreateLink = (type: string) => {
  let route;
  switch (type) {
    case REPO_TYPE.APP:
      route = collaborationSpaceRoutes.apps.create;
      break;
    case REPO_TYPE.CODE_CAPSULE:
      route = collaborationSpaceRoutes.codeCapsules.create;
      break;
    default:
      route = collaborationSpaceRoutes.repositories.create;
      break;
  }

  return generatePath(`${collaborationSpaceRoutes.basePath}/${route}`);
};

export const repositoryCreateHabitatLink = (
  type: string,
  habitatCode: string
) => repositoryCreateLink(type) + `?habitatCode=${habitatCode}`;

/**
 * TODO This should also use generatePath and pass group and repoName instead of both combined into repoFullName
 * @param repoFullName
 */
export const repositoryDetailsLink = (repoFullName: string) =>
  `${collaborationSpaceRoutes.basePath}/${repoFullName}`;
export const generateRepositoryDetailsLink = (repoFullName: string) =>
  generatePath(`${collaborationSpaceRoutes.basePath}/${repoFullName}`, {
    repoFullName,
  });

/**
 * TODO this is with the correct parameters, but it should be the primary function and not call the worse one. Also use generatePath
 * @param group
 * @param repositoryName
 */
export const repositoryDetailsLink2 = (group: string, repositoryName: string) =>
  repositoryDetailsLink(group + '/' + repositoryName);

/**
 * TODO This should also use generatePath and pass group and repoName instead of both combined into repoFullName
 * @param repoFullName
 */
export const repositoryCloneLink = (repoFullName: string) =>
  `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.clone}/${repoFullName}`;

/**
 * TODO this is with the correct parameters, but it should be the primary function and not call the worse one. Also use generatePath
 * @param group
 * @param repositoryName
 * @param moduleCode
 */
export const repositoryCloneLink2 = (
  group: string,
  repositoryName: string,
  queryParams?: Record<string, string>
) =>
  repositoryCloneLink(group + '/' + repositoryName) +
  (queryParams ? `?${qs.stringify(queryParams)}` : '');

/* TODO This should also use generatePath and pass group and repoName instead of both combined into repoFullName
 * @param repoFullName
 */
export const repositoryBuildAppLink = (group: string, repositoryName: string) =>
  `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.buildApp}/${group}/${repositoryName}`;
export const generateRepositoryBuildAppLink = (
  group: string,
  repositoryName: string
) =>
  generatePath(
    `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.basePath}/${group}/${repositoryName}/${collaborationSpaceRoutes.repositories.buildApp}`,
    {
      group,
      repositoryName,
    }
  );
/* TODO This should also use generatePath and pass group and repoName instead of both combined into repoFullName
 * @param repoFullName
 */
export const repositoryBuildCodeCapsuleLink = (
  group: string,
  repositoryName: string
) =>
  `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.buildCodeCapsule}/${group}/${repositoryName}`;

/**
 * TODO this is with the correct parameters, but it should be the primary function and not call the worse one. Also use generatePath
 * @param group
 * @param repositoryName
 */
export const repositoryMergeRequestLink = (
  group: string,
  repositoryName: string
) =>
  `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.basePath}/${group}/${repositoryName}/${collaborationSpaceRoutes.repositories.merge}`;

/**
 * TODO this is with the correct parameters, but it should be the primary function and not call the worse one. Also use generatePath
 * @param group
 * @param repositoryName
 */
export const repositoryMergeRequestSourceBranchLink = (
  group: string,
  repositoryName: string,
  sourceBranch
) =>
  repositoryMergeRequestLink(group, repositoryName) +
  `?sourceBranch=${sourceBranch}`;

/**
 * TODO this is with the correct parameters, but it should be the primary function and not call the worse one. Also use generatePath
 * @param group
 * @param repositoryName
 * @param id
 */
export const repositoryMergeRequestIdLink = (
  group: string,
  repositoryName: string,
  id: number
) =>
  `${collaborationSpaceRoutes.basePath}/${
    collaborationSpaceRoutes.repositories.basePath
  }/${group}/${repositoryName}/${
    collaborationSpaceRoutes.repositories.mergeRequest
  }${id ? '/' + id : ''}`;

/**
 * TODO this is with the correct parameters, but it should be the primary function and not call the worse one. Also use generatePath
 * @param group
 * @param repositoryName
 * @param id
 */
export const repositoryMergeRequestEditLink = (
  group: string,
  repositoryName: string,
  id: number
) =>
  `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.basePath}/${group}/${repositoryName}/${collaborationSpaceRoutes.repositories.merge}/edit/${id}`;
/**
 * @param moduleCode
 * @param group
 * @param repositoryName
 * @param id
 */
export const moduleMergeRequestEditLink = (
  moduleCode: string,
  group: string,
  repositoryName: string,
  id: number
) =>
  generatePath(
    `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.modules.basePath}/${collaborationSpaceRoutes.modules.details.basePath}/${collaborationSpaceRoutes.modules.details.mergeWizardEdit}`,
    { moduleCode, group, repositoryName, id }
  );

/**
 * @param moduleCode
 */
export const moduleMergeRequestLink = (moduleCode: string) =>
  generatePath(
    `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.modules.basePath}/${collaborationSpaceRoutes.modules.details.basePath}/${collaborationSpaceRoutes.modules.details.mergeRequest}`,
    { moduleCode }
  );
/**
 * @param moduleCode
 * @param group
 * @param repositoryName
 */
export const moduleMergeLink = (
  moduleCode: string,
  group: string,
  repositoryName: string
) =>
  generatePath(
    `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.modules.basePath}/${collaborationSpaceRoutes.modules.details.basePath}/${collaborationSpaceRoutes.modules.details.merge}`,
    { moduleCode, group, repositoryName }
  );

/**
 * @param moduleCode
 * @param group
 * @param repositoryName
 * @param sourceBranch
 */
export const moduleMergeRequestSourceBranchLink = (
  moduleCode: string,
  group: string,
  repositoryName: string,
  sourceBranch: string
) =>
  generatePath(
    `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.modules.basePath}/${collaborationSpaceRoutes.modules.details.basePath}/${collaborationSpaceRoutes.modules.details.merge}`,
    { moduleCode, group, repositoryName }
  ) + `?sourceBranch=${sourceBranch}`;

/**
 * @param moduleCode
 * @param group
 * @param repositoryName
 * @param id
 */
export const moduleMergeRequestIdLink = (
  moduleCode: string,
  group: string,
  repositoryName: string,
  id: number
) =>
  generatePath(
    `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.modules.basePath}/${collaborationSpaceRoutes.modules.details.basePath}/${collaborationSpaceRoutes.modules.details.mergeRequestDetails}`,
    { moduleCode, group, repositoryName, id }
  );

export const overviewAppsLink = () =>
  generatePath(
    `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.overviewApps}`
  );

export const overviewCodeCapsulesLink = () =>
  generatePath(
    `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.overviewCodeCapsules}`
  );

export const overviewPlainLink = () =>
  generatePath(
    `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.overviewPlain}`
  );
