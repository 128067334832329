import { Node, NodeProps } from '@xyflow/react';
import classNames from 'classnames';
import React from 'react';

import { createEmptySubflow } from './GatewayNode';
import styles from './styles.module.scss';
import { AS_NODE_TYPES, AsNodesWithGateway, GenericNodeData } from './types';
import { generateHandles } from './utils';
import Button from '../../../../../atoms/button/Button';
import {
  generateReturnValues,
  getIncomingVarNameByHandleId,
  subflowFn,
} from '../codegen';
import { useSubflowUtils } from '../hooks';
import { GenerationProps } from '../types';

export type SubflowNodeData = GenericNodeData & {
  subflowId: string;
};

export type SubflowNode = Node<SubflowNodeData, typeof AS_NODE_TYPES.SUBFLOW>;

export function isSubflowNode(node: AsNodesWithGateway): node is SubflowNode {
  return node.type === AS_NODE_TYPES.SUBFLOW;
}

export const generateSubflowCode = ({
  context,
  node,
  flow,
}: GenerationProps<SubflowNode>) => {
  const subflow = flow.subflows.find(
    (subflow) => subflow.id === node.data.subflowId
  );
  const subflowName = subflowFn(context, subflow);

  const inputVars = node.data.connections.inputs
    .map((input) => getIncomingVarNameByHandleId(context, flow, node, input.id))
    .join(', ');
  const outputVars = generateReturnValues(context, node);

  if (!outputVars) return [`${subflowName}(${inputVars})`];

  // Convert outputVars to an array for save_var_to_results
  const outputs = outputVars.startsWith('(')
    ? outputVars.slice(1, -1).split(', ')
    : [outputVars];

  return [
    `${outputVars} = ${subflowName}(${inputVars})`,
    `save_var_to_results('${node.id}', [${outputs.join(', ')}])`,
  ];
};

export function SubflowNode({ id, data }: NodeProps<SubflowNode>) {
  const {
    filePath,
    highlight,
    connections: { inputs, outputs },
    subflowId,
  } = data;

  const { addSubflow, openSubflow } = useSubflowUtils(filePath);

  return (
    <div
      className={classNames(
        'react-flow__node-default',
        styles.defaultNodeStyle
      )}
      style={highlight ? { backgroundColor: 'rgb(222, 222, 222)' } : {}}
    >
      {generateHandles(inputs, 'target')}
      SUBFLOW
      {!subflowId && (
        <Button
          label={'Create Subflow'}
          onClick={() => {
            const subflow = createEmptySubflow(filePath, id, [], [], true);
            addSubflow(id, subflow);
            openSubflow(id, subflow.id);
          }}
        />
      )}
      {subflowId && (
        <Button
          label={'Open Subflow'}
          onClick={() => openSubflow(id, subflowId)}
        />
      )}
      {generateHandles(outputs, 'source')}
    </div>
  );
}
