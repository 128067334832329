import React, { FC } from 'react';

type Props = {
  width?: number | string;
  height?: number | string;
};

const AltaSigmaLogo: FC<Props> = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 33 25' {...props}>
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M90.141 2.283h2.201v2.13h-2.2M29.248 9.995c-1.764 0-3.194 1.378-3.194 3.08q0 .315.062.613c.008.085.018.17.018.257 0 1.545-1.297 2.793-2.896 2.793-.42 0-.816-.089-1.177-.246-.076-.044-.152-.091-.232-.132a3.48 3.48 0 0 1-1.19-2.609c0-1.113.535-2.107 1.369-2.757 1.818-.861 3.07-2.663 3.07-4.747 0-2.921-2.455-5.288-5.486-5.288-2.868 0-5.223 2.126-5.463 4.833-.498 2.645-2.897 4.65-5.784 4.65a6.1 6.1 0 0 1-1.673-.233 4.3 4.3 0 0 0-1.781-.384c-2.33 0-4.215 1.817-4.215 4.063 0 2.242 1.885 4.064 4.215 4.064 2.203 0 4.009-1.633 4.195-3.71.612-2.302 2.774-4.006 5.356-4.006.66 0 1.294.115 1.882.319 1.278.58 2.164 1.827 2.164 3.277a3.55 3.55 0 0 1-1.186 2.64c-1.339.777-2.238 2.186-2.238 3.802 0 2.448 2.06 4.435 4.602 4.435 2.54 0 4.6-1.987 4.6-4.435 0-.275-.03-.542-.08-.803l.005.007c0-1.898 1.57-3.498 3.538-3.498.247 0 .485.027.717.073.256.065.524.102.802.102 1.765 0 3.195-1.38 3.195-3.08s-1.43-3.08-3.195-3.08'
    />
  </svg>
);
export default AltaSigmaLogo;
