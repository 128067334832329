import React, { FC } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import styles from './styles.module.scss';
import AltaSigmaLogo from '../AltaSigmaLogo';

type Props = {
  title: MessageDescriptor;
};

const Placeholder: FC<Props> = ({ title }) => {
  return (
    <div className={styles.placeholder}>
      <div className={styles.inner}>
        <div className={styles.iconContainer}>
          <AltaSigmaLogo width={'36px'} height={'36px'} />
        </div>
        <FormattedMessage {...title}>
          {(text) => <span className={styles.title}>{text}</span>}
        </FormattedMessage>
      </div>
    </div>
  );
};

export default Placeholder;
