import { NodeTypes } from "@xyflow/react";

import { ConditionalNode } from './ConditionalNode';
import { CustomPythonNode } from './CustomPythonNode';
import { GatewayNode } from './GatewayNode';
import { SubflowNode } from './SubflowNode';
import { ConnectionMeta } from '../types';

export const AS_NODE_TYPES = {
  CONDITIONAL: 'conditional',
  PYTHON_NODE: 'python_node',
  SUBFLOW: 'subflow',
  GATEWAY: 'gateway',
} as const;
export type AsNodeTypes = typeof AS_NODE_TYPES[keyof typeof AS_NODE_TYPES];

export const nodeTypes = {
  [AS_NODE_TYPES.CONDITIONAL]: ConditionalNode,
  [AS_NODE_TYPES.SUBFLOW]: SubflowNode,
  [AS_NODE_TYPES.GATEWAY]: GatewayNode,
  [AS_NODE_TYPES.PYTHON_NODE]: CustomPythonNode,
} satisfies NodeTypes;

export type GenericNodeData = {
  filePath: string;
  settings?: Record<string, unknown>;
  connections: ConnectionMeta;
  highlight?: boolean;
  name?: string;
};

export type AsNodes = ConditionalNode | CustomPythonNode | SubflowNode;

export type AsNodesWithGateway = AsNodes | GatewayNode;
