import React, { FC } from 'react';

import { AsNodesWithGateway } from './types';
import { useContent } from '../../../../../../core/api/workbench/content';
import TabLine from '../../../../../molecules/tab-line/TabLine';
import Table from '../../../../../molecules/table/Table';

export type Props = {
  selectedNode: AsNodesWithGateway | null;
  flowPath: string;
};

const TablePreview: FC<{ data: Array<Record<string, string>> }> = ({
  data,
}) => {
  const columnNames = data.length > 0 ? Object.keys(data[0]) : [];

  const renderColumns = columnNames.map((columnName) => ({
    key: columnName,
    renderHeader: () => <span>{columnName}</span>,
    renderCell: (col: string) => <span>{col}</span>,
  }));

  return <Table data={data} renderColumns={renderColumns} showHeader />;
};

const TabContent: FC<{ output: any }> = ({ output }) => {
  if (output === undefined || output === null) {
    return <p>Not available. Execute node first.</p>;
  }

  // Dynamically infer table structure
  if (
    Array.isArray(output) &&
    output.length > 0 &&
    typeof output[0] === 'object' &&
    !Array.isArray(output[0])
  ) {
    return <TablePreview data={output} />;
  }

  if (
    typeof output === 'string' ||
    typeof output === 'number' ||
    typeof output === 'boolean'
  ) {
    return <pre>{String(output)}</pre>;
  }

  return <pre>{JSON.stringify(output, null, 2)}</pre>;
};

const NodeContent: FC<{
  selectedNode: AsNodesWithGateway | null;
  previewData: any | undefined;
}> = ({ selectedNode, previewData }) => {
  if (!selectedNode) {
    return <p style={{ padding: '10px' }}>No node selected.</p>;
  }

  if (!selectedNode.data?.connections?.outputs?.length) {
    return (
      <p style={{ padding: '10px' }}>This node does not have any output.</p>
    );
  }

  if (!previewData) {
    return (
      <p style={{ padding: '10px' }}>
        Output not available yet. Execute node first.
      </p>
    );
  }

  if (previewData.error) {
    return (
      <p style={{ padding: '10px' }}>
        Unable to load node content: {previewData.error}
      </p>
    );
  } else if (!Array.isArray(previewData)) {
    return (
      <p style={{ padding: '10px' }}>
        Unable to load node content: Not an array.
      </p>
    );
  }

  const tabNames: string[] = previewData.map(
    (_, index: number) => `Output ${index + 1}`
  );

  const tabContents: JSX.Element[] = previewData.map(
    (output: any, index: number) => (
      <div key={index} style={{ height: '250px', overflow: 'auto' }}>
        <TabContent output={output} />
      </div>
    )
  );

  return <TabLine tabNames={tabNames} tabContents={tabContents} />;
};

const NodeResultPreview: FC<Props> = ({ selectedNode, flowPath }) => {
  // Remove asflow filename from path
  const flowDirPath = flowPath.substring(0, flowPath.lastIndexOf('/'));

  const selectedNodeResultPath =
    flowDirPath + `/results/${selectedNode?.id}.json`;

  const { data: selectedNodeResultData } = useContent(
    selectedNodeResultPath,
    !!selectedNode
  );

  let previewData = undefined;
  if (selectedNodeResultData && selectedNodeResultData.content) {
    try {
      previewData = JSON.parse(selectedNodeResultData.content);
    } catch (error) {
      previewData = {
        error: `Failed to parse JSON result for node ${selectedNode?.id}`,
      };
    }
  }

  return (
    <div style={{ height: '300px', flex: '0 0 auto' }}>
      <NodeContent selectedNode={selectedNode} previewData={previewData} />
    </div>
  );
};

export default NodeResultPreview;
