import classNames from 'classnames';
import { ConfigNavbarLabel } from 'common/dist/types/frontendConfig';
import { getLinkForFirstPermittedMainTab } from 'common/dist/utils/authorization.general';
import React, { FC, useState } from 'react';
import { GoSidebarCollapse, GoSidebarExpand } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import NavbarLabel from './NavbarLabel';
import styles from './styles.module.scss';
import { RootState } from '../../../../store/store';
import Logo from '../../../atoms/icons/logo/Logo';
import LogoIcon from '../../../atoms/icons/logo-icon/LogoIcon';
import TopMenu from '../profile-menu/TopMenu.container';
import WhatsNew from '../profile-menu/WhatsNew';
import ProfileMenuButtonContainer from '../profile-menu-button/ProfileMenuButton.container';
import QuickMenu from '../quick-menu/QuickMenu';
import SearchBar from '../search-bar/SearchBar.container';

export type Props = {
  isTopMenuShown: boolean;
  configNavbarLabel?: ConfigNavbarLabel;
  isMenuCollapsed: boolean;
  setMenuCollapsed: (isMenuCollapsed: boolean) => void;
};

/**
 * Docusaurus likes trailing slashes, so it will redirect to /doc/
 * But it seems important to oauth2-proxy that we start on /doc for the login process. That way we also land on /doc
 * after the login process. Otherwise, we land on just /
 */
export const docuLink = '/doc';

const Header: FC<Props> = ({
  isTopMenuShown,
  configNavbarLabel,
  isMenuCollapsed,
  setMenuCollapsed,
}) => {
  const [showWhatsNewModal, setShowWhatsNewModal] = useState(false);

  const permissions = useSelector<RootState, Record<string, string[]>>(
    (state) => state.dashboard?.permissions?.data
  );
  const mainTabsDefaultLink = getLinkForFirstPermittedMainTab(permissions);

  return (
    <header
      className={classNames(styles.header, {
        [styles.menuCollapsed]: isMenuCollapsed,
      })}
    >
      <div className={styles.headerRow}>
        <div className={styles.headerLeft}>
          <div className={styles.logoContainer}>
            <Link to={mainTabsDefaultLink} className={styles.headerLogo}>
              {isMenuCollapsed ? <LogoIcon /> : <Logo />}
            </Link>
          </div>
          <div
            className={styles.menuCollapseIcon}
            onClick={() => setMenuCollapsed(!isMenuCollapsed)}
          >
            {isMenuCollapsed ? (
              <GoSidebarExpand size={16} />
            ) : (
              <GoSidebarCollapse size={16} />
            )}
          </div>
        </div>
        <div className={styles.headerCenter}>
          <SearchBar />
          <QuickMenu />
        </div>
        <div className={styles.headerRight}>
          <NavbarLabel configNavbarLabel={configNavbarLabel} />
          <Link className={styles.helpIcon} to={docuLink} target={'_blank'}>
            <span>?</span>
          </Link>
          <ProfileMenuButtonContainer />
        </div>
        {isTopMenuShown && (
          <TopMenu showWhatsNewModal={() => setShowWhatsNewModal(true)} />
        )}
      </div>

      <WhatsNew
        show={showWhatsNewModal}
        hideModal={() => setShowWhatsNewModal(false)}
      />
    </header>
  );
};

export default Header;
