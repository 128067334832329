import { HABITAT_SCOPES } from 'common/dist/constants/keycloak';
import { HabitatWithScopes } from 'common/dist/types/habitat';
import { Job, JobGroupTopologyType } from 'common/dist/types/job';
import _ from 'lodash';
import { Edge, Node } from 'reactflow';

import { Value } from './form';
import { hasModal } from './Modals';
import { Props as JobProps } from '../job/Job';
import jobStyles from '../job/styles.module.scss';

export function ElementsToJobsAndJobGroupTopology(
  nodes: Node<JobProps>[],
  edges: Edge[]
): Value {
  const jobs = nodes.map((node) => node.data.job);
  const jobGroupTopology: JobGroupTopologyType[] = edges.flatMap((edge) =>
    EdgeTojobGroupTopology(edge)
  );
  // Backend requires JobGroupTopology even without any connections, why?
  const emptyJobGroupTopologyPerJob: JobGroupTopologyType[] = nodes.map(
    (node) => ({
      jobCode: node.data.job.jobCode,
      predecessors: [],
      successors: [],
    })
  );
  // Merge same jobCodes, as the previous step added two JobGroupTopologyType for each Edge
  const jobGroupTopologyGrouped = _.groupBy(
    [...jobGroupTopology, ...emptyJobGroupTopologyPerJob],
    (jgt) => jgt.jobCode
  );
  const jobGroupTopologyMerged = Object.entries(jobGroupTopologyGrouped).map(
    ([jobCode, jobGroupTopology]: [string, JobGroupTopologyType[]]) => ({
      jobCode,
      predecessors: jobGroupTopology.flatMap((jgt) => jgt.predecessors),
      successors: jobGroupTopology.flatMap((jgt) => jgt.successors),
    })
  );
  return {
    jobs,
    jobGroupTopology: jobGroupTopologyMerged,
  };
}

/**
 *
 * @param job - It should contain the superType, but otherwise the fields don't matter
 * @param augurNames
 * @param codeCapsuleNames
 * @param onNodesRemove
 * @param editJob
 * @constructor
 */
export function JobToNode(
  job: Partial<Job>,
  augurNames: Record<string, string>,
  codeCapsuleNames: Record<string, string>,
  habitats: HabitatWithScopes[],
  onNodesRemove: (nodesToRemove: Node<JobProps>[]) => void,
  editJob: (nodeId: string) => void
): Node<JobProps> {
  let augurName: string,
    codeCapsuleName: string,
    missingPermissions = true;
  switch (job.superType) {
    case 'augur':
      augurName = augurNames[job.augurCode];
      missingPermissions = false;
      break;
    case 'code-capsule': {
      codeCapsuleName = codeCapsuleNames[job.codeCapsuleCode];
      const habitat = habitats.find(
        (habitat) => habitat.code === job.habitatCode
      );
      // the habitat doesn't exist in habitats if the user doesn't have the permissions
      missingPermissions =
        !habitat?.allowedScopes.includes(HABITAT_SCOPES.VIEW) &&
        !habitat?.allowedScopes.includes(HABITAT_SCOPES.EDIT);
      break;
    }
  }
  const node = {
    id: job.jobCode,
    type: 'jobNodeLarge',
    data: {
      job: job as Job,
      augurName,
      codeCapsuleName,
      showJobStatus: false,
      handlesDisabled: false,
    },
    // Use this class to draw the border so that the handles lie on it
    className: jobStyles.flowJob,
    position: { x: 0, y: 0 },
  };
  return {
    ...node,
    data: {
      ...node.data,
      editable: true,
      missingPermissions,
      onClickEdit: hasModal(job.superType)
        ? (jobCode) => editJob(node.id)
        : undefined,
      onClickDelete: (jobCode) => onNodesRemove([node]),
    },
  };
}

/**
 * @param jobGroupTopology
 * @constructor
 */
export const JobGroupTopologyToEdges = (
  jobGroupTopology: JobGroupTopologyType
): Edge<JobProps>[] => {
  return jobGroupTopology.successors.map((successor) => ({
    id: `e${jobGroupTopology.jobCode}-${successor}`,
    source: jobGroupTopology.jobCode,
    target: successor,
    style: { stroke: '#777' },
  }));
};

/**
 * Turn an edge with source and target into two JobGroupTopologyType entries, one for each
 * @param edge
 */
export const EdgeTojobGroupTopology = (
  edge: Edge<JobProps>
): JobGroupTopologyType[] => {
  return [
    { jobCode: edge.source, predecessors: [], successors: [edge.target] },
    {
      jobCode: edge.target,
      predecessors: [edge.source],
      successors: [],
    },
  ];
};

/**
 * Combine jobGroupTopologies with changes: Update those, which have added predecessors or successors and *add* those
 * also add the completely new ones
 * @deprecated
 * @param jobGroupTopologies
 * @param changes
 */
export function AddJobGroupTopologyChanges(
  jobGroupTopologies: JobGroupTopologyType[],
  changes: JobGroupTopologyType[]
): JobGroupTopologyType[] {
  const changed: JobGroupTopologyType[] = jobGroupTopologies.flatMap((jgt) => {
    const relevantChanges = changes.filter(
      (jgtChange) => jgt.jobCode === jgtChange.jobCode
    );
    return relevantChanges.length > 0
      ? {
          jobCode: jgt.jobCode,
          predecessors: [
            ...jgt.predecessors,
            ...relevantChanges.flatMap((rc) => rc.predecessors),
          ],
          successors: [
            ...jgt.successors,
            ...relevantChanges.flatMap((rc) => rc.successors),
          ],
        }
      : [jgt];
  });
  const added: JobGroupTopologyType[] = changes.filter(
    (jgtChange) =>
      !jobGroupTopologies.some((jgt) => jgtChange.jobCode === jgt.jobCode)
  );
  return [...changed, ...added];
}

/**
 * Method to set the data in the drag event, must be stringified and later parsed, since only string are allowed
 * @param event
 * @param job
 */
export const onDragStart = (event, job: Partial<Job>) => {
  event.dataTransfer.setData('application/altasigma', JSON.stringify(job));
  event.dataTransfer.effectAllowed = 'move';
};
