import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';
import { dataManagementRoutes } from '../../routes';

export interface Props {
  dataSourceCode: string;
  bucket: string;
  bucketPath: string;
  loading: boolean;
}

/**
 * Jumps to a directory at the given level
 * @param bucketPath
 * @param dirLevel
 */
function toDirLevel(bucketPath, dirLevel) {
  if (!bucketPath) return '';
  const parts = bucketPath.replace(/^(\/)/, '').split('/');
  const upDir = parts.slice(0, dirLevel).join('/');
  const upDirClean = upDir.replace(/^(\/)/, ''); // Make sure that there is no leading "/"
  return upDirClean;
}

// ---

const BucketHeadline: FC<Props> = ({
  dataSourceCode,
  bucket,
  bucketPath,
  loading,
}) => {
  const dirs = bucketPath ? bucketPath.split('/') : [];
  return (
    <div className={styles.bucketContentHeadlineContainer}>
      {loading ? (
        <Skeleton
          className={styles.bucketPathContainer}
          // Quickly measured in the browser. The actual height for loaded is probably determined by the height of the button
          height={'28px'}
          width={'50%'}
          inline={true}
        />
      ) : (
        <div className={styles.bucketPathContainer}>
          <Link
            className={styles.bpBucket}
            to={`${dataManagementRoutes.basePath}/s3/${dataSourceCode}/bucket/${bucket}/path/`}
            style={{ textDecoration: 'none', zIndex: dirs.length + 1 }}
          >
            {bucket}
          </Link>

          {dirs.map((dir, i) => (
            <Link
              className={styles.bpDir}
              key={i}
              to={`${
                dataManagementRoutes.basePath
              }/s3/${dataSourceCode}/bucket/${bucket}/path/${toDirLevel(
                bucketPath,
                i + 1
              )}/`}
              style={{ textDecoration: 'none', zIndex: dirs.length - i }}
            >
              {dir}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default BucketHeadline;
