import dataManagement from 'common/dist/messages/dataManagement';
import React, { FC, useEffect } from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { event as EVENT_NOTIFICATION } from '../../../../core/notifications';
import {
  clearS3Credentials,
  fetchS3Credentials,
} from '../../../../redux/modules/data.s3.module';
import { sendNotification } from '../../../../redux/modules/notifications.module';
import { S3CredentialsState } from '../../../../store/dataManagement/state.types';
import { ReduxActUnknown3 } from '../../../../store/reduxAct';
import { RootState } from '../../../../store/store';
import Button from '../../../atoms/button/Button';
import TextInputLine from '../../../atoms/input-elements/text-input-line/TextInputLine';
import TextInputLinePassword from '../../../atoms/input-elements/text-input-line-password/TextInputLinePassword';
import ClipboardWrapper from '../../../molecules/clipboard/ClipboardWrapper';
import styles from '../../cassandra/authentication/styles.module.scss';

export type Props = {
  dataSourceCode: string;
};
const Authentication: FC<Props & WrappedComponentProps> = (props) => {
  const credentials = useSelector<RootState, S3CredentialsState>(
    (state) => state.data.s3[props.dataSourceCode]?.credentials
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // "componentWillUnmount"
    return () => {
      if (props.dataSourceCode) {
        dispatch(clearS3Credentials(props.dataSourceCode));
      }
    };
  }, [dispatch, props.dataSourceCode]);

  return (
    <div>
      <div className={styles.inputRow}>
        <div className={styles.inputParent}>
          <TextInputLine
            touched={true}
            error={undefined}
            id={'s3-accessKey'}
            labelId={dataManagement.msgAccessKey.id}
            labelDefault={dataManagement.msgAccessKey.defaultMessage}
            onChange={() => undefined}
            value={credentials?.data?.accessKey}
            validating={false}
            valid={undefined}
            hasLabel={true}
            disabled={true}
            onBlur={() => undefined}
            onFocus={() => undefined}
            placeholderDefault={dataManagement.msgAccessKey.defaultMessage}
          />
        </div>
      </div>

      <div className={styles.inputRow}>
        <div className={styles.inputParent}>
          <TextInputLinePassword
            touched={true}
            error={undefined}
            id={'s3'} // -password is appended to the id internally... wtf?
            label={dataManagement.msgSecretKey}
            onChange={() => undefined}
            value={credentials?.data?.secretKey}
            disabled={true}
            placeholder={dataManagement.msgSecretKey}
          />
        </div>
      </div>

      <div className={styles.bottomRow}>
        {credentials?.error?.formattedMessage && (
          <span className={styles.Error}>
            <FormattedMessage {...credentials.error.formattedMessage} />
          </span>
        )}

        <div>
          {!credentials?.error && credentials?.data && (
            <span>
              {props.intl.formatMessage({
                id: 'datapools.cassandra.auth.validUntil',
              })}
              &nbsp;
              {props.intl.formatDate(credentials?.data?.expiresAt)}&nbsp;
              {props.intl.formatTime(credentials?.data?.expiresAt)}
            </span>
          )}
        </div>

        <div className={styles.buttonLine}>
          <Button
            label={'Request Credentials'}
            color={'primary'}
            onClick={() => dispatch(fetchS3Credentials(props.dataSourceCode))}
            disabled={!props.dataSourceCode || credentials?.loading}
            isBusy={credentials?.loading}
          />

          <ClipboardWrapper
            data={
              credentials?.data?.accessKey + '\n' + credentials?.data?.secretKey
            }
          >
            <Button
              label={'Copy to Clipboard'}
              color={'secondary'}
              onClick={() => {
                dispatch(
                  (sendNotification as ReduxActUnknown3)(
                    'Credentials Copied',
                    'Credentials successfully copied to your Clipboard',
                    EVENT_NOTIFICATION
                  )
                );
              }}
              disabled={!credentials?.data}
            />
          </ClipboardWrapper>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Authentication);
