import { defineMessages } from 'react-intl';

export default defineMessages({
  menuWhatsNew: {
    id: 'header.menu.whatsNew',
    defaultMessage: "What's new",
  },

  searchNoResults: {
    id: 'header.search.no_results',
    defaultMessage: 'No search results.',
  },
});
