import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { fetchQueryFnWorkbench } from './_tools';
import * as CollabApi from './workbench/collab';
import NotebookApi, { CommitFilter } from './workbench/git.notebook';
import {
  Commit,
  FileStatus,
} from '../../components/workbench/part-right/repository/info/types/types';
import { notebookUser } from '../../redux/workbench/selectors/notebookUser.selector';

export const versionControlKeys = {
  all: () => ['versionControl'] as const,
  commits: (
    repositoryPath: string,
    activeBranch: string,
    filter?: CommitFilter
  ) =>
    [
      ...versionControlKeys.all(),
      'commits',
      repositoryPath,
      activeBranch,
      filter ?? CommitFilter.None,
    ] as const,
  fileStatus: (repositoryPath: string) =>
    [...versionControlKeys.all(), 'fileStatus', repositoryPath] as const,
  activeBranch: (repositoryPath: string) =>
    [...versionControlKeys.all(), 'activeBranch', repositoryPath] as const,
  getBranches: (repository: string, group: string) =>
    [...versionControlKeys.all(), 'getBranches', repository, group] as const,
};

export const useCommits = (
  repositoryPath: string,
  activeBranch: string,
  filter?: CommitFilter
): UseQueryResult<Commit[]> => {
  const key = versionControlKeys.commits(repositoryPath, activeBranch, filter);

  const jupyterUser = useSelector((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  return useQuery(key, () =>
    fetchQueryFnWorkbench(key, () =>
      notebookApi.listCommits(repositoryPath, activeBranch, 20, 0, filter)
    )
  );
};
export const useFileStatus = (
  repositoryPath: string
): UseQueryResult<FileStatus> => {
  const key = versionControlKeys.fileStatus(repositoryPath);

  const jupyterUser = useSelector((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  return useQuery(key, () =>
    fetchQueryFnWorkbench(key, () => notebookApi.status(repositoryPath))
  );
};
export const useActiveBranch = (
  repositoryPath: string
): UseQueryResult<{ name: string }> => {
  const key = versionControlKeys.activeBranch(repositoryPath);

  const jupyterUser = useSelector((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  return useQuery(key, () =>
    fetchQueryFnWorkbench(key, () =>
      notebookApi.getCurrentBranch(repositoryPath)
    )
  );
};
export const useGetBranches = (
  remote?: string
): UseQueryResult<Array<{ name: string; commit: object }>> => {
  const url = remote ? new URL(remote) : null;
  const parts = url?.pathname?.substr(1).split('/');
  const group = parts?.[0];
  const repoName = parts?.slice(1).join('/').replace('.git', '');
  const key = versionControlKeys.getBranches(group, repoName);

  return useQuery(
    key,
    () =>
      fetchQueryFnWorkbench(key, () => CollabApi.getBranches(group, repoName)),
    {
      enabled: !!url && !!group && !!repoName,
    }
  );
};
