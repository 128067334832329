import { Table as AntTable, TableColumnsType } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';

import localStyles from './styles.module.scss';
import { useDimensions } from '../../../../../../../utils';
import { FormattedDateTime } from '../../../../../../atoms/formatted-date-time/FormattedDateTime';
import styles from '../../../styles.module.scss';
import { ReportElementProps } from '../../../types/meta';
import { TableConfig, TableReportData } from '../type';
import './antstyle.css';

function isValidDate(dateString: string) {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

function parseDate(dateString: string) {
  const date = new Date(dateString);
  return <FormattedDateTime date={date} dateFirst />;
}

export type Props = TableReportData & TableConfig;

export const Table: FC<Props> = (props) => {
  const [ref, { width }] = useDimensions<HTMLDivElement>(1);
  const {
    data,
    pageRowSize = 100,
    columnLimit = 100,
    hasSorting = false,
    isStriped = true,
  } = props;

  const columns: TableColumnsType = [];
  const columnKeys: string[] = [];
  if (Array.isArray(data)) {
    data.forEach((dataEntry) => {
      const keys = Object.keys(dataEntry);

      keys.forEach((key) => {
        if (columnKeys.length < columnLimit) {
          columnKeys.push(key);
        }
      });
    });
  }

  const uniqueKeys = Array.from(new Set(columnKeys));
  uniqueKeys.forEach((key, index) => {
    const isBooleanColumn = typeof data[0]?.[key] === 'boolean';
    columns.push({
      title: key.toString(),
      dataIndex: key.toString(),
      key: key.toString() + '_' + index,
      render: (value: number | string | boolean) => {
        if (typeof value === 'boolean') {
          return value ? 'True' : 'False';
        }

        if (typeof value === 'number') {
          const hasDecimals = value % 1 !== 0;
          if (hasDecimals) {
            return parseFloat(value.toFixed(2));
          } else {
            return value;
          }
        }
        if (typeof value === 'string') {
          return isValidDate(value) ? parseDate(value) : value;
        }
        return 'NaN';
      },
      sorter:
        !hasSorting || isBooleanColumn
          ? undefined
          : (a, b) => {
              const valA = a[key];
              const valB = b[key];

              if (typeof valA === 'number' && typeof valB === 'number') {
                return valA - valB; // Sort numerically
              } else if (typeof valA === 'string' && typeof valB === 'string') {
                return valA.localeCompare(valB); // Sort alphabetically
              }
              return 0; // Default: no sorting
            },
      filters: !hasSorting
        ? undefined
        : typeof data[0]?.[key] === 'boolean'
        ? [
            { text: 'True', value: true },
            { text: 'False', value: false },
          ]
        : undefined,
      onFilter: (value, record) => record[key] === value,
    });
  });

  return (
    <div ref={ref} className={styles.chart}>
      <AntTable
        style={{ width: width }}
        className={classNames(localStyles.antTable, {
          [localStyles.antTableStriped]: isStriped,
        })}
        rowHoverable={false}
        dataSource={data}
        columns={columns}
        pagination={{
          hideOnSinglePage: true,
          pageSize: pageRowSize,
          pageSizeOptions: [],
          showSizeChanger: false,
          position: ['topLeft'],
          className: classNames(localStyles.pagination),
        }}
      />
    </div>
  );
};

export const TableSingle: FC<
  ReportElementProps<TableReportData, TableConfig>
> = (props) => {
  return <Table data={props.input.reportValue.data} {...props.config} />;
};
