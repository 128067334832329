import {
  REPO_TYPE,
  RepoType,
  repoTypeToSpeakingPlural,
} from 'common/dist/types/repository';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';
import { useWorkbenchProjects } from '../../../core/api/workbench/git.notebook';
import { useRepositoriesByRepoFullName } from '../../../core/api/workbench/repositories';
import { getActiveProjectPath } from '../../../store/workbench/activeProject.slice';
import Busy from '../../atoms/busy/Busy';
import ExpandCollapseIcon from '../../atoms/expand-collapse-icon/ExpandCollapseIcon';
import TextInputSearch from '../../atoms/input-elements/text-input-search/TextInputSearch';
import InputError from '../../atoms/input-error/InputError';
import ProjectCard, {
  Props as ProjectCardProps,
} from '../../molecules/project-card/ProjectCard';
import GenericCollapsibleContainer from '../../organisms/collapsible-container/generic/GenericCollapsibleContainer';

type ProjectInfo = ProjectCardProps & {
  repoType: RepoType;
};

const ProjectsOverview: FC = () => {
  const activeProjectPath = useSelector(getActiveProjectPath);
  const [search, setSearch] = useState('');

  const {
    data: projects = [],
    error,
    isLoading,
    isError,
    isSuccess,
  } = useWorkbenchProjects();
  const { data: repositories, isLoading: isRepositoriesLoading } =
    useRepositoriesByRepoFullName(
      projects.map((project) => project.repoFullName) || [],
      isSuccess
    );
  const enrichedProjects: ProjectInfo[] = (repositories || []).flatMap(
    (repo) => {
      const matchingProjects = (projects || []).filter(
        (project) => repo.repoFullName === project.repoFullName
      );

      return matchingProjects.map((project) => {
        const [, slug] = project.repoFullName.split('/');
        const baseProps: ProjectInfo = {
          path: project.path,
          slug: slug,
          repoType: project.repoType,
          isActive: activeProjectPath === project.path,
          isRepoLoading: isRepositoriesLoading,
        };

        if (!repo) return baseProps;

        let additionalProps = {};

        switch (repo.repoType) {
          case REPO_TYPE.PLAIN:
            additionalProps = { name: repo.name };
            break;
          case REPO_TYPE.CODE_CAPSULE:
            additionalProps = { name: repo.codeCapsule.name };
            break;
          case REPO_TYPE.APP:
            additionalProps = { name: repo.app.name };
            break;
          case REPO_TYPE.MODULE:
            additionalProps = {
              name: repo.module.name,
              avatar: repo.module.moduleAvatar,
            };
            break;
        }

        return {
          ...baseProps,
          ...additionalProps,
          repoFullName: repo.repoFullName,
        };
      });
    }
  );

  const renderNoElements = (text: string) => {
    return <div className={styles.noElements}>{text}</div>;
  };

  const renderProjects = (projectInfos: ProjectInfo[]) => {
    if (projectInfos.length === 0) {
      return renderNoElements('No projects available.');
    }

    const filteredProjects = projectInfos.filter(
      (project) =>
        project.path.toLowerCase().includes(search.toLowerCase()) ||
        project.name?.toLowerCase()?.includes(search.toLowerCase()) ||
        project.slug?.toLowerCase()?.includes(search.toLowerCase())
    );

    if (filteredProjects.length === 0) {
      return renderNoElements('No search results.');
    }

    return filteredProjects.map((project) => (
      <ProjectCard key={project.path} {...project} />
    ));
  };

  const renderContent = () => {
    if (isLoading) {
      return <Busy isBusy={true} />;
    } else if (isError) {
      return <InputError touched={true} error={error} />;
    } else {
      return Object.values(REPO_TYPE).map((type) => {
        return (
          <GenericCollapsibleContainer
            key={type}
            title={repoTypeToSpeakingPlural[type]}
            initialCollapsed={false}
            renderHeader={(title, collapsed) => {
              return (
                <div className={styles.projectCardHeader}>
                  <ExpandCollapseIcon
                    isExpanded={!collapsed}
                    onClick={() => {}}
                  />
                  <h4 className={styles.projectCardTitle}>{title}</h4>
                </div>
              );
            }}
            renderBody={() => {
              return (
                <div className={styles.projectCardGrid}>
                  {renderProjects(
                    enrichedProjects.filter(
                      (project) => project.repoType === type
                    )
                  )}
                </div>
              );
            }}
          />
        );
      });
    }
  };

  return (
    <div className={'CollaborationSpace--content'}>
      <div className={styles.container}>
        <h2>Workbench Projects</h2>
        <div>
          <TextInputSearch
            initialValue={search}
            submitSearchQuery={setSearch}
            submitOnChange={true}
          />
        </div>
      </div>

      {renderContent()}
    </div>
  );
};

export default ProjectsOverview;
