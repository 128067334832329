import { useQuery, UseQueryResult } from '@tanstack/react-query';
import React, { FC, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';

import styles from './styles.module.scss';
import { apiRequest, fetchQueryFn } from '../../../../core/api/_tools';
import { useAugur } from '../../../../core/api/augurs';
import BackTo from '../../../atoms/back-to/BackTo';
import Button from '../../../atoms/button/Button';
import ThemedSwitch from '../../../atoms/themed-switch/ThemedSwitch';
import AudioRecorder from '../../components/audioRecorder/AudioRecorder';
import ChatMessages, {
  ChatMessage,
} from '../../components/chatMessages/ChatMessages';
import Placeholder from '../../components/placeholder/Placeholder';
import { assistantsRoutes } from '../../routes';
import { useAugurCode } from '../utils';

type Props = {};

const useS2tModelName = (
  augurCode: string
): UseQueryResult<{ models: string[] }> => {
  const key = [`s2t_${augurCode}`];
  return useQuery(key, () =>
    fetchQueryFn(key, () =>
      apiRequest(`/orchestration/realtime/augurs/${augurCode}/serving/api/ps`)
    )
  );
};

const Speech2Text: FC<Props> = () => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [translate, setTranslate] = useState<boolean>(false);

  const augurCode = useAugurCode();

  const { data: augur } = useAugur(augurCode);

  const qrModelName = useS2tModelName(augurCode);
  const speech2TextModel = qrModelName?.data?.models?.[0];

  const addMessage = (message: ChatMessage, replaceAtId?: string | number) => {
    if (replaceAtId) {
      const index = messages.findIndex((msg) => msg.id === replaceAtId);
      if (index !== -1) {
        // Replace the message at the found index
        const newMessages = [...messages];
        newMessages[index] = message;
        setMessages(newMessages);
      } else {
        // If no message found with the given id, add to the end
        setMessages([...messages, message]);
      }
    } else {
      // If no replaceAtId provided, simply add to the end
      setMessages([...messages, message]);
    }
  };

  const sendAudioForTranscription = async (audioBlob: Blob) => {
    const formData = new FormData();
    formData.append('file', audioBlob);
    formData.append('model', speech2TextModel);
    formData.append('response_format', 'json');
    const msgTitle = translate
      ? 'Speech2Text Translation'
      : 'Speech2Text Transcription';

    const messageId = uuidv4();
    try {
      addMessage({
        id: messageId,
        role: 'assistant',
        title: msgTitle,
        isLoading: true,
      });

      const response = translate
        ? await apiRequest(
            `/orchestration/realtime/augurs/${augurCode}/serving/v1/audio/translations`,
            {
              method: 'POST',
              body: formData,
              headers: {
                accept: 'application/json',
              },
            }
          )
        : await apiRequest(
            `/orchestration/realtime/augurs/${augurCode}/serving/v1/audio/transcriptions`,
            {
              method: 'POST',
              body: formData,
              headers: {
                accept: 'application/json',
              },
            }
          );

      if (response.error) {
        throw new Error(`HTTP error: ${JSON.stringify(response?.error)}`);
      }

      const responseBody = await response.response;
      addMessage(
        {
          role: 'assistant',
          title: msgTitle,
          content: responseBody?.['text'] || '',
        },
        messageId
      );
    } catch (error) {
      if (error instanceof Error) {
        addMessage(
          {
            role: 'assistant',
            title: 'Error',
            content: 'Error while fetching: ' + error.message,
            isError: true,
          },
          messageId
        );
      } else {
        addMessage(
          {
            role: 'assistant',
            title: 'Error',
            content: 'An unexpected error occurred: ' + error,
            isError: true,
          },
          messageId
        );
      }
    }
  };

  return (
    <div className={styles.speech2Text}>
      <div className={styles.backButtonParent}>
        <BackTo
          label={'Back to all Assistants'}
          linkTo={assistantsRoutes.basePath}
        />
      </div>

      <div className={styles.headlineBar}>
        <span className={styles.headline}>{augur?.name}</span>
        <div className={styles.buttonLine}>
          <Button
            label={{ id: 'no-id', defaultMessage: 'New Chat' }}
            color={'green'}
            Icon={() => <FiPlus size={16} />}
            onClick={() => {
              setMessages([]);
            }}
          />
        </div>
      </div>

      <div className={styles.recorderContainer}>
        <div className={styles.outputContainer}>
          {!(messages?.length > 0) ? (
            <Placeholder
              title={{
                id: 'no-id',
                defaultMessage: 'How can I help you today?',
              }}
            />
          ) : (
            <ChatMessages messages={messages} />
          )}
        </div>

        <div className={styles.toggleContainer}>
          <span className={styles.toggleLabel}>Translate to English</span>
          <ThemedSwitch
            onChange={() => setTranslate(!translate)}
            checked={translate}
            height={20}
            width={40}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
            activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
            themeColor={'primary'}
          />
        </div>
        <div className={styles.inputContainer}>
          <AudioRecorder
            onStopRecording={sendAudioForTranscription}
            onError={(errorMessage: string) => {
              addMessage({
                id: uuidv4(),
                role: 'assistant',
                title: 'Error',
                content: errorMessage,
                isError: true,
              });
            }}
          />
        </div>
        <div className={styles.infoContainer}>
          <span className={styles.modelName}>{speech2TextModel}</span>
        </div>
      </div>
    </div>
  );
};

export default Speech2Text;
