import { Point } from 'common/dist/types/module.charts';
import { scaleLinear } from 'd3-scale';
import { DefaultLabelFormatterCallbackParams } from 'echarts';
import React, { FC } from 'react';

import { useDimensions, useThemeColor } from '../../../../../../../utils';
import EChartWrapper, {
  ReactEChartsProps,
} from '../../../../../e-chart-wrapper/EChartWrapper';
import { SharedInputProps } from '../../../../common/utils';
import { showLegendSize } from '../../../common/sizes';
import commonStyles from '../../../styles.module.scss';
import { MultiReportElementProps } from '../../../types/meta';
import { BarChartConfig, BarChartReportData } from '../type';

export interface PredictedValuesChartData {
  data: (Point[] | undefined)[];
  jobCodes: string[];
}

export type Props = PredictedValuesChartData &
  BarChartConfig &
  SharedInputProps;

type StackedBarData = [number | string, number | undefined][][];

export const PredictedValuesChart: FC<Props> = ({ data, jobCodes, xLabel }) => {
  const [ref, { width, height }] = useDimensions<HTMLDivElement>(1);

  const middleColor = useThemeColor('primary-highlight');
  const lightColor = useThemeColor('primary-highlight', '-lighter75');
  const darkColor = useThemeColor('primary-highlight', '-darker75');

  const smallSize = showLegendSize;

  const sortedData: number[] = [];

  // Used for padding
  // Padding is needed in case of undefined values.
  // If one data row has more elements than another to other needs padding to fill the gaps,
  // because this is a stacked bar chart used as a predicted values chart and each stack needs to have exacly the same amount of datapoints.
  let maxRowLength = 0;

  const xDomainValues: Set<number> = new Set();

  data.forEach((barData) => {
    if (barData) {
      // Get longest row
      if (barData.length > maxRowLength) {
        maxRowLength = barData.length;
      }
      barData.forEach((point) => {
        xDomainValues.add(point[0]);
        sortedData.push(point[1]);
      });
    }
  });

  const xDomain: (number | string)[] = Array.from(xDomainValues).sort(
    (a, b) => a - b
  );

  // Case all data is undefined
  // Force chart to have 1 column
  if (xDomain.length === 0) {
    xDomain.push('');
  }

  const smallestValue = Math.min(...sortedData);
  const biggestValue = Math.max(...sortedData);

  // Calculating Boxplot values to handle outliers
  // Else the color scale would fail to provide colors equally
  sortedData.sort((a, b) => {
    return a - b;
  });

  function getPercentile(percentile: number) {
    return Math.floor(sortedData.length * percentile) ===
      sortedData.length * percentile
      ? sortedData[sortedData.length * percentile]
      : sortedData[Math.floor(sortedData.length * percentile) + 1];
  }

  const median = getPercentile(0.5);
  const percentile025 = getPercentile(0.25);
  const percentile075 = getPercentile(0.75);
  const interquartileRange = percentile075 - percentile025;
  const whiskerRange = 1;
  const upperWhisker = percentile075 + interquartileRange * whiskerRange;
  const lowerWhisker = percentile025 - interquartileRange * whiskerRange;

  const colorScale = scaleLinear(
    [
      biggestValue,
      upperWhisker >= biggestValue ? biggestValue : upperWhisker,
      median,
      lowerWhisker <= smallestValue ? smallestValue : lowerWhisker,
      smallestValue,
    ],
    [darkColor, darkColor, middleColor, lightColor, lightColor]
  );

  // One element in array equals one row
  const series = jobCodes.map((name, sid) => {
    const dataPadded: StackedBarData = [];
    data.forEach((dataEntry, index) => {
      // Create padding - one point with undefined value for each possible point of each row
      if (Array.from(xDomainValues).length !== 0) {
        dataPadded.push(xDomain.map((xVal) => [xVal, undefined]));
      } else {
        dataPadded.push([]);
      }
      // Check if value exists and add updates the points of the row with the value.
      dataEntry?.forEach((dataEntryItem) => {
        const paddedDataRow = dataPadded[index];
        for (let i = 0; i < maxRowLength; i++) {
          if (paddedDataRow[i][0] === dataEntryItem[0]) {
            dataPadded[index][i][1] = dataEntryItem[1];
            break;
          }
        }
      });
    });

    return {
      animation: true,
      label: {
        show: true,
        formatter: (
          params: DefaultLabelFormatterCallbackParams & {
            data: { noData: boolean };
          }
        ) => {
          if (
            // Only one NoData per row should be shown if the bar data is undefined
            Math.floor(maxRowLength / 2) === params.dataIndex &&
            params?.data?.noData
          ) {
            return 'NoData';
          }
          return '';
        },
      },
      itemStyle: {
        borderRadius: 0,
        borderWidth: 1,

        borderColor: 'transparent',
        color: (
          params: DefaultLabelFormatterCallbackParams & {
            data: { trueValue: undefined | [number, number] };
          }
        ) => {
          const value = params?.data?.trueValue?.[1];
          if (undefined === value) return 'transparent';
          return colorScale(value);
        },
      },
      tooltip: {
        appendToBody: true,
        formatter: (
          params: DefaultLabelFormatterCallbackParams & {
            data: { trueValue: undefined | [number, number] };
          }
        ) => {
          const val = params?.data?.trueValue?.[1];
          const xAxis = xLabel ?? 'x-Axis';
          const xAxisVal = params?.data?.trueValue?.[0];
          if (val) {
            const marker = params?.marker;
            return `
            <strong> ${params.seriesName} </strong> 
            <br/> ${xAxis}: <strong> ${xAxisVal} </strong>
            <br/>${marker?.toString()} <strong> ${val} </strong>`;
          } else {
            return '';
          }
        },
      },
      animationEasing: 'linear',
      animationDuration: 0,
      cursor: 'default',
      name: name,
      type: 'bar',
      stack: 'total',
      barWidth: '96%',

      data: (() => {
        const dataRow: {
          value: number;
          trueValue: [number | string, number | undefined] | undefined;
          noData: boolean;
        }[] = [];

        // To remodel the stacked bar chart to become a predicted values chart the value is always 100 to have equal height for each stack.
        // The true value of each point is added as an extra parameter.
        if (maxRowLength === 0) {
          dataRow.push({
            value: 100,
            trueValue: undefined,
            noData: true,
          });
        }
        for (let i = 0; i < maxRowLength; i++) {
          const val = dataPadded[sid]?.[i];
          // noData parameter marks the whole row as undefined to render the NoData label.
          dataRow.push({ value: 100, trueValue: val, noData: !data[sid] });
        }
        return dataRow;
      })(),
    };
  });

  const option: ReactEChartsProps['option'] = {
    tooltip: {
      appendToBody: true,
      show: true,
      trigger: 'item',
    },
    grid: {
      containLabel: true,
      left: 0,
      top: 10,
      right: 12,
      bottom: 12,
    },

    legend: {
      selectedMode: false,
      show: false,
    },
    yAxis: {
      axisTick: {
        show: true,
      },
      axisLine: {
        show: true,
      },
      position: 'left',
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.7,
        },
      },
      offset: 0,
      name: '',
      nameLocation: 'middle',

      show: true,
      interval: 100,
      min: 0,
      max: jobCodes.length * 100,
      // With limited space the y-axis labels are cut.
      axisLabel: {
        formatter: (_: unknown, index: number): string => {
          const label = jobCodes[index];
          if (!label || width < smallSize) return '';
          if (height / jobCodes.length < 30 && label.length > 6)
            return label.substring(0, 6) + '...';
          if (height / jobCodes.length < 50 && label.length > 15)
            return (
              label.substring(0, 9) + '\n' + label.substring(8, 15) + '...'
            );
          return label.match(new RegExp(`.{1,9}`, 'g'))?.join('\n') || '';
        },
        verticalAlign: 'bottom',
      },
    },
    xAxis: {
      name: xLabel,
      nameLocation: 'middle',
      nameGap: 22,
      axisLine: {
        show: true,
      },
      axisTick: {
        show: true,
      },
      type: 'category',
      data: xDomain,
    },
    // I added additional parameters that are not in the
    // ReactEChartsProps['option'] type.
    // ts-ignore is here because the type to very complex.
    // @ts-ignore
    series,
  };

  return (
    <div ref={ref} className={commonStyles.chart}>
      <EChartWrapper option={option} style={{ width: width, height: height }} />
    </div>
  );
};

export const PredictedValuesChartMulti: FC<
  MultiReportElementProps<BarChartReportData, BarChartConfig>
> = ({ input, config, ...rest }) => {
  const data = input.map((x) => x.reportValue?.data);
  const jobCodes = input.map((x) => x.jobCode);
  return (
    <PredictedValuesChart
      data={data}
      jobCodes={jobCodes}
      {...config}
      {...rest}
    />
  );
};
