import classNames from 'classnames';
import repositoryMsgs from 'common/dist/messages/repository';
import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiX } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import ReactModal from 'react-modal';

import infoStyles from './../../info/styles.module.scss';
import Button from '../../../../../atoms/button/Button';
import styles from '../../../../../collaborationSpace/repository-details/merge-requests/merge-request-details/tab-resolve/styles.module.scss';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

interface GitCommitModalProps {
  isOpen: boolean;
  onClose: () => void;
  repositoryPath: string;
  activeBranch: string;
  onCommit: (
    repositoryPath: string,
    commitMessage: string,
    activeBranch: string
  ) => void;
}

type CommitFormData = {
  commitMessage: string;
};

const GitCommitModal: FC<GitCommitModalProps> = ({
  isOpen,
  onClose,
  repositoryPath,
  activeBranch,
  onCommit,
}) => {
  const [buttonClicked, setButtonClicked] = useState(false);
  const intl = useIntl();

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<CommitFormData>({
    defaultValues: {
      commitMessage: '',
    },
    mode: 'onChange',
  });

  const onSubmit = (data: CommitFormData) => {
    if (!buttonClicked) {
      setButtonClicked(true);
      onCommit(repositoryPath, data.commitMessage, activeBranch);
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel={intl.formatMessage(repositoryMsgs.msgGitCommitModal)}
      onRequestClose={onClose}
      style={customStyles}
      appElement={document.getElementById('react-app')}
      parentSelector={() => document.querySelector('#MainContainer')}
    >
      <div className={infoStyles.modalCommitParent}>
        <div
          title={intl.formatMessage(repositoryMsgs.msgGitCommitModalClose)}
          className={classNames('workbench-button', 'close-button')}
          onClick={onClose}
        >
          <FiX className={'icon'} size={'20px'} />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={infoStyles.modalCommitElements}>
            <Controller
              name='commitMessage'
              control={control}
              rules={{
                required: 'Please enter a commit message',
                validate: (value) =>
                  value.trim() !== '' || 'Commit message cannot be empty',
              }}
              render={({ field, fieldState: { error, isTouched } }) => (
                <div className={styles.commitMessageParent}>
                  <div className={styles.fieldHeadline}>
                    <span className={styles.commitMessageLabel}>
                      {intl.formatMessage(repositoryMsgs.msgGitCommitMessage)}
                    </span>
                    {isTouched && error && (
                      <div className={styles.errorLine}>
                        <span className={'error'}>{error.message}</span>
                      </div>
                    )}
                  </div>
                  <input
                    type={'text'}
                    id='commitMessage'
                    className={classNames(styles.inputCommitMessage, {
                      [styles.invalid]: isTouched && error,
                    })}
                    {...field}
                  />
                </div>
              )}
            />
          </div>

          <div className={infoStyles.modalCommitButtons}>
            <Button
              color={'primary'}
              label={'Add & Commit'}
              disabled={!isDirty || !isValid || buttonClicked}
              type='submit'
            />
          </div>
        </form>
      </div>
    </ReactModal>
  );
};

export default GitCommitModal;
