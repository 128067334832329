import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import { JupyterNotebookFormat } from 'common/dist/utils/workbench/jupyterNotebookFormat';
import qs from 'qs';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux-act';

import {
  fetchNotebook,
  selectNotebook,
  showWarningOpenFile,
} from '../../redux/workbench/modules/notebook.module';
import { getFetchedNotebooks } from '../../redux/workbench/selectors/notebook.selectors';
import { DeprecatedRootState } from '../../store/state.type';
import { workbenchRoutes } from '../../workbench/common/workbenchRoutes';
import { PaneContent } from '../../workbench/types';
import { ContentElement } from '../organisms/workbench-browser/generic-file-browser/GenericFileBrowser';

// 8 MB
export const WARNING_SIZE = 8 * 1024 ** 2;

export const openFileFromBin = (
  state: DeprecatedRootState,
  dispatch: Dispatch,
  history: RouteComponentProps['history'],
  selectedPath: string[],
  element: Omit<ContentElement, 'content'>
) => {
  return openFile(state, dispatch, history, selectedPath, element, true);
};

export function openFile(
  state: DeprecatedRootState,
  dispatch: Dispatch,
  history: RouteComponentProps['history'],
  selectedPath: string[],
  element: Omit<ContentElement, 'content'>,
  fromRecycleBin = false
) {
  if (element.size > WARNING_SIZE) {
    // @ts-ignore: Type actions/reducers first
    dispatch(showWarningOpenFile(element.path, element.type));
    const targetRoute = fromRecycleBin
      ? workbenchRoutes.recycleBin
      : workbenchRoutes.fileBrowser;
    history.push(
      `${workbenchRoutes.basePath}${targetRoute}${workbenchRoutes.warningOpenFile}${history.location.search}`
    );
  } else {
    openFileNoCheck(state, dispatch, history, element.path, fromRecycleBin);
  }
}

export function openFileNoCheck(
  state: DeprecatedRootState,
  dispatch: Dispatch,
  history: RouteComponentProps['history'],
  elementPath: string,
  fromRecycleBin = false
) {
  const fetchedNotebooks = getFetchedNotebooks(state);
  const mostRecentPaneId = state.workbench.mostRecentPaneId;

  const targetRoute = fromRecycleBin
    ? workbenchRoutes.recycleBin
    : workbenchRoutes.fileBrowser;

  history.push(
    `${workbenchRoutes.basePath}${targetRoute}${history.location.search}`
  );

  if (fetchedNotebooks.includes(elementPath)) {
    dispatch(selectNotebook(elementPath));
  } else {
    // @ts-ignore: Type actions/reducers first
    dispatch(fetchNotebook(elementPath, mostRecentPaneId));
  }
}

/**
 * Strip non-standard fields from a notebook file / json / object, which should not be persisted, because
 * they are only relevant while the file is opened / in redux.
 *
 * executing: This is a field inside a cell, which we set to show that a cell is currently executing.
 *
 * @param notebook Notebook with nbformat: 4 nbformat_minor: 2 and additional fields
 *                 for example: an "executing" field in the CodeCell type
 */
export function stripInMemoryContent(notebook): JupyterNotebookFormat {
  return {
    ...notebook,
    cells: notebook.cells.map(({ executing, ...rest }) => rest),
  };
}

export const getSelectedDirPathQuery = (path: string[]): string =>
  qs.stringify({ path: contentArrayToPath(path) }, { addQueryPrefix: true });

export function getUniqueTabNames(contents: PaneContent[]): PaneContent[] {
  const nameMap = new Map<string, PaneContent[]>();

  // Group files by name
  contents.forEach((content) => {
    if (!nameMap.has(content.name)) {
      nameMap.set(content.name, []);
    }
    nameMap.get(content.name).push(content);
  });

  // For any group of files with the same name
  nameMap.forEach((fileGroup) => {
    if (fileGroup.length > 1) {
      fileGroup.sort((a, b) => a.path.length - b.path.length);

      // Iterate over the files and find the shortest unique part of the path
      fileGroup.forEach((file) => {
        const parts = file.path.split('/');

        // Initialize with filename (last part of path)
        let uniquePath = parts.pop();

        for (let i = parts.length - 1; i >= 0; i--) {
          uniquePath = `${parts[i]}/${uniquePath}`;
          const isUnique = fileGroup.every(
            (otherFile) =>
              otherFile === file || !otherFile.path.endsWith(uniquePath)
          );
          if (isUnique) break;
        }

        // Set updated tab name
        file.tabName = uniquePath;
      });
    } else {
      fileGroup[0].tabName = fileGroup[0].name;
    }
  });

  return contents;
}
