import type { EChartsOption } from 'echarts';
import React, { FC } from 'react';

import {
  getStringWidth,
  useDimensions,
  usePrimaryColorScale,
  useThemeColor,
} from '../../../../../../../utils';
import EChartWrapper, {
  ReactEChartsProps,
} from '../../../../../e-chart-wrapper/EChartWrapper';
import { showLegendSize } from '../../../common/sizes';
import styles from '../../../styles.module.scss';
import { MultiReportElementProps } from '../../../types/meta';
import { PieChartConfig, PieChartData } from '../type';

export type StackedBarChartData = {
  data: (PieChartData['data'] | undefined)[];
  labels: string[];
};

export type Props = StackedBarChartData & PieChartConfig;

export const StackedBarChart: FC<Props> = ({ data, labels, legend }) => {
  const [ref, { width, height }] = useDimensions<HTMLDivElement>(1);
  const primaryHighlight = useThemeColor('primary-highlight');
  const primary = useThemeColor('primary');
  const secondary = useThemeColor('secondary');

  // To find the values for the tooltip
  const labelPieDataMap: {
    [label: string]: PieChartData['data'] | undefined;
  } = {};
  for (let i = 0; labels.length > i; i++) {
    labelPieDataMap[labels[i]] = data[i];
  }

  // For bar width and x-label linebreak to prevent text overlay
  const barWidthPercent = 80;
  const barWidth = (width / data.length) * (barWidthPercent / 100);

  // Data transformation for echart

  // For custom colors
  const legendMap: { [label: string]: string } = {};
  legend?.forEach((legendEntry) => {
    let color: string;
    switch (legendEntry.color) {
      case 'primary':
        color = primary;
        break;
      case 'secondary':
        color = secondary;
        break;
      case 'primaryHighlight':
        color = primaryHighlight;
        break;
      default:
        color = legendEntry.color;
    }

    legendMap[legendEntry.label] = color;
  });

  const keys: Set<string> = new Set<string>();
  data.forEach((data) => {
    if (data) {
      const innerKeys = Object.keys(data);
      innerKeys.forEach((innerKey) => {
        keys.add(innerKey);
      });
    }
  });

  const keyList: (string | undefined)[] = [];
  keys.forEach((key) => {
    keyList.push(key);
  });

  const colorScale = usePrimaryColorScale(keyList.length);

  const isSmall = height < showLegendSize && width < showLegendSize;

  const rawData: (number | undefined)[][] = [];
  keyList.forEach(() => rawData.push([]));
  data.forEach((pie) => {
    keyList.forEach((key, index) => {
      rawData[index].push(pie ? pie[key!] : undefined);
    });
  });

  // For the case no data is provided at all
  const totalData: (number | undefined)[] = [];
  if (rawData.length === 0) {
    rawData.push([]);
    data.forEach(() => {
      rawData[0].push(undefined);
      keyList.push(undefined);
    });
  }

  // totalData is used to calculate relative stack height
  // -1 is used to identify undefined data
  for (let i = 0; i < rawData[0].length; ++i) {
    let sum = -1;
    for (let j = 0; j < rawData.length; ++j) {
      if (rawData[j][i]) {
        if (sum === -1) sum = 0;
        sum += rawData[j][i]!;
      }
    }
    totalData.push(sum);
  }

  const additionalTopPadding =
    keyList.length > 8 ? 30 * Math.floor(keyList.length / 8) : 0;

  const grid = {
    containLabel: true,
    left: 0,
    right: 0,
    top: isSmall ? 10 : 60 + additionalTopPadding,
    bottom: 12,
  };

  const series: EChartsOption['series'] = keyList.map((name, sid) => {
    let colorsInner = undefined;

    if (name) {
      if (legend && legendMap[name]) {
        colorsInner = legendMap[name];
      } else {
        colorsInner = colorScale(sid);
      }
    }

    return {
      animation: true,
      animationEasing: 'linear',
      animationDuration: 0,
      cursor: 'default',
      name: name,
      type: 'bar',
      stack: 'total',
      barWidth: `${barWidthPercent}%`,
      color: colorsInner,
      label: {
        show: true,
        rotate: barWidth < 50 ? 90 : 0,
        color: 'grey',
        formatter: (param) => {
          if (
            totalData[Number(param.dataIndex)] === -1 &&
            param.seriesIndex === 0
          )
            return 'No Data\n';
          return '';
        },
      },
      data: !rawData[sid]
        ? []
        : rawData[sid].map((d, did) => {
            if (totalData[did] === undefined || d === undefined) return 0;
            return totalData[did] <= 0 ? 0 : d / totalData[did];
          }),
    };
  });

  const option: ReactEChartsProps['option'] = {
    animation: true,

    legend: {
      selectedMode: true,
      show: !isSmall,
      width: width,
      top: '0px',
      left: 'center',
    },
    grid,
    yAxis: {
      show: true,
      type: 'value',
      axisLabel: {
        show: true,
      },
      axisLine: {
        show: true,
      },
      axisTick: {
        show: true,
      },
    },

    xAxis: {
      axisTick: { length: 1, show: true },

      axisLabel: {
        width: 50,

        formatter(value) {
          const labelSplit: string[] = [];
          let currentSplit = '';
          for (const char of value) {
            if (getStringWidth(currentSplit) >= barWidth - 20) {
              labelSplit.push(currentSplit);
              currentSplit = '';
            }

            currentSplit += char;
          }
          labelSplit.push(currentSplit);

          let isCut = false;
          while (labelSplit.length > 3) {
            labelSplit.pop();
            isCut = true;
          }
          if (isCut) {
            labelSplit.pop();
            labelSplit.push('...');
          }

          return labelSplit.join('\n');
        },
      },
      type: 'category',
      boundaryGap: true,
      data: labels,
      triggerEvent: true,
      tooltip: {
        show: true,
      },
    },
    tooltip: {
      appendToBody: true,
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params) => {
        if (!Array.isArray(params)) return '';

        // Creates the tooltips and saves them in a map.
        const tooltips: { [label: string]: string } = {};
        params.forEach((param) => {
          let tooltipText = '';
          let data: { [label: string]: number } | undefined = undefined;
          let axisValue = '';
          if ('axisValue' in param) {
            axisValue = param.axisValue as string;
          }
          data = labelPieDataMap[axisValue];
          if (data) {
            const keys = params.map((param) => param.seriesName);
            keys.forEach((key, index) => {
              if (key && params[index].color) {
                tooltipText += `
                <div>
               ${params[index].marker?.toString()}
                ${key}: <strong>${data[key]}</strong>
                </div>`;
              }
            });
            tooltips[axisValue] = tooltipText;
          }
        });
        // If tooltip exists for the key its added to the tooltip
        let values;
        if ('axisValue' in params[0]) {
          const firstAxisValue = params[0].axisValue as string;
          values = tooltips[firstAxisValue];
        }
        return (
          `<strong> ${params[0].name} </strong>` + '\n' + (values ? values : '')
        );
      },
    },
    series,
  };

  return (
    <div ref={ref} className={styles.container}>
      <EChartWrapper option={option} style={{ width: width, height: height }} />
    </div>
  );
};

export const StackedBarChartMulti: FC<
  MultiReportElementProps<PieChartData, PieChartConfig>
> = ({ input, config, ...rest }) => {
  const data = input.map((x) => x.reportValue?.data);
  const jobCodes = input.map((report) => report.jobCode || 'JobCode missing');
  return (
    <StackedBarChart data={data} labels={jobCodes} {...config} {...rest} />
  );
};
