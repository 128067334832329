const directoryRegex = /^[a-zA-Z0-9\ \[\]\(\)\!\?äöüßÄÖÜ\-_]+$/;

export function validateDirectoryName(
  value: string,
  filesInCurrentDir: { name: string }[],
  isEditing: boolean,
  initialName: string
): string | undefined {
  // No empty names
  if (!value) {
    return 'Please enter a name';
  }
  // Specify the allowed characters
  if (!directoryRegex.test(value)) {
    return 'Please remove invalid characters';
  }

  //No duplicated names
  if (filesInCurrentDir) {
    const sameName = filesInCurrentDir.find((c) => c.name === value);
    // In the case where we are editing and the name is the same as the initalName it is allowed to keep it
    if (sameName && !(isEditing && value === initialName)) {
      return 'A file / directory with the given name already exists';
    }
  }

  // If all validations pass, return undefined
  return undefined;
}
