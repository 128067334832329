import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { User } from 'common/dist/types/users';
import React, { FC, useCallback, useRef, useState } from 'react';
import { FiPlus, FiSend } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import styles from './styles.module.scss';
import keycloak from '../../../../../keycloak';
import { apiRequest, fetchQueryFn } from '../../../../core/api/_tools';
import { useAugur } from '../../../../core/api/augurs';
import { currentUser } from '../../../../redux/selectors/user.selector';
import { RootState } from '../../../../store/store';
import BackTo from '../../../atoms/back-to/BackTo';
import Button from '../../../atoms/button/Button';
import ChatMessages, {
  ChatMessage,
  SourceDocument,
  UserMessage,
} from '../../components/chatMessages/ChatMessages';
import Placeholder from '../../components/placeholder/Placeholder';
import { assistantsRoutes } from '../../routes';
import { useAugurCode } from '../utils';

type Props = {};

const STREAM = false; // The custom module doesn't support streaming the answers yet

const useLlmModelNames = (
  augurCode: string
): UseQueryResult<{ embedding?: string; generative: string }> => {
  const key = [`llm_${augurCode}`];
  return useQuery(key, () =>
    fetchQueryFn(key, () =>
      apiRequest(`/orchestration/realtime/augurs/${augurCode}/serving/models`)
    )
  );
};

const GraphSearch: FC<Props> = () => {
  const messagesRef = useRef<ChatMessage[]>([]);
  const [, setMessagesUpdate] = useState<number>(0);
  // const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [chatInput, setChatInput] = useState<string>(null);

  const user = useSelector<RootState, User>((state) => currentUser(state));
  const augurCode = useAugurCode();

  const { data: augur } = useAugur(augurCode);

  const qrModelNames = useLlmModelNames(augurCode);
  const generativeModelName = qrModelNames?.data?.generative;

  const addMessage = useCallback(
    (message: ChatMessage, replaceAtId?: string | number) => {
      if (replaceAtId) {
        const index = messagesRef.current.findIndex(
          (msg) => msg.id === replaceAtId
        );
        if (index !== -1) {
          // Replace the message at the found index
          messagesRef.current[index] = message;
        } else {
          // If no message found with the given id, add to the end
          messagesRef.current.push(message);
        }
      } else {
        // If no replaceAtId provided, simply add to the end
        messagesRef.current.push(message);
      }
      // Trigger a re-render
      setMessagesUpdate((prev) => prev + 1);
    },
    []
  );

  const sendMessagesToLlm = async () => {
    const messageId = uuidv4();
    const messageTitle = 'Large Language Model';
    try {
      addMessage({
        id: messageId,
        role: 'assistant',
        title: messageTitle,
        isLoading: true,
      });

      const requestMessages = messagesRef.current
        .filter((msg) => !msg.isError && !msg.isLoading)
        .map((msg) => ({
          role: msg.role,
          content: msg.content,
        }));

      const requestBody = {
        messages: requestMessages,
        stream: STREAM,
      };

      if (STREAM) {
        const response = await fetch(
          `/orchestration/realtime/augurs/${augurCode}/serving/chat/completions`,
          {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${keycloak.token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let accumulatedContent = '';

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value);
          const lines = chunk.split('\n').filter((line) => line.trim() !== '');

          for (const line of lines) {
            const parsedChunk = JSON.parse(line);
            if (!parsedChunk.finish_reason) {
              accumulatedContent += parsedChunk.message.content || '';
              // Update the message with accumulated content
              addMessage(
                {
                  id: messageId,
                  role: 'assistant',
                  title: messageTitle,
                  content: accumulatedContent,
                },
                messageId
              );
            } else {
              addMessage(
                {
                  id: messageId,
                  role: 'assistant',
                  title: messageTitle,
                  content: accumulatedContent,
                  source_documents:
                    parsedChunk.source_documents as SourceDocument[],
                },
                messageId
              );
              return;
            }
          }
        }
      } else {
        // Non-streaming
        const response = await apiRequest(
          `/orchestration/realtime/augurs/${augurCode}/serving/chat/completions`,
          {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
              'Content-Type': 'application/json',
              accept: 'application/json',
            },
          }
        );

        if (response.error) {
          throw new Error(`HTTP error: ${JSON.stringify(response?.error)}`);
        }

        const responseBody = await response.response;
        addMessage(
          {
            role: 'assistant',
            title: messageTitle,
            content: responseBody?.['message']?.['content'],
            source_documents: responseBody?.['source_documents'],
          },
          messageId
        );
      }
    } catch (error) {
      if (error instanceof Error) {
        addMessage(
          {
            role: 'assistant',
            title: 'Error',
            content: 'Error while fetching: ' + error.message,
            isError: true,
          },
          messageId
        );
      } else {
        addMessage(
          {
            role: 'assistant',
            title: 'Error',
            content: 'An unexpected error occurred: ' + error,
            isError: true,
          },
          messageId
        );
      }
    }
  };

  const submitTextMessage = async (e: React.FormEvent) => {
    e.preventDefault();

    // 1. Add the message to the user message list
    const userMessage: UserMessage = {
      id: uuidv4(),
      role: 'user',
      content: chatInput,
      title: `${user?.firstName} ${user?.lastName}`,
    };
    addMessage(userMessage);

    // 2. Remove the text from the text input
    setChatInput('');

    // 3. Send the messages to the LLM
    await sendMessagesToLlm();
  };

  return (
    <div className={styles.graphSearch}>
      <div className={styles.backButtonParent}>
        <BackTo
          label={'Back to all Assistants'}
          linkTo={assistantsRoutes.basePath}
        />
      </div>

      <div className={styles.headlineBar}>
        <span className={styles.headline}>{augur?.name}</span>
        <div className={styles.buttonLine}>
          <Button
            label={{ id: 'no-id', defaultMessage: 'New Chat' }}
            color={'green'}
            Icon={() => <FiPlus size={16} />}
            onClick={() => {
              messagesRef.current = [];
              setMessagesUpdate((prev) => prev + 1);
            }}
          />
        </div>
      </div>

      <div className={styles.chatContainer}>
        <div className={styles.outputContainer}>
          {!(messagesRef?.current?.length > 0) ? (
            <Placeholder
              title={{
                id: 'no-id',
                defaultMessage: 'How can I help you today?',
              }}
            />
          ) : (
            <ChatMessages messages={messagesRef.current} userId={user?.id} />
          )}
        </div>

        <form className={styles.inputContainer} onSubmit={submitTextMessage}>
          <div className={styles.textInputContainer}>
            <input
              className={styles.textInput}
              type={'text'}
              value={chatInput}
              onChange={(e) => setChatInput(e.target.value)}
              placeholder={'Send a message to the Large Language Model'}
            />
          </div>
          <button
            type={'submit'}
            className={styles.submitButton}
            disabled={!chatInput}
          >
            <FiSend size={18} />
          </button>
        </form>

        <div className={styles.infoContainer}>
          {generativeModelName && (
            <span className={styles.modelName}>
              Generative Model: {generativeModelName}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default GraphSearch;
