import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import NameContainer from './NameContainer';
import styles from './styles.module.scss';
import { useWorkbenchProjects } from '../../../../core/api/workbench/git.notebook';
import { getActiveProjectPath } from '../../../../store/workbench/activeProject.slice';
import { ProjectInfo } from '../../../../store/workbench/state.types';

const OverlayDevAugurDetails: FC = () => {
  const activeProjectPath: string = useSelector(getActiveProjectPath);

  const { data: projects } = useWorkbenchProjects();
  const repoMeta = Object.values(projects || [])
    .flat<ProjectInfo[]>()
    .find((project) => project.path === activeProjectPath);

  return (
    <div className={styles.detailsBar}>
      <NameContainer
        isLoaded={true}
        augurName={repoMeta?.repoFullName.split('/').at(-1)}
        augurHealth={4} // TODO extract value from report?
        habitatName={'Dev Augur'}
      />
    </div>
  );
};

export default OverlayDevAugurDetails;
