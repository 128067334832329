import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import FruitObjectDetection from './assistants/fruitObjectDetection/FruitObjectDetection';
import GraphSearch from './assistants/graphSearch/GraphSearch';
import Rag from './assistants/rag/Rag';
import Speech2Text from './assistants/speech2Text/Speech2Text';
import AssistantsOverview, {
  ASSISTANT_ID_FRUIT_OBJECT_DETECTION,
  ASSISTANT_ID_GRAP_SEARCH,
  ASSISTANT_ID_RAG,
  ASSISTANT_ID_SPEECH2TEXT,
} from './overview/AssistantsOverview';
import { assistantsRoutes } from './routes';
import MainContainer from '../pages/main-container/MainContainer';

type Props = {};

const Assistants: FC<Props> = () => {
  return (
    <MainContainer transparent={false} fullWidth scrollableY={false}>
      <Switch>
        {/* Assistant: Speech2Text */}
        <Route
          path={`${assistantsRoutes.basePath}/${ASSISTANT_ID_SPEECH2TEXT}`}
          component={Speech2Text}
        />

        {/* Assistant: RAG */}
        <Route
          path={`${assistantsRoutes.basePath}/${ASSISTANT_ID_RAG}`}
          component={Rag}
        />

        {/* Assistant: GraphSearch */}
        <Route
          path={`${assistantsRoutes.basePath}/${ASSISTANT_ID_GRAP_SEARCH}`}
          component={GraphSearch}
        />

        {/* Assistant: FruitObjectDetection */}
        <Route
          path={`${assistantsRoutes.basePath}/${ASSISTANT_ID_FRUIT_OBJECT_DETECTION}`}
          component={FruitObjectDetection}
        />

        {/* Overview */}
        <Route
          path={assistantsRoutes.basePath}
          component={AssistantsOverview}
        />
      </Switch>
    </MainContainer>
  );
};

export default Assistants;
