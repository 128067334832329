import { useQueryClient } from '@tanstack/react-query';
import React, { FC } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import ReactLoading from 'react-loading';

import vars from '../../../../../../../scss/base/var.module.scss';
import {
  useCommits,
  versionControlKeys,
} from '../../../../../../core/api/versionControl';
import { CommitFilter } from '../../../../../../core/api/workbench/git.notebook';
import Button from '../../../../../atoms/button/Button';

interface GitListCommitsProps {
  repositoryPath: string;
  activeBranch: string;
}
const GitListCommits: FC<GitListCommitsProps> = ({
  repositoryPath,
  activeBranch,
}) => {
  const queryClient = useQueryClient();
  async function invalidateCommits() {
    await queryClient.invalidateQueries(
      versionControlKeys.commits(repositoryPath, activeBranch)
    );
  }
  const {
    data: commits,
    error,
    isLoading,
  } = useCommits(repositoryPath, activeBranch, CommitFilter.None);
  const {
    data: unpushedCommits,
    error: unpushedCommitsError,
    isLoading: unpushedCommitsLoading,
  } = useCommits(repositoryPath, activeBranch, CommitFilter.NotPushed);
  const renderLoaded = () => {
    const unpushedShas = unpushedCommits
      ? unpushedCommits.map((d) => d.hexsha)
      : [];

    return (
      <div className={'git-list-commits'}>
        {commits && commits.length > 0 && (
          <div className={'commit-list ct-list'}>
            <div className={'ct-headline ct-row'}>
              <div className={'ct-col ct-col-flex-grow'}>Message</div>
              <div className={'ct-col ct-col-200px'}>Timestamp</div>
              <div className={'ct-col ct-col-200px'}>Comitter</div>
            </div>
            {commits?.map((commit) => (
              <div className={'ct-row'} key={commit?.hexsha}>
                <div className={'ct-col ct-col-flex-grow'}>
                  {commit?.message}
                  {unpushedShas.includes(commit?.hexsha) && (
                    <span className={'unpushed-label'}>
                      <i> unpushed</i>
                    </span>
                  )}
                </div>
                <div className={'ct-col ct-col-200px'}>
                  {commit?.committed_date}
                </div>
                <div className={'ct-col ct-col-200px'}>{commit?.committer}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderLoading = () => (
    <div className={'git-list-commits'}>
      <ReactLoading
        className={'starting-stopping-spinner'}
        type={'cylon'}
        color={vars.colorPrimary}
      />
    </div>
  );

  const renderEmpty = () => (
    <div className={'git-list-commits'}>
      There are no commits for this repository yet
    </div>
  );

  const renderError = () => (
    <div className={'git-list-commits'}>
      {error}

      <div className={'refresh-button-container'}>
        <Button
          Icon={() => <FiRefreshCw size={16} />}
          onClick={() => invalidateCommits()}
        />
      </div>
    </div>
  );

  if (isLoading) return renderLoading();
  if (!isLoading) return renderLoaded();
  if (error) return renderError();
  return renderEmpty();
};

export default GitListCommits;
