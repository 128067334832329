import dagre from 'dagre';
import _ from 'lodash';
import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  Controls,
  Edge,
  MarkerType,
  Node,
  Position,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/base.css';
import './styles.scss';

import FlowElementGroup from './chart-elements/FlowElementGroup';
import FlowElementNode from './chart-elements/FlowElementNode';

import { NodeType, PipelineTuningSchemaType } from 'common/src/types/pipeline';

export type PipelineTuningChartErrorType = {
  [parameterPath: string]: string | undefined;
};

type Props = {
  /** The pipeline schema */
  pipeline: PipelineTuningSchemaType;
  /** Callback for when a node is selected */
  onSelectingNode: (selectedNode: NodeType) => void;
  inactiveNodeIds: string[];
  error?: PipelineTuningChartErrorType;
  pipelineIndex?: number;
};

const getLayoutedNodes = (nodes: Node[], edges: Edge[]): Node[] => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodesMap = _.keyBy(nodes, 'id');

  const isHorizontal = true;
  dagreGraph.setGraph({ rankdir: 'LR' }); // rankdir: LR | HR

  nodes.forEach((el) => {
    dagreGraph.setNode(el.id, {
      width: nodesMap[el.id].width,
      height: nodesMap[el.id].height,
    });
  });
  edges.forEach((el) => {
    dagreGraph.setEdge(el.source, el.target);
  });

  dagre.layout(dagreGraph);

  return nodes.map((el) => {
    const nodeWithPosition = dagreGraph.node(el.id);
    el.targetPosition = isHorizontal ? Position.Left : Position.Top;
    el.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

    el.position = {
      x: nodeWithPosition.x - nodesMap[el.id].width / 2,
      y: nodeWithPosition.y - nodesMap[el.id].height / 2,
    };
    return el;
  });
};

const nodeTypes = {
  node: FlowElementNode,
  group: FlowElementGroup,
};

/**
 * Takes the pipeline definition and converts it to the schema as it's required by react flow.
 * Simply converts the format, doesn't change anything in the logic how the graph is connected.
 * @param pipeline
 */
function pipelineTuningSchemaToReactFlow(
  pipeline: PipelineTuningSchemaType,
  error?: PipelineTuningChartErrorType,
  pipelineIndex?: number
): [Node[], Edge[]] {
  // --- Convert the nodes to the react flow schema
  const convertedNodes = pipeline.nodes.map((node) => ({
    id: node.id,
    type: node.type,
    position: {
      x: 0, // will be set by onLayout, this is just an initial value
      y: 0, // will be set by onLayout, this is just an initial value
    },
    data: {
      ...node,
      error: error,
      pipelineIndex: pipelineIndex,
    },
  }));

  // --- Convert the edges to the react flow schema
  const convertedEdges: Edge[] = pipeline.edges.map(
    (edge) =>
      ({
        id: `${edge.sourceID}-${edge.targetID}`,
        source: edge.sourceID,
        target: edge.targetID,
        animated: false,
        type: 'default', // default = bezier curve
        markerEnd: { type: MarkerType.ArrowClosed },
      } satisfies Edge)
  );

  // --- Simply append the converted nodes and edges, since this is how react flow treats them
  return [convertedNodes, convertedEdges];
}

/**
 * Generic Component that renders a tuning pipeline. Can be used for both the tuning input (which parameters are
 * supposed to be tested against each other) and to display the structure of the actual model.
 *
 * The flow how elements lead to a graph goes like this:
 * 1. Add the elements to the graph (they have x/y = 0 and no width)
 * 2. React-flow renders them and sets their height and width
 * 3. Read that information (from the nodes), calculate a graph layout and set it via the elements
 * 4. (Measure the height and set the css for that)
 * 5. Fit view
 *
 * @param props
 * @constructor
 */
const PipelineTuningChart: FC<Props> = ({
  onSelectingNode,
  inactiveNodeIds,
  pipeline,
  error,
  pipelineIndex,
}) => {
  const inactiveNodeIdCount = inactiveNodeIds.length;

  // --- Generate the elements from the schema and attach the "setSelectedNode" callback for groups
  // Can only be calculated once, since it's only used as initial value in useState, which will only use it once anyway
  const [initialNodes, initialEdges]: [Node[], Edge[]] = useMemo(() => {
    const [nodes, edges] = pipelineTuningSchemaToReactFlow(
      pipeline,
      error,
      pipelineIndex
    );
    return [
      nodes.map((el) => {
        if (el.type === 'group') {
          return {
            ...el,
            data: {
              ...el.data,
              setSelectedNode: (n) => {
                setSelectedNode(n);
                onSelectingNode(n);
              },
            },
          };
        } else {
          return el;
        }
      }),
      edges,
    ];
  }, []);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  // --- Stuff for selection and node state / inactivity
  const [selectedNode, setSelectedNode] = useState(null);
  function onSelectionChange({
    nodes,
    edges,
  }: {
    nodes: Node[];
    edges: Edge[];
  }) {
    if (!nodes || nodes.length === 0) {
      setSelectedNode(null);
      onSelectingNode(null);
    } else if (nodes[nodes.length - 1].type === 'group') return;
    // Groups are not selectable (but will track the node selection by themselves)
    else {
      const node = nodes[nodes.length - 1]?.data;
      setSelectedNode(node);
      onSelectingNode(node);
    }
  }
  // Set the 'selectedNodeId' and isInactive flag of all nodes
  const updateSelectedAndInactiveNodes = () => {
    const layoutedNodes = nodes.map((el) => ({
      ...el,
      data: {
        ...el.data,
        error: error,
        pipelineIndex: pipelineIndex,
        selectedNodeId: selectedNode?.id,
        isInactive: inactiveNodeIds.includes(el.data?.id),
      },
    }));
    layoutedNodes
      .filter((el) => el.type === 'group')
      .forEach((el) => {
        el.data = {
          ...el.data,
          nodes: (el.data?.nodes || []).map((n) => ({
            ...n,
            isInactive: inactiveNodeIds.includes(n.id),
            error: error,
            pipelineIndex: pipelineIndex,
          })),
        };
      });

    setNodes(layoutedNodes);
  };

  // Keep updating the nodes to show their selection or activity state
  useEffect(() => {
    updateSelectedAndInactiveNodes();
  }, [selectedNode, inactiveNodeIdCount, error]);

  // --- Stuff for measuring and setting the viewport
  const [measure, setMeasure] = useState({ width: 100, height: 100 });
  const { fitView, getNodes } = useReactFlow();

  // Do the layout once after nodes have been rendered, and we know their width/height
  //  useNodesInitialized is one render early. It says they have been measured, but nodes still contains the old ones
  //  you could get the very latest ones with getNodes()???
  const hasRendered = nodes.find((n) => n.height) !== undefined;
  useEffect(() => {
    if (!hasRendered) return; // Nothing to do yet
    const layoutedNodes = getLayoutedNodes(nodes, edges);
    setNodes(layoutedNodes);
  }, [hasRendered]);

  // Measure the actually rendered nodes (get their location and their size)
  const measureNodes = () => {
    const width =
      Math.max(...nodes.map((node) => node.position.x + node.width)) + 15; // "+ 15" to add some sort of margin on the right side to have some space after scrolling all to the right
    const height =
      Math.max(...nodes.map((node) => node.position.y + node.height)) + 25; // "+ 25" for the scroll bar that might appear at the bottom
    setMeasure({ width, height });
  };

  // Measure once, cut twice (Should only need to measure if the nodes (size or layout) changed. Never after initially rendering and layouting them?)
  useEffect(() => {
    if (nodes.length === 0) return; // Nothing to do yet
    measureNodes();
  }, [nodes]);

  // Fit view after measuring (measuring only used as a proxy for the fact that the size or layout changed)
  // (seems to work by setting the zoom level depending on available width & height)
  useEffect(() => {
    fitView();
  }, [fitView, measure.width]);

  return (
    <div className={'PipelineTuningChart'}>
      <div
        style={{
          height: '400px',
          flexGrow: 1,
        }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodesDraggable={false}
          nodesConnectable={false}
          nodeTypes={nodeTypes}
          elementsSelectable={true}
          onSelectionChange={onSelectionChange}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          panOnDrag={true}
          zoomOnScroll={false}
          zoomOnPinch={true}
          zoomOnDoubleClick={false}
          preventScrolling={false} // Can't scroll inside the ReactFlow area, but may need to scroll parent/siblings
          minZoom={0.1} // Default is 0.5, which would limit the extent fitView can zoom out
        >
          <Controls showInteractive={false} showFitView={true} />
        </ReactFlow>
      </div>
    </div>
  );
};

const WrappingPipelineTuningChart: FC<Props> = (props) => {
  return (
    <ReactFlowProvider>
      <PipelineTuningChart {...props} />
    </ReactFlowProvider>
  );
};

export default WrappingPipelineTuningChart;
