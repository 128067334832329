import { HABITAT_SCOPES } from 'common/dist/constants/keycloak';
import { FrontendConfig } from 'common/dist/types/frontendConfig';
import Keycloak from 'keycloak-js';

import { apiRequest } from './js/core/api/_tools';
import { clearLocalStorage } from './js/localStorage';

let keycloak;
if (typeof Keycloak !== 'undefined') {
  keycloak = new Keycloak('/api/keycloak.json');
}

// Set the config from backend /api/keycloak.json (can't use frontend/public/keycloak.json because of env variable changes)

function setKeycloakToken(accessToken: string, refreshToken: string) {
  // Better to make sure, since undefined is saved as "undefined" and later read as such
  if (accessToken) {
    window.localStorage.setItem('accessToken', accessToken);
  }
  if (refreshToken) {
    window.localStorage.setItem('refreshToken', refreshToken);
  }
}

export function unsetKeycloakToken() {
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('refreshToken');
}

// TODO duplicated in backend
const BackendClient = 'altasigma';

/**
 * Replicates the format of the Keycloak object and manages a single set of an RPT
 * (relying party token = access token with more specific content for a specific purpose)
 * Currently not used since RPTs may grow to large to fit most header max size settings (8kB)
 */
class TokenManager {
  keycloak: {
    endpoints: {
      token(): string;
    };
    token: string;
    updateToken(minValidity: number): Promise<boolean>;
    login(options?: Record<string, unknown>): void;
  };
  accessToken: string;
  accessTokenParsed: Record<string, unknown>;
  refreshToken: string;
  refreshTokenParsed: Record<string, unknown>;
  refreshQueue: PromiseExtResolve<boolean>[];

  constructor(keycloak) {
    this.keycloak = keycloak;
    this.refreshQueue = [];
  }

  setToken(accessToken: string, refreshToken: string) {
    // Parse first, so we don't set garbage to the unparsed tokens
    this.accessTokenParsed = decodeToken(accessToken);
    this.accessToken = accessToken;
    this.refreshTokenParsed = decodeToken(refreshToken);
    this.refreshToken = refreshToken;
  }

  /**
   * Get a relying party token from the token service with our access token.
   * It is intended for the dashboard backend and contains the permissions on habitats etc.
   * keycloak.endpoints.token is undocumented
   *
   *   http://${host}:${port}/auth/realms/${realm}/protocol/openid-connect/token \
   *   -H "Authorization: Bearer ${access_token}" \
   *   --data "grant_type=urn:ietf:params:oauth:grant-type:uma-ticket" \
   *   --data "audience={resource_server_client_id}" \
   *   --data "permission=Resource A#Scope A" \
   */
  async init() {
    const refreshed = await this.keycloak.updateToken(5);
    const grantResponse = await fetch(this.keycloak.endpoints.token(), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.keycloak.token}`,
        //'Content-Type': 'text/plain',
        'Content-Type': 'application/x-www-form-urlencoded', // Why not text/plain?
        Accept: 'application/json',
      },
      body:
        'grant_type=urn:ietf:params:oauth:grant-type:uma-ticket&' +
        `audience=${BackendClient}&` +
        `permission=#${HABITAT_SCOPES.VIEW}&` +
        `permission=#${HABITAT_SCOPES.EDIT}`,
    });
    try {
      const {
        access_token: accessToken,
        refresh_token: refreshToken,
      } = await grantResponse.json();
      this.setToken(accessToken, refreshToken);
    } catch (error) {
      console.error('error parsing tokenManager grant: ', error);
    }
  }

  isTokenExpired(minValidity) {
    if (!this.accessTokenParsed) {
      throw new Error('Not authenticated');
    }

    let expiresIn =
      (this.accessTokenParsed.exp as number) -
      Math.ceil(new Date().getTime() / 1000);
    if (minValidity) {
      if (isNaN(minValidity)) {
        throw new Error('Invalid minValidity');
      }
      expiresIn -= minValidity;
    }
    return expiresIn < 0;
  }

  async updateToken(minValidity = 5) {
    const promise = createPromise();

    let refreshToken = false;
    if (minValidity === -1) {
      refreshToken = true;
    } else if (!this.accessTokenParsed || this.isTokenExpired(minValidity)) {
      refreshToken = true;
    }

    if (!refreshToken) {
      promise.resolve(false);
    } else {
      this.refreshQueue.push(promise);

      // Only do work with the first enqueued promise
      if (this.refreshQueue.length === 1) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        const grantResponse = await fetch(this.keycloak.endpoints.token(), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body:
            'grant_type=refresh_token&' +
            `refresh_token=${this.refreshToken}&` +
            `client_id=${keycloak.clientId}&` +
            `audience=${BackendClient}`, // TODO this does not work and changes the audience from the originally requested one
        });
        try {
          const {
            access_token: accessToken,
            refresh_token: refreshToken,
          } = await grantResponse.json();
          this.setToken(accessToken, refreshToken);
        } catch (error) {
          console.error('error parsing tokenManager grant: ', error);
          this.keycloak.login(); // Probably not necessary, since failure should only happen on expired session, which will be checked by the iframe
        }

        // Resolve the first and all meanwhile enqueued and waiting promises
        for (
          let p = this.refreshQueue.pop();
          p != null;
          p = this.refreshQueue.pop()
        ) {
          p.resolve(true);
        }
      }
    }

    return promise.promise;
  }

  // ...and other checks
  hasRealmRole(role) {
    // TODO?
  }
}

// export let backendTokenManager: TokenManager;

/** A wrapper for promises which allows for external resolving. Useful for queuing promises and resolving them elsewhere */
interface PromiseExtResolve<T = unknown> {
  promise: Promise<T>;

  resolve(result: T): void;

  reject(result: T): void;
}

function createPromise(): PromiseExtResolve<boolean> {
  const p: Partial<PromiseExtResolve<boolean>> = {};
  p.promise = new Promise((resolve, reject) => {
    p.resolve = resolve;
    p.reject = reject;
  });
  return p as PromiseExtResolve<boolean>;
}

/**
 * Decode jwt tokens.
 * @param str
 */
function decodeToken(str) {
  str = str.split('.')[1];

  str = str.replace(/-/g, '+');
  str = str.replace(/_/g, '/');
  switch (str.length % 4) {
    case 0:
      break;
    case 2:
      str += '==';
      break;
    case 3:
      str += '=';
      break;
    default:
      throw 'Invalid token';
  }

  str = decodeURIComponent(escape(atob(str)));

  str = JSON.parse(str);
  return str;
}

export async function initKeycloak() {
  const accessToken = window.localStorage.getItem('accessToken');
  const refreshToken = window.localStorage.getItem('refreshToken');
  try {
    const authenticated = await keycloak.init({
      onLoad: 'login-required',
      token: accessToken,
      refreshToken,
      // scope: ["roles"] // No way to pass options to login in init TODO? do keycloak.login() after keycloak.init()?
    });
    // console.log(authenticated ? 'authenticated' : 'not authenticated');
  } catch (e) {
    console.error('failed to initialize due to error: ', e);
    return false;
  }
  setKeycloakToken(keycloak.token, keycloak.refreshToken);

  // Refresh
  // TODO why does it also refresh fresh tokens? Does it?
  //console.log(`checking if exp ${new Date(keycloak.tokenParsed.exp * 1000)} is expired`)
  //console.log(`jti before updating ${keycloak.tokenParsed.jti}`)
  const refreshed = await keycloak.updateToken(5);
  //console.log(`jti after updating ${keycloak.tokenParsed.jti}`)
  try {
    if (refreshed) {
      // console.log('authenticated and refreshed');
    }
    setKeycloakToken(keycloak.token, keycloak.refreshToken);
  } catch (e) {
    console.error(
      'Failed to refresh the token, or the session has expired, error: ',
      e
    );
  }

  // Other RPTs
  // backendTokenManager = new TokenManager(keycloak);
  // await backendTokenManager.init();

  return true;
}

export async function logout(
  frontendConfig?: FrontendConfig,
  logoutOptions?: { redirectUri: string }
) {
  // Await the logouts since we don't want the redirect from keycloak.logout() to interrupt any cookie deletion
  const superset = frontendConfig?.components?.superset;
  if (superset?.enabled) {
    try {
      // TODO add timeout (small?)
      await fetch(`${superset.scheme}://${superset.domain}/logout/`, {
        credentials: 'include',
      });
    } catch (e) {
      console.error('Failed logging out from ssa: ', e);
    }
  }
  try {
    await apiRequest('/api/logoutCookies');
  } catch (e) {
    console.error('Failed logging out cookies from backend: ', e);
  }
  unsetKeycloakToken();
  clearLocalStorage();
  logoutOptions !== undefined
    ? keycloak.logout(logoutOptions)
    : keycloak.logout();
}

export async function updateToken(minValidity = 5): Promise<boolean> {
  const refreshed = await keycloak.updateToken(minValidity);
  if (refreshed) {
    setKeycloakToken(keycloak.token, keycloak.refreshToken);
  }
  return refreshed;
}

export default keycloak;
