import HttpStatusCode from 'common/dist/constants/httpStatusCodes';
import { ApiError } from 'common/dist/types/responseBodies/errors';

import keycloak, { updateToken } from '../../../../keycloak';
import { Options } from '../_tools';

export const HEADERS = {
  JSON: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  FORM: {
    Accept:
      'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
export const IS_API = '@@API';
const REQUEST_TIMEOUT = 10000;

/**
 * Response of the notebook git api sidecar after it went through the apiRequest method.
 * Attention: often the mimetype is not set correctly, which means the response could be read as a string even though it contains json
 */
export type NotebookGitApiResponse<TResponse = unknown, TError = unknown> =
  | {
      response: TResponse;
      error?: never;
      status?: never;
    }
  | {
      response?: never;
      error: TError;
      status: HttpStatusCode;
      /** Just included because of the function that produces this */
      [IS_API]: boolean;
    };

/**
 * TODO this is a bad amalgamation of all requests that happen in the workbench and on top of that is mostly a copy of
 *  the other CompletedApiRequest type.
 * It is probably used for requests to Jupyter, the NotebookGitApi or maybe even the Dashboard backend.
 * For that reason the typing is not actually very accurate, especially in regards to the error type
 */
export type CompletedWorkbenchRequest<T = unknown> =
  | Promise<{
      response: T | string;
      error?: never;
      status?: never;
    }>
  | Promise<{
      response?: never;
      /** The "any" is by design even though it overrides the others. This is so that more specific types can use string or an object for example */
      error: any | Record<string, unknown> | string | ApiError;
      status: HttpStatusCode;
      [IS_API]: boolean;
    }>;

export async function apiRequest<T>(
  url: string,
  options?: Options
): // @ts-ignore
CompletedWorkbenchRequest<T> {
  const refreshed = await updateToken();
  const response = await fetch(
    url,
    Object.assign({}, options, {
      cache: 'no-store' as RequestCache,
      headers: {
        ...options?.headers,
        Authorization: `Bearer ${keycloak.token}`,
      },
      // Will result in something like "DOMException: The operation was aborted."
      // You may want to catch it, check the name for "AbortError" and rethrow with more info
      signal: AbortSignal.timeout(REQUEST_TIMEOUT),
    })
  );
  if (response.ok) {
    return response
      .text()
      .then((text: string): string | T => {
        if (!text) return {} as T;
        try {
          return JSON.parse(text);
        } catch (e) {
          return text;
        }
      })
      .then((data) => ({ response: data }));
  } else if (response.status == 404) {
    if (url.includes('main.asapp')) {
      throw new Error('404 for main.asapp');
    }
  }

  // TODO why do we care for the content-type only in the error case, but parse any ok bodies as json anyway
  //  probably wait for better typing to fix this
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response
      .json()
      .then((json) => ({
        [IS_API]: true,
        error: json,
        status: response.status,
      }))
      .catch((err: Error) => ({
        [IS_API]: true,
        error: err.message,
        status: response.status,
      }));
  }
  return response.text().then((text) => ({
    [IS_API]: true,
    error: text,
    status: response.status,
  }));
}

export function putApiRequest(url, body, options = { headers: {} }) {
  return apiRequest(url, {
    ...options,
    method: 'PUT',
    headers: {
      ...options.headers,
      ...HEADERS.JSON,
      ...getXsrfHeader(),
    },
    body: JSON.stringify(body),
  });
}

export function postApiRequest<T>(
  url: string,
  body?: Record<string, unknown>,
  options = { headers: {} },
  contentType = 'json'
): CompletedWorkbenchRequest<T> {
  let headers = { ...options.headers };
  switch (contentType) {
    case 'json': {
      headers = {
        ...headers,
        ...HEADERS.JSON,
        ...getXsrfHeader(),
      };
      break;
    }
    case 'form':
      {
        headers = {
          ...headers,
          ...HEADERS.FORM,
        };
      }
      break;
  }

  let bodyProcessed: string | Record<string, unknown> = body;
  switch (contentType) {
    case 'application/json': {
      // Could be simplified with "case 'json'" but the fall-through didn't work for any reason
      bodyProcessed = JSON.stringify(body);
      break;
    }
    case 'json': {
      bodyProcessed = JSON.stringify(body);
      break;
    }
    case 'form':
      break; // Nothing to do
  }

  return apiRequest(url, {
    ...options,
    method: 'POST',
    headers,
    body: bodyProcessed,
  });
}

export function deleteApiRequest(
  url: string,
  options = { headers: {} }
): CompletedWorkbenchRequest {
  return apiRequest(url, {
    ...options,
    method: 'DELETE',
    headers: {
      ...options.headers,
      ...HEADERS.JSON,
      ...getXsrfHeader(),
    },
  });
}

export function patchApiRequest<T>(
  url,
  body,
  options = { headers: {} }
): CompletedWorkbenchRequest<T> {
  return apiRequest<T>(url, {
    ...options,
    method: 'PATCH',
    headers: {
      ...options.headers,
      ...HEADERS.JSON,
      ...getXsrfHeader(),
    },
    body: JSON.stringify(body),
  });
}

export function getApiRequest<T>(
  url,
  options = { headers: {} },
  contentType = 'json'
): CompletedWorkbenchRequest<T> {
  let headers = { ...options.headers };
  switch (contentType) {
    case 'json': {
      headers = {
        ...headers,
        ...HEADERS.JSON,
      };
      break;
    }
    case 'form':
      {
        headers = {
          ...headers,
          ...HEADERS.FORM,
        };
      }
      break;
  }

  return apiRequest<T>(url, {
    ...options,
    method: 'GET',
    headers,
  });
}

/**
 * Returns the Xsrf-Header required for POST/ (PUT/...?) requests against the Jupyter Notebook API
 * @returns {{}|{"X-XSRFToken": string | boolean}}
 */
function getXsrfHeader() {
  const cookie = getCookie('_xsrf');
  if (!cookie) return {};

  return { 'X-XSRFToken': cookie };
}

/**
 * Get cookie by name
 * @param name
 * @returns {string|boolean}
 */
function getCookie(name) {
  const pattern = RegExp(`${name}=.[^;]*`);
  const matched = document.cookie.match(pattern);
  if (matched) {
    const cookie = matched[0].split('=');
    return cookie[1];
  }
  return false;
}
