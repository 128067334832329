import { FrontendConfig } from 'common/dist/types/frontendConfig';
import { ModuleAvatar } from 'common/dist/types/module';
import React, { ComponentType, FC, useEffect, useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { useDeriveMenuEntries } from './entries';
import MenuEntry from './MenuEntry';
import styles from './styles.module.scss';
import { useDataSources } from '../../../core/api/data';
import { isAdmin as isAdminSelector } from '../../../redux/selectors/user.selector';
import { checkWhetherNotebookIsRunning } from '../../../redux/workbench/modules/connection.module';
import { notebookUser as notebookUserSelector } from '../../../redux/workbench/selectors/notebookUser.selector';
import { RootState } from '../../../store/store';
import { getVsCodeUrl } from '../../workbench/_entrypoint/workbench-main/VsCode';

export type MenuHrType = {
  /** To distinguish between entry and HR */
  type: 'hr';
};

export type MenuEntryType = {
  /** ID of the menu entry */
  id: string;
  /** To distinguish between entry, headline and HR */
  type: 'entry';
  /** URL to open when the menu entry is selected */
  path: string;
  /** Optional callback for when the menu entry is selected */
  onSelect?: () => void;
  /** Title of the menu entry */
  title: MessageDescriptor;
  /** Icon of the menu entry */
  Icon: ComponentType;
  subMenuEntries?: SubMenuEntriesType[];
  /** Open the link in a new browser tab? */
  openInNewTab?: boolean;
};

export type MenuHeadlineType = {
  type: 'headline';
  headline: MessageDescriptor;
};

export type SubMenuEntryType = {
  // TODO Think about melting this type with the MenuEntryType?
  /** ID of the sub menu entry */
  id: string;
  /** To distinguish between entry and loading */
  type: 'entry';
  /** URL to open when the sub menu entry is selected */
  path: string;
  /** Optional callback for when the sub menu entry is selected */
  onSelect?: () => void;
  /** Title of the sub menu entry */
  title: MessageDescriptor;
  /** Icon of the sub menu entry */
  Icon: ComponentType;
};

export type SubMenuLoadingType = {
  type: 'loading';
};

export type SubMenuHrType = {
  type: 'hr';
};

export type SubMenuLinkType = {
  id: string;
  type: 'projectLink';
  path: string;
  title: MessageDescriptor;
  repoType?: string;
  avatar?: ModuleAvatar;
  isLoading?: boolean;
  isActiveProject?: boolean;
};

export type SubMenuHeadlineType = {
  type: 'headline';
  headline: MessageDescriptor;
};

export type SubMenuEntriesType =
  | SubMenuEntryType
  | SubMenuHeadlineType
  | SubMenuLoadingType
  | SubMenuHrType
  | SubMenuLinkType;

export const menuTooltipId = 'menu-tooltip';

const MenuEntries: FC = () => {
  // Get the permissions / whether the user is an admin
  const permissions = useSelector<RootState, Record<string, string[]>>(
    (state) => state.dashboard?.permissions?.data
  );
  const isAdmin = useSelector<RootState, boolean>((state) =>
    isAdminSelector(state)
  );

  // Fetch the available data sources
  const qrDataSources = useDataSources();

  // Fetch the frontend config (e.g. to see whether Superset is available)
  const frontendConfig = useSelector<RootState, FrontendConfig>(
    (state) => state.config
  );

  // Check whether the workbench is running
  const notebookUser = useSelector<RootState, string>((state) =>
    notebookUserSelector(state)
  );
  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(checkWhetherNotebookIsRunning(notebookUser, false));
  }, [notebookUser]);
  const isWorkbenchRunning = useSelector<RootState, boolean>(
    (state) => state.workbench?.notebookRunning?.isRunning
  );

  // Check whether VS Code is available
  const { isRunning, isSpawning, isStopping } = useSelector<
    RootState,
    {
      isRunning?: boolean;
      isSpawning?: boolean;
      isStopping?: boolean;
    }
  >((state) => state.workbench?.notebookRunning);

  const [isVsCodeAvailable, setIsVsCodeAvailable] = useState<boolean>(null);

  useEffect(() => {
    const checkVsCodeAvailability = async () => {
      const res = await fetch(getVsCodeUrl(notebookUser));
      setIsVsCodeAvailable(res.ok);
    };
    void checkVsCodeAvailability();
  }, [notebookUser, isRunning, isSpawning, isStopping]);

  let { pathname: activePath } = useLocation();
  const menuEntries = useDeriveMenuEntries({
    permissions,
    isAdmin,
    qrDataSources,
    frontendConfig,
    isWorkbenchRunning,
    isVsCodeAvailable,
  });

  // -- Rewrite activePath
  // TODO This is a really dirty hack to ensure the "Model Management" menu entry is highlighted when browsing Augur Details.
  //   Instead of doing this, we should move the augur details below the /app/models/ route
  // "activePath" is only used to determine which menu entry is supposed to be highlighted
  if (activePath.startsWith('/app/habitat/')) {
    activePath = '/app/models/';
  }
  // ---

  return (
    <div className={styles.menuEntries}>
      {menuEntries.map((menuEntry, i) => {
        if (menuEntry.type === 'entry') {
          return (
            <MenuEntry
              menuEntry={menuEntry}
              activePath={activePath}
              key={`menu-entry-${i}`}
            />
          );
        } else if (menuEntry.type === 'hr') {
          return <hr className={styles.menuHr} key={`menu-entry-${i}`} />;
        } else if (menuEntry.type === 'headline') {
          return (
            <div className={styles.menuHeadline}>
              <FormattedMessage
                {...menuEntry.headline}
                key={`menu-entry-${i}`}
              />
            </div>
          );
        } else {
          return null;
        }
      })}
      <Tooltip
        id={menuTooltipId}
        place={'right'}
        className={styles.menuTooltip}
        noArrow
        offset={-12}
        delayShow={0}
      />
    </div>
  );
};

export default MenuEntries;
