import {
  watchCheckWhetherAppIsRunning,
  watchStartApp,
} from '../modules/app.connection.module';
import {
  watchFetchNotebookForApp,
  watchPostSessionForApp,
  watchSelectNextStepAndExecute,
  watchShowAppArrangeModal,
} from '../modules/app.module';
import { watchExecuteVariables } from '../modules/cells.app.module';
import { watchUpdateCodeCapsuleConfiguration } from '../modules/codeCapsule.module';
import {
  watchEditMergeRequest,
  watchForkRepository,
  watchListMergeRequests,
  watchLoadMergeRequestDetails,
  watchMergeMergeRequest,
  watchOpenMergeRequest,
  watchTriggerAppBuild,
  watchTriggerCodeCapsuleBuild,
} from '../modules/collab.module';
import {
  watchFetchBranches,
  watchFetchLatestCommit,
  watchFetchRepoContent,
  watchFetchRepoDetails,
} from '../modules/collab.repository.module';
import {
  watchCheckWhetherNotebookisRunning,
  watchStartServer,
} from '../modules/connection.module';
import {
  watchCheckGitRefFormat,
  watchCloneGitRepository,
  watchDeleteContent,
  watchEnsureDirectoryExistsSaga,
  watchGitConfig,
} from '../modules/container-interactions.module';
import {
  watchEmptyRecycleBin,
  watchOpenRemoveModuleArtifactsWizard,
  watchRenameContent,
  watchRestoreContent,
} from '../modules/content.module';
import {
  watchAddDirectory,
  watchSaveEditDirectory,
} from '../modules/directory.module';
import { watchGetDiskUsage } from '../modules/diskUsage.module';
import { watchAddFile } from '../modules/file.add.module';
import {
  watchInterruptKernel,
  watchRestartKernel,
} from '../modules/kernel.module';
import { watchFetchKernelspecs } from '../modules/kernelspecs.module';
import {
  watchFetchMergeRequestStatus,
  watchFetchRefStatus,
  watchLoadMergeRequestConflict,
  watchMergerCreateMergeRequest,
  watchSendResolveConflictCommit,
  watchSubmitConflictResolve,
} from '../modules/merger.module';
import {
  watchAddNotebook,
  watchFetchNotebook,
  watchFetchNotebookParentRepository,
  watchFetchNotebookUpdate,
  watchLoadRepoMeta,
  watchPasteNotebook,
  watchSaveEditNotebook,
  watchSaveNotebook,
  watchSaveNotebookByPath,
} from '../modules/notebook.module';
import {
  watchExecuteCells,
  watchOpenSocket,
  watchRequestCodeCompletion,
} from '../modules/notebook.websocket.module';
import {
  watchDeleteSession,
  watchFetchSessions,
  watchGetSessionDetails,
  watchPostSession,
} from '../modules/sessions.module';
import { watchUploadFiles } from '../modules/upload.module';

// This is imported in 'client/js/redux/saga/index' and injected into the rootSaga there,
// needs to be a function so that the rootSaga does not get stale workbenchSagas after restarting
export const workbenchSagas = () => [
  watchGitConfig(),
  watchFetchNotebook(),
  watchFetchNotebookUpdate(),
  watchOpenSocket(),
  watchExecuteCells(),
  watchRequestCodeCompletion(),
  watchSaveNotebook(),
  watchSaveNotebookByPath(),
  watchAddNotebook(),
  watchFetchSessions(),
  watchPostSession(),
  watchDeleteSession(),
  watchGetSessionDetails(),
  watchFetchKernelspecs(),
  watchAddDirectory(),
  watchSaveEditDirectory(),
  watchRenameContent(),
  watchRestoreContent(),
  watchEmptyRecycleBin(),
  watchOpenRemoveModuleArtifactsWizard(),
  watchRestartKernel(),
  watchInterruptKernel(),
  watchPasteNotebook(),
  watchSaveEditNotebook(),
  watchStartServer(),
  watchCheckWhetherNotebookisRunning(),
  watchAddFile(),
  watchForkRepository(),
  watchFetchLatestCommit(),
  watchFetchRepoContent(),
  watchFetchRepoDetails(),
  watchCloneGitRepository(),
  watchTriggerCodeCapsuleBuild(),
  watchTriggerAppBuild(),
  watchDeleteContent(),
  watchUploadFiles(),
  watchShowAppArrangeModal(),
  watchSelectNextStepAndExecute(),
  watchFetchNotebookParentRepository(),
  watchLoadRepoMeta(),
  watchExecuteVariables(),
  watchUpdateCodeCapsuleConfiguration(),
  watchEnsureDirectoryExistsSaga(),
  watchStartApp(),
  watchCheckWhetherAppIsRunning(),
  watchFetchNotebookForApp(),
  watchPostSessionForApp(),
  watchOpenMergeRequest(),
  watchListMergeRequests(),
  watchLoadMergeRequestDetails(),
  watchMergeMergeRequest(),
  watchEditMergeRequest(),
  watchLoadMergeRequestConflict(),
  watchSubmitConflictResolve(),
  watchSendResolveConflictCommit(),
  watchFetchBranches(),
  watchCheckGitRefFormat(),
  watchMergerCreateMergeRequest(),
  watchFetchMergeRequestStatus(),
  watchFetchRefStatus(),
  watchGetDiskUsage(),
];
