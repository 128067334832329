import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState, } from 'react';
import 'reactflow/dist/base.css';

import './styles.scss';
import { Job, JobGroupTopologyType } from 'common/dist/types/job';
import ReactFlow, {
  addEdge,
  applyNodeChanges,
  Connection,
  Controls,
  Edge,
  EdgeRemoveChange,
  isNode,
  MiniMap,
  Node,
  NodeChange,
  NodeRemoveChange,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow';
import { WrappedFieldProps } from 'redux-form';

import { ElementsToJobsAndJobGroupTopology, JobGroupTopologyToEdges, JobToNode, } from './flowUtils';
import { Value } from './form';
import { hasModal, ModalProps } from './Modals';
import { useAugurNames } from '../../../../core/api/augurs';
import { useCodeCapsuleNames } from '../../../../core/api/codeCapsules';
import { useHabitats } from "../../../../core/api/habitats";
import Busy from "../../../atoms/busy/Busy";
import { nodeTypes, Props as JobProps } from '../job/Job';
import styles from '../job/styles.module.scss';
import { getLayoutedNodesWithoutMeasuring } from '../job-group-topology-chart/graphLayout';

import { applyEdgeChanges } from '@xyflow/react';

import { ResourceNames } from "common/dist/types/utils";
import { HabitatWithScopes } from 'common/dist/types/habitat';

const jobWidthLarge = Number.parseInt(styles.jobWidthLarge);
const jobHeightLarge = Number.parseInt(styles.jobHeightLarge);

let id = 0;
const getId = () => `job_${id++}`;

export type InnerEditorPaneProps = {
  habitats: HabitatWithScopes[]
  augurNames: ResourceNames
  codeCapsuleNames: ResourceNames
}

export type EditorPaneProps = {
  jobs: Job[];
  jobGroupTopology: JobGroupTopologyType[];
  setShowModal: (
    showModal: ModalProps | ((prevState: ModalProps) => ModalProps)
  ) => void;
  closeModal: () => void;
  setShowError: Dispatch<SetStateAction<boolean>>;
  jobToAdd: Job;
} & WrappedFieldProps;

const EditorPaneInner: FC<EditorPaneProps& InnerEditorPaneProps
> = (props) => {
  const reactFlowWrapper = useRef(null);
  const reactFlowInstance = useReactFlow();
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  // Track a reference to the current state, which we need when creating functions like editJob that need to run with the
  // current state and not the state from the time the function was created (when adding the job) i.e. when we leave the
  // React context and a local reference to elements will be useless, since it will never update when used outside the render function
  const nodesStateRef = useRef<Node<JobProps>[]>();
  nodesStateRef.current = nodes;
  const edgesStateRef = useRef<Edge[]>()
  edgesStateRef.current = edges
  // Track the selected element(s) to decide which edge to add, when adding a new element by clicking instead of drag&drop
  const [selectedElements, setSelectedElements] = useState<
    Node<JobProps>[] | null
  >([]);
  const onEdgesChange = (changes) => {
    setEdges((es) => applyEdgeChanges(changes, es));
  };
  const onNodesChange = useCallback((changes: NodeChange[]) => {
    setNodes((ns) => applyNodeChanges(changes, ns));
    // While deleting with the button, the node is also selected, which would be wrong (could maybe avoid selecting it?)
    setSelectedElements((ns) => applyNodeChanges(changes, ns));
  }, []);
  const onConnect = useCallback(
    (connection: Connection) => setEdges((eds) => addEdge(connection, eds)),
    []
  );
  const onNodesRemove = useCallback(
    (nodesToRemove: Node<JobProps>[]) => {
      onNodesChange(
        nodesToRemove.map((node) => ({ id: node.id, type: 'remove' } satisfies NodeRemoveChange))
      );

      // we also need to remove the edges that are connected to nodes that are supposed to be removed
      const currentEdges = edgesStateRef.current
      const edgesToRemove = currentEdges.filter((edge) =>
        nodesToRemove.some(
          (node) =>
            edge.source === node.id || edge.target === node.id
        )
      );
      onEdgesChange(
        edgesToRemove.map((edge) => ({ id: edge.id, type: 'remove' } satisfies EdgeRemoveChange))
      );
    },
    [onNodesChange, onEdgesChange, edges]
  );

  /**
   * Edit Jobs which have modals
   * @param nodeId
   */
  const editJob = (nodeId: string) => {
    // TODO wut?
    props.setShowError(true);
    // Get the current state. We could also use the state from setElements since we will want to set it later, but this
    // way is more clear. Otherwise, elements would be a stale local reference from when the node was added
    const elements = nodesStateRef.current;

    const node = elements.find((node) => node.id === nodeId);
    if (node === undefined || !isNode(node)) {
      return;
    }
    if (hasModal(node.data.job.superType)) {
      props.setShowModal({
        isOpen: true,
        node: node,
        handleFinishedNode: (finishedNode) => {
          setNodes((es) => {
            const editedIndex = es.findIndex(
              (node) => node.id === finishedNode.id
            );
            return [
              ...es.slice(0, editedIndex),
              finishedNode,
              ...es.slice(editedIndex + 1),
            ];
          });
          props.closeModal();
        },
        onRequestClose: props.closeModal,
        edit: true,
      });
    }
  };

  /**
   * Add Jobs and trigger Modals if the JobType requires it
   * @param x
   * @param y
   * @param job
   * @param alreadyProjected
   */
  const addJob = async (
    x: number,
    y: number,
    job: Job,
    alreadyProjected = false
  ): Promise<Node<JobProps>> => {
    props.setShowError(true);

    let position: { x: number; y: number };
    if (alreadyProjected) {
      position = { x, y };
    } else {
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      position = reactFlowInstance.project({
        x: x - reactFlowBounds.left - jobWidthLarge / 2,
        y: y - reactFlowBounds.top - jobHeightLarge / 2,
      });
    }
    const id = getId();
    const jobWithId: Partial<Job> = { ...job, jobCode: id };
    const newNode: Node<JobProps> = JobToNode(
      jobWithId,
      props.augurNames,
      props.codeCapsuleNames,
      props.habitats,
      onNodesRemove,
      editJob
    );
    newNode.position = position;

    return new Promise(function (resolve, reject) {
      if (hasModal(jobWithId.superType)) {
        props.setShowModal({
          isOpen: true,
          node: newNode,
          handleFinishedNode: (finishedNode) => {
            setNodes((es) => es.concat(finishedNode));
            props.closeModal();
            resolve(newNode);
          },
          onRequestClose: () => {
            props.closeModal();
            resolve(undefined);
          },
        });
      } else {
        setNodes((es) => es.concat(newNode));
        resolve(newNode);
      }
    });
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  // Add nodes by dropping a job over the ReactFlow
  const onDrop = (event) => {
    event.preventDefault();
    addJob(
      event.clientX,
      event.clientY,
      JSON.parse(event.dataTransfer.getData('application/altasigma'))
    ).catch((e) => console.error(e));
  };

  // Add a job node when passed prop changes (alternative function to drag&drop was called elsewhere)
  useEffect(() => {
    if (props.jobToAdd) {
      if (
        !!selectedElements &&
        selectedElements.length === 1 &&
        selectedElements[0].type === 'jobNodeLarge'
      ) {
        // If exactly one element is selected, "append" the job to the selected element by adding the new job
        // and adding an edge between the selected element and the newly added job.
        const selectedElement = selectedElements[0];
        // Finding the element needs to be done to get the current position (if the element was selected and then
        //   dragged, the position of selectedElement is not up-to-date anymore)
        const upToDateSelectedElement = nodes.find(
          (e) => e.id === selectedElement.id
        ) as Node<JobProps>;
        const positionOfSelectedElement = upToDateSelectedElement.position;
        // TODO this can't really work with modals?
        addJob(
          positionOfSelectedElement.x + jobWidthLarge + 20,
          positionOfSelectedElement.y,
          props.jobToAdd,
          true
        )
          .then((addedJob) => {
            if (addedJob !== undefined) {
              // Connect the selected and the added node
              setEdges((els) =>
                addEdge(
                  {
                    source: selectedElement.id,
                    target: addedJob.id,
                    sourceHandle: null,
                    targetHandle: null,
                  },
                  els
                )
              );
              // Mark the added element as selected. Unfortunately, there doesn't seem to be a way to set the element as
              //   selected for react-flow too (which would mark it blue) ... but at least, when clicking the next node, it
              //   will be appended to the node just added
              setSelectedElements([addedJob]);
            }
          })
          .catch((e) => console.error(e));
      } else {
        // No element selected, or more than one element selected (not clear to which element the job is supposed to
        // appended to) -> Simply add the job, but don't add an edge
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        // values are chosen by what looks good
        const leftX = jobWidthLarge / 2;
        const centerY =
          (reactFlowBounds.bottom - reactFlowBounds.top - jobHeightLarge) / 2;
        addJob(leftX, centerY, props.jobToAdd, true)
          .then((addedJob) => {
            if (addedJob !== undefined) {
              // Mark the added element as selected. Unfortunately, there doesn't seem to be a way to set the element as
              //   selected for react-flow too (which would mark it blue) ... but at least, when clicking the next node, it
              //   will be appended to the node just added
              setSelectedElements([addedJob]);
            }
          })
          .catch((e) => console.error(e));
      }
    }
    // TODO the deps are misused to only trigger when jobToAdd changes
  }, [props.jobToAdd]);

  // Arrange the elements if the component was mounted and the values are not empty
  useEffect(() => {
    const value: Value = props.input.value;
    if (value !== undefined) {
      // Create the initial Elements by transforming the values in the reverse direction
      const initialEdges = (value.jobGroupTopology || []).flatMap((jgt) =>
        JobGroupTopologyToEdges(jgt)
      );
      const initialNodes = (value.jobs || []).map((job) =>
        JobToNode(
          job,
          props.augurNames,
          props.codeCapsuleNames,
          props.habitats,
          onNodesRemove,
          editJob
        )
      );
      // Layout the elements and return information about the layout like height
      const { nodes: layoutedNodes } = getLayoutedNodesWithoutMeasuring(
        initialNodes,
        initialEdges,
        jobWidthLarge,
        jobHeightLarge,
        {
          rankdir: 'LR',
          nodesep: 10,
          ranksep: 20,
          edgesep: 0,
          marginx: 5,
        }
      );
      // Add some spacing to the layouted elements (works better than different zoom levels returned by reactFlowInstance.fitView())
      const transformedNodes = layoutedNodes.map((e) => {
        return {
          ...e,
          position: {
            x: e.position.x + jobWidthLarge,
            y: e.position.y + 2 * jobHeightLarge,
          },
        };
      });
      setNodes(transformedNodes);
      setEdges(initialEdges);
    }
  }, []);

  // Transform and sync the react-flow elements with redux-form (after any change, i.e. adding, connecting, removing,...)
  useEffect(() => {
    const value = ElementsToJobsAndJobGroupTopology(nodes, edges);
    props.input.onChange(value);
  }, [nodes, edges]);

  return (
    <div className='JobGroupEditor--reactflow-wrapper' ref={reactFlowWrapper}>
      <ReactFlow
        nodes={nodes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        edges={edges}
        nodeTypes={nodeTypes}
        onConnect={onConnect}
        onDrop={onDrop}
        onDragOver={onDragOver}
      >
        <MiniMap />
        <Controls showInteractive={false} />
      </ReactFlow>
    </div>
  );
};

const EditorPane: FC<EditorPaneProps> = (props) => {
  const { data: augurNames, isLoading: isAugurNamesLoading } = useAugurNames();
  const { data: codeCapsuleNames, isLoading: isCodeCapsuleNamesLoading } = useCodeCapsuleNames();
  const { data: habitats, isLoading: isHabitatsLoading } = useHabitats()

  if (isAugurNamesLoading || isCodeCapsuleNamesLoading || isHabitatsLoading)
    return <Busy/>

  return <div className={'JobGroupEditor--dndflow'}>
    <ReactFlowProvider>
      <EditorPaneInner {...props} habitats={habitats} augurNames={augurNames} codeCapsuleNames={codeCapsuleNames}/>
    </ReactFlowProvider>
  </div>
}

export default EditorPane;
