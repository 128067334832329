import classNames from 'classnames';
import * as d3 from 'd3';
import { HierarchyPointNode } from 'd3';
import React from 'react';
import { Transition } from 'react-transition-group';

import {
  BinaryTreeState,
  deselectPreviewNode,
  hidePreviewNodePath,
  selectNode,
  selectPreviewNode,
  showPreviewNodePath,
  showSelectedNodePath,
} from './BinaryTreeShadowModel';
import NodeShape from './NodeShape';
import { getNodeType } from './treeChart/nodeParse';
import { getOrigin } from './treeChart/tree';
import { ClassificationTreeNode } from './type';

export type TreeNode = ClassificationTreeNode & {
  renderedChildren: TreeNode[];
};

type NodeProps = {
  // A node has the props 'x' and 'y', and this matches with the description of a HiearchyPointNode
  node: HierarchyPointNode<TreeNode>;
  state: BinaryTreeState;
  dispatch: (...args: any[]) => any;
  positiveClassName: string;
  negativeClassName: string;
  leafClassName: string;
  animationDuration: {
    mount?: {
      delay: number;
      duration: number;
    };
    update?: {
      delay: number;
      duration: number;
    };
    exit?: {
      delay: number;
      duration: number;
    };
  };
};

type NodePosition = { x: number; y: number };

type NodeState = {
  nodePosition: NodePosition;
};

class Node extends React.Component<NodeProps, NodeState> {
  gRef: any;
  constructor(props: NodeProps) {
    super(props);
    this.state = {
      nodePosition: {
        ...getOrigin(this.props.node),
      },
    };
    this.gRef = null;
    this.setGRef = this.setGRef.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleComponentExit = this.handleComponentExit.bind(this);
  }

  componentDidMount() {
    const { node, animationDuration } = this.props;
    const { x, y } = node;
    const { y: originY } = getOrigin(node);

    if (animationDuration && animationDuration.mount) {
      this.animateNode(
        [0.5, 1],
        [
          { x, y: originY },
          { x, y },
        ],
        animationDuration.mount.delay,
        animationDuration.mount.duration / 2
      );
    }
  }

  componentDidUpdate(prevProps: NodeProps) {
    const { node, animationDuration } = this.props;
    const { x, y } = prevProps.node;

    if (
      animationDuration &&
      animationDuration.update &&
      (node.x !== x || node.y !== y)
    ) {
      this.animateNode(
        [1],
        [{ x: node.x, y: node.y }],
        animationDuration.update.delay,
        animationDuration.update.duration
      );
    }
  }

  setGRef(ref: any) {
    this.gRef = ref;
  }

  handleMouseEnter() {
    const { dispatch, node } = this.props;
    dispatch(selectPreviewNode(node));
    dispatch(showPreviewNodePath());
  }

  handleMouseLeave() {
    const { dispatch, node } = this.props;
    dispatch(deselectPreviewNode());
    dispatch(hidePreviewNodePath());
  }

  handleClick() {
    const { dispatch, node } = this.props;
    dispatch(selectNode(node));
    dispatch(showSelectedNodePath());
  }

  handleComponentExit() {
    const { node, animationDuration } = this.props;
    const { x } = node;
    const { x: originX, y: originY } = getOrigin(node);

    if (animationDuration && animationDuration.exit) {
      this.animateNode(
        [0.5, 0],
        [
          { x, y: originY },
          { x: originX, y: originY },
        ],
        animationDuration.exit.delay,
        animationDuration.exit.duration / 2
      );
    }
  }

  animateNode(
    opacities: number[],
    positions: NodePosition[],
    delay: number,
    duration: number
  ) {
    let transition = d3.select(this.gRef).transition().delay(delay);
    positions.forEach((position, index) => {
      transition = transition
        .ease(d3.easeLinear)
        .duration(duration)
        .style('opacity', opacities[index])
        .attr('transform', `translate(${position.x},${position.y})`);
      if (index + 1 === positions.length) {
        transition = transition.on('end', () =>
          this.setState({ nodePosition: { ...position } })
        );
      } else {
        transition = transition.transition();
      }
    });
  }
  render() {
    const {
      state,
      node,
      positiveClassName,
      negativeClassName,
      leafClassName,
      animationDuration,
      ...restProps
    } = this.props;
    const type = getNodeType(node);
    const isActive = node === state.selectedNode || node === state.previewNode;
    const isSelected =
      !!state.selectedNode.data && node.data.id === state.selectedNode.data.id;
    const { x, y } = this.state.nodePosition;
    const isLeaf = type === 'leaf';
    const nodeClassName = classNames('tree-chart_node', {
      [positiveClassName]: !!+node.data.score,
      [negativeClassName]: !+node.data.score,
      [leafClassName]: isLeaf,
    });
    return (
      <Transition
        timeout={{
          exit: animationDuration.exit
            ? animationDuration.exit.delay + animationDuration.exit.duration
            : 0,
        }}
        onExit={this.handleComponentExit}
        {...restProps}
      >
        <g
          ref={this.setGRef}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onClick={this.handleClick}
          transform={`translate(${x},${y})`}
          style={{ opacity: 0 }}
        >
          <NodeShape
            className={nodeClassName}
            circle={isLeaf ? { r: 7, cx: 0, cy: 0 } : { r: 8, cx: 0, cy: 0 }}
            isLeaf={isLeaf}
            isActive={isActive}
            isSelected={isSelected}
          />
        </g>
      </Transition>
    );
  }
}
export default Node;
