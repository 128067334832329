import { TableReportData } from '../type';

export const personTable: TableReportData['data'] = [
  {
    id: 1,
    name: 'John Doe',
    age: 30,
    city: 'New York',
    occupation: 'Software Engineer',
    street: '123 Main St',
    isCustomer: false,
    date: '2023-10-15',
  },
  {
    id: 2,
    name: 'Jane Smith',
    age: 25,
    city: 'London',
    occupation: 'Data Scientist',
    street: '55 Oxford Street',
    isCustomer: true,
    date: '2024-03-05T14:30:00Z',
  },
  {
    id: 3,
    name: 'David Lee',
    age: 40,
    city: 'Tokyo',
    occupation: 'Doctor',
    street: 'Shibuya 1-1',
    isCustomer: false,
    date: '2025-07-20T08:45:00+02:00',
  },
  {
    id: 4,
    name: 'Sarah Jones',
    age: 35,
    city: 'Paris',
    occupation: 'Teacher',
    street: 'Rue Saint-Honoré',
    isCustomer: true,
    date: '2026-12-31T23:59:59-05:00',
  },
  {
    id: 5,
    name: 'Michael Brown',
    age: 28,
    city: 'Sydney',
    occupation: 'Marketing Manager',
    street: 'George Street',
    isCustomer: true,
    date: '2027-04-01',
  },
];

export const shortTable: TableReportData['data'] = [
  {
    Person: 'Arni',
    PR: 200,
  },
  {
    Person: 'Ronny',
    PR: 1000,
  },
  {
    Person: 'Tommy',
    PR: 400,
  },
];
