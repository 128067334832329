/**
 * Possible connection/handle data types.
 */
export const AS_DATA_TYPES = {
  ANY: 'any',
  TABLE: 'table',
  BOOL: 'bool',
  NUMBER: 'number',
  STRING: 'string',
} as const;
export type AsDataTypes = typeof AS_DATA_TYPES[keyof typeof AS_DATA_TYPES];
