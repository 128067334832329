import classNames from 'classnames';
import { Location } from 'history';
import React, { FC, useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { IntlShape } from 'react-intl/src/types';
import { Link, useLocation } from 'react-router-dom';

import { MAIN_MENU_QUERY_WHITELIST } from './MainMenu';
import { MenuEntryType, menuTooltipId, SubMenuEntryType } from './MenuEntries';
import styles from './styles.module.scss';
import SubMenuEntry from './SubMenuEntry';
import { toLinkWhitelistQuery } from '../../../utils';

type Props = {
  menuEntry: MenuEntryType;
  /** The currently active path, e.g. /apps/orchestration/history?offset=1&limit=20 */
  activePath: string;
};

/**
 * Checks for the largest match.
 * Sub-menu entries have priority over the main menu entry since they produce a closer match.
 * @param menuEntry
 * @param activePath
 */
function getMatchingPathOrNull(menuEntry: MenuEntryType, activePath: string) {
  const matchedSubMenuEntry = menuEntry.subMenuEntries
    ?.filter((me) => me.type === 'entry')
    .find((me) => {
      const subMenuPathWithoutQuery = (me as SubMenuEntryType).path?.split(
        '?'
      )[0];
      return activePath.startsWith(subMenuPathWithoutQuery);
    });
  if (matchedSubMenuEntry) {
    return (matchedSubMenuEntry as SubMenuEntryType).path;
  } else if (activePath.startsWith(menuEntry.path)) {
    return menuEntry.path;
  } else {
    return null;
  }
}

type MainEntryProps = {
  children: React.ReactNode;
  matchingPath: string;
  isExpanded: boolean;
  setExpanded: (isExpanded: boolean) => void;
  intl: IntlShape;
  path: string;
  title: MessageDescriptor;
  location: Location;
};

const MainMenuEntry: FC<MainEntryProps> = ({
  children,
  matchingPath,
  isExpanded,
  setExpanded,
  intl,
  path,
  title,
}) => (
  <div
    className={classNames(styles.entry, styles.mainEntry, {
      [styles.isActive]: matchingPath && matchingPath === path,
      [styles.isExpanded]: isExpanded,
    })}
    onClick={() => setExpanded(!isExpanded)}
    data-tooltip-id={menuTooltipId}
    data-tooltip-content={intl.formatMessage(title)}
  >
    {children}
    <div className={styles.expandToggle}>
      <FiChevronRight size={16} />
    </div>
  </div>
);

const NewBrowserTabEntry: FC<MainEntryProps> = ({
  children,
  matchingPath,
  intl,
  path,
  title,
}) => (
  <a
    href={path}
    className={classNames(styles.entry, styles.mainEntry, {
      [styles.isActive]: matchingPath && matchingPath === path,
    })}
    data-tooltip-id={menuTooltipId}
    data-tooltip-content={intl.formatMessage(title)}
    target={'_blank'}
    rel='noreferrer'
  >
    {children}
  </a>
);

const LinkEntry: FC<MainEntryProps> = ({
  children,
  matchingPath,
  intl,
  title,
  path,
  location,
}) => (
  <Link
    to={toLinkWhitelistQuery(path, location, MAIN_MENU_QUERY_WHITELIST)}
    className={classNames(styles.entry, styles.mainEntry, {
      [styles.isActive]: matchingPath && matchingPath === path,
    })}
    data-tooltip-id={menuTooltipId}
    data-tooltip-content={intl.formatMessage(title)}
  >
    {children}
  </Link>
);

const getMainEntryElement = (menuEntry: MenuEntryType): FC<MainEntryProps> => {
  if (menuEntry.subMenuEntries?.length > 0) {
    return MainMenuEntry;
  } else if (menuEntry.openInNewTab) {
    // Menu entry has no sub menu entries and is supposed to be opened in a new browser tab: render an <a>-Element
    return NewBrowserTabEntry;
  } else {
    // Menu entry has no sub menu entries, render a Link
    return LinkEntry;
  }
};

const MenuEntry: FC<Props> = ({ menuEntry, activePath }) => {
  const matchingPath = getMatchingPathOrNull(menuEntry, activePath);
  const location = useLocation();
  const MainEntryElement = getMainEntryElement(menuEntry);

  const [isExpanded, setExpanded] = useState<boolean>(false);
  const intl = useIntl();
  return (
    <div className={styles.menuEntry}>
      <MainEntryElement
        matchingPath={matchingPath}
        isExpanded={isExpanded}
        setExpanded={setExpanded}
        intl={intl}
        title={menuEntry.title}
        path={menuEntry.path}
        location={location}
      >
        <>
          <div className={styles.icon}>
            <menuEntry.Icon />
          </div>
          <FormattedMessage {...menuEntry.title}>
            {(text) => <span className={styles.title}>{text}</span>}
          </FormattedMessage>
        </>
      </MainEntryElement>

      {isExpanded &&
        menuEntry.subMenuEntries?.map((subMenuEntry, idx) => (
          <SubMenuEntry
            key={idx}
            subMenuEntry={subMenuEntry}
            matchingPath={matchingPath}
            intl={intl}
          />
        ))}
    </div>
  );
};

export default MenuEntry;
