import { BarChartV1ReportData as BarChartReportData } from 'common/dist/types/reportData/barChartV1ReportData';

import { Config } from '../../../../../pages/augur/type';

export type { BarChartReportData };

export type BarChartConfig = {
  xLabel?: string;
  yLabel?: string;
};
export type ConfigBarChartConfig = Config<BarChartConfig>;

export const configTypeBarChart = `{
 xLabel?: string;
 yLabel?: string;
}`;

export const reportDataTypeBarChart = `{
  data: [number, number][];
}`;

export const defaultConfigBarChart: ConfigBarChartConfig = {
  xLabel: { source: 'hard-coded', value: 'X-Axis' },
  yLabel: { source: 'hard-coded', value: 'Y-Axis' },
};

export const defaultReportDataDefaultBarChart: BarChartReportData = {
  data: [
    [0, 217],
    [0.05, 308],
    [0.1, 101],
    [0.15, 178],
    [0.2, 144],
    [0.25, 99],
    [0.3, 153],
    [0.35, 118],
    [0.4, 166],
    [0.45, 208],
    [0.5, 205],
    [0.55, 188],
    [0.6, 173],
    [0.65, 121],
    [0.7, 212],
    [0.75, 211],
    [0.8, 196],
    [0.85, 307],
    [0.9, 256],
    [0.95, 177],
  ],
};
