import classNames from 'classnames';
import React, { FC } from 'react';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import {
  FiChevronLeft,
  FiChevronsLeft,
  FiCode,
  FiPlay,
  FiSave,
} from 'react-icons/fi';
import { RxActivityLog } from 'react-icons/rx';
import { SlTarget } from 'react-icons/sl';

import { saveNotebook } from '../../../../../redux/workbench/modules/notebook.module';
import { useAppDispatch } from '../../../../../store/store';
import Button from '../../../../atoms/button/Button';

export type Props = {
  path: string;
  content: string;
  unsavedChanges: boolean;
  toggleSource: () => void;
  toggleLog: () => void;
  sendExecuteRequest: () => void;
  onGoBackFlowPath: () => void;
  onClearFlowPath: () => void;
  activeSidebarTab?: string;
};

const ButtonBar: FC<Props> = ({
  path,
  content,
  unsavedChanges,
  toggleSource,
  toggleLog,
  sendExecuteRequest,
  onGoBackFlowPath,
  onClearFlowPath,
  activeSidebarTab,
}) => {
  const dispatch = useAppDispatch();
  return (
    <div className={'workbench-buttons'}>
      <div
        title={'Save (Cmd+S)'}
        className={classNames('workbench-button', {
          'unsaved-changes': unsavedChanges,
        })}
        // @ts-ignore
        onClick={() => dispatch(saveNotebook(path, content, 'file'))}
      >
        <FiSave className={'icon save-icon'} size={'20px'} />
      </div>
      <Button
        Icon={() => <FiCode size={20} />}
        className={'workbench-button'}
        shape={'squared'}
        onClick={toggleSource}
        title={'Show Source'}
        toggled={activeSidebarTab === 'source'}
      />
      <Button
        Icon={() => <RxActivityLog size={20} />}
        className={'workbench-button'}
        shape={'squared'}
        onClick={toggleLog}
        title={'Show Log'}
        toggled={activeSidebarTab === 'log'}
      />
      <div
        title={'Execute'}
        className={'workbench-button'}
        onClick={sendExecuteRequest}
      >
        <FiPlay className={'icon play-icon'} size={'20px'} />
      </div>
      <div
        title={'Go back'}
        className={'workbench-button'}
        onClick={onGoBackFlowPath}
      >
        <FiChevronLeft size={'20px'} />
      </div>
      <div
        title={'Go to parent'}
        className={'workbench-button'}
        onClick={onClearFlowPath}
      >
        <FiChevronsLeft size={'20px'} />
      </div>
    </div>
  );
};

export default ButtonBar;
