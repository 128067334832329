import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import HttpStatusCode from 'common/dist/constants/httpStatusCodes';
import notificationsMsgs from 'common/dist/messages/notifications';
import { PullConflicts, PushConflicts } from 'common/dist/types/repository';
import { useDispatch, useSelector } from 'react-redux';

import { fetchQueryFnWorkbench } from './_tools';
import { versionControlKeys } from './versionControl';
import { NotebookGitApiResponse } from './workbench/_apiRequests';
import { contentKeys } from './workbench/content';
import NotebookApi from './workbench/git.notebook';
import { CommitFormData } from '../../components/workbench/part-right/repository/info/file-status/GitFileStatus';
import { GitPullMutation } from '../../components/workbench/part-right/repository/info/git-not-pulled/GitNotPulled';
import { sendNotification } from '../../redux/modules/notifications.module';
import { fetchNotebookUpdate } from '../../redux/workbench/modules/notebook.module';
import { getPaneNotebooksNoUnsaved } from '../../redux/workbench/selectors/notebook.selectors';
import { notebookUser } from '../../redux/workbench/selectors/notebookUser.selector';
import { error as errorType, event as eventType } from '../notifications';

export const notebookKeys = {
  all: () => ['notebook'] as const,
  listBranches: (repositoryPath: string) => [
    ...notebookKeys.all(),
    repositoryPath,
    'listBranches',
  ],
  getRemote: (repositoryPath: string) => [
    ...notebookKeys.all(),
    repositoryPath,
    'getRemote',
  ],
};
export const useListBranches = (
  repositoryPath: string,
  enabled?: boolean
): UseQueryResult<unknown[]> => {
  const key = notebookKeys.listBranches(repositoryPath);

  const jupyterUser = useSelector((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  return useQuery(
    key,
    () =>
      fetchQueryFnWorkbench(key, () => notebookApi.getBranches(repositoryPath)),
    {
      enabled: enabled,
    }
  );
};

export const useGitGetRemote = (
  repositoryPath: string,
  enabled?: boolean
): UseQueryResult<string> => {
  const key = notebookKeys.getRemote(repositoryPath);
  const jupyterUser = useSelector((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);

  return useQuery(
    key,
    () =>
      fetchQueryFnWorkbench(key, () => notebookApi.getRemote(repositoryPath)),
    {
      enabled: enabled,
    }
  );
};

/*Mutations*/
export const useGitFetchAndTrackRemoteBranch = () => {
  const queryClient = useQueryClient();

  const jupyterUser = useSelector((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  return useMutation<
    unknown,
    Error,
    { repositoryPath: string; branchName: string }
  >(
    async ({ repositoryPath, branchName }) =>
      notebookApi.fetchAndTrackRemoteBranch(repositoryPath, branchName),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(versionControlKeys.all());
        void queryClient.invalidateQueries(notebookKeys.all());
      },
    }
  );
};

export function useGitPull() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const jupyterUser = useSelector((state) => notebookUser(state));
  //TODO: This is not the ideal solution. We should check the files in the onSettled method. Also we should do this check with the opened files in other hooks, e.g switchBranch
  const nbsNoUnsaved = useSelector((state) => getPaneNotebooksNoUnsaved(state));
  const notebookApi = new NotebookApi(jupyterUser);

  return useMutation<
    NotebookGitApiResponse<string, PullConflicts>,
    Error,
    GitPullMutation
  >(
    (submitValues: GitPullMutation) =>
      notebookApi.pull(
        submitValues.repositoryPath,
        submitValues.activeBranch,
        submitValues.force
      ),
    {
      onSettled: async (data, _, variables) => {
        const { response, error, status } = data;
        if (status === HttpStatusCode.OK) {
          dispatch(
            sendNotification(
              notificationsMsgs.msgTitleGitPullSuccess.id,
              // @ts-ignore
              notificationsMsgs.msgDescriptionGitPullSuccess.id,
              eventType
            )
          );
        } else {
          if (
            response &&
            (variables.force || status !== HttpStatusCode.CONFLICT)
          ) {
            // Refresh sidebar
            await queryClient.invalidateQueries(contentKeys.all());

            for (const [paneId, notebooks] of Object.entries(nbsNoUnsaved)) {
              for (const notebookPath of notebooks) {
                if (notebookPath.startsWith(variables.repositoryPath)) {
                  //@ts-ignore
                  dispatch(fetchNotebookUpdate(notebookPath, paneId));
                }
              }
            }

            // Force currently uses reset, so the local commits will be removed and only the file changes kept
            if (variables.force) {
              dispatch(
                sendNotification(
                  notificationsMsgs.msgTitleGitPullSuccess.id,
                  // @ts-ignore
                  notificationsMsgs.msgDescriptionGitPullSuccess.id,
                  eventType
                )
              );
            }
          } else if (status === HttpStatusCode.CONFLICT) {
            dispatch(
              sendNotification(
                notificationsMsgs.msgTitleGitPullFailure.id,
                // @ts-ignore
                notificationsMsgs.msgDescriptionGitPullFailure.id,
                errorType
              )
            ); // Throw an error with the response data
            throw Error(JSON.stringify(error));
          }
        }

        await queryClient.invalidateQueries(versionControlKeys.all());
      },
    }
  );
}

export function useGitAddAllAndCommitHook() {
  const queryClient = useQueryClient();

  const jupyterUser = useSelector((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);

  return useMutation<
    unknown,
    Error,
    {
      repositoryPath: string;
      commitMessage: string;
    }
  >(
    async (submitValues: CommitFormData) =>
      notebookApi.addAllAndCommit(
        submitValues.repositoryPath,
        submitValues.commitMessage
      ),
    {
      onSettled: () => {
        // Don't await, because this mutation should just invalidate the queries, not wait until they are fetched again
        void queryClient.invalidateQueries(notebookKeys.all());
      },
    }
  );
}

export function useGitPushHook() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const jupyterUser = useSelector((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);

  return useMutation<
    NotebookGitApiResponse<string, PushConflicts>,
    Error,
    { repositoryPath: string; activeBranch: string }
  >(
    ({ repositoryPath, activeBranch }) => {
      return notebookApi.push(repositoryPath, activeBranch);
    },
    {
      onSettled: async (data) => {
        const { response, error, status } = data;
        // Don't await, because this mutation should just invalidate the queries, not wait until they are fetched again
        await queryClient.invalidateQueries(notebookKeys.all());
        if (response === 'OK' || status === HttpStatusCode.OK) {
          dispatch(
            sendNotification(
              notificationsMsgs.msgTitleGitPushSuccess.id,
              // @ts-ignore
              notificationsMsgs.msgDescriptionGitPushSuccess.id,
              eventType
            )
          );
          void queryClient.invalidateQueries(versionControlKeys.all());
        } else {
          dispatch(
            sendNotification(
              notificationsMsgs.msgTitleGitPushFailure.id,
              // @ts-ignore
              notificationsMsgs.msgDescriptionGitPushFailure.id,
              errorType
            )
          );
          // Throw an error with the response data
          throw Error(JSON.stringify(error));
        }
      },
    }
  );
}

export function useGitCreateBranchHook() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const jupyterUser = useSelector((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  return useMutation<
    unknown,
    Error,
    { repositoryPath: string; branchName: string }
  >(
    (submitValues: { repositoryPath: string; branchName: string }) =>
      notebookApi.createBranch(
        submitValues.repositoryPath,
        submitValues.branchName
      ),
    {
      onSettled: async () => {
        // Don't await, because this mutation should just invalidate the queries, not wait until they are fetched again
        await queryClient.invalidateQueries(notebookKeys.all());
      },
      onSuccess: () => {
        dispatch(
          sendNotification(
            notificationsMsgs.msgTitleGitCreateBranchSuccess.id,
            // @ts-ignore
            notificationsMsgs.msgDescriptionGitCreateBranchSuccess.id,
            eventType
          )
        );
        void queryClient.invalidateQueries(versionControlKeys.all());
      },
      onError: () => {
        dispatch(
          sendNotification(
            notificationsMsgs.msgTitleGitCreateBranchFailure.id,
            // @ts-ignore
            notificationsMsgs.msgDescriptionGitCreateBranchFailure.id,
            errorType
          )
        );
      },
    }
  );
}

export function useGitDeleteBranchHook() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const jupyterUser = useSelector((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);

  return useMutation<
    unknown,
    Error,
    {
      repositoryPath: string;
      branchName: string;
      force: boolean;
      remote: boolean;
    }
  >(
    async ({ repositoryPath, branchName, force, remote }) => {
      return notebookApi.deleteBranch(
        repositoryPath,
        branchName,
        force,
        remote
      );
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(versionControlKeys.all());
        await queryClient.invalidateQueries(notebookKeys.all());
      },
      onSuccess: () => {
        dispatch(
          sendNotification(
            notificationsMsgs.msgTitleGitDeleteBranchSuccess.id,
            // @ts-ignore
            notificationsMsgs.msgDescriptionGitDeleteBranchSuccess.id,
            eventType
          )
        );
      },
      onError: () => {
        dispatch(
          sendNotification(
            notificationsMsgs.msgTitleGitDeleteBranchFailure.id,
            // @ts-ignore
            notificationsMsgs.msgDescriptionGitDeleteBranchFailure.id,
            errorType
          )
        );
      },
    }
  );
}

export const useGitSwitchBranch = () => {
  const queryClient = useQueryClient();
  const jupyterUser = useSelector((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);

  return useMutation<
    unknown,
    Error,
    {
      repositoryPath: string;
      branchName: string;
    }
  >(
    async ({
      repositoryPath,
      branchName,
    }: {
      repositoryPath: string;
      branchName: string;
    }) => {
      return notebookApi.switchBranch(repositoryPath, branchName);
    },
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(notebookKeys.all());
        await queryClient.invalidateQueries(versionControlKeys.all());
      },
      onError: (error) => {
        // Handle any errors here
        console.error('Failed to switch branch:', error);
      },
    }
  );
};
