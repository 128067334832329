import classNames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { FiMic, FiSquare } from 'react-icons/fi';

import styles from './styles.module.scss';
import Waveform from './Waveform';

type Props = {
  onStopRecording: (audioBlob: Blob) => void;
  onError: (errorMessage: string) => void;
  disabled?: boolean;
};

const SAMPLE_RATE = 44100; // Standard sample rate
const BIN_SIZE = 0.2; // 0.2 seconds per bin
const TOTAL_DURATION = 20; // 10 seconds total

const AudioRecorder: FC<Props> = ({ onStopRecording, onError, disabled }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [waveformData, setWaveformData] = useState<number[]>([]);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const audioDataRef = useRef<number[]>([]);

  const animationFrameId = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, []);

  const startRecording = async () => {
    if (!navigator.mediaDevices) {
      onError('No media devices fond');
      return;
    }
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorder.current = new MediaRecorder(stream);

    const audioContext = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    const source = audioContext.createMediaStreamSource(stream);
    analyserRef.current = audioContext.createAnalyser();
    analyserRef.current.fftSize = 2048;
    source.connect(analyserRef.current);

    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Float32Array(bufferLength);

    audioDataRef.current = [];

    const draw = () => {
      animationFrameId.current = requestAnimationFrame(draw);
      analyserRef.current.getFloatTimeDomainData(dataArray);

      // Add new data to the end of the array
      audioDataRef.current = [
        ...audioDataRef.current,
        ...Array.from(dataArray),
      ];

      // Limit the total amount of data to TOTAL_DURATION seconds
      if (audioDataRef.current.length > SAMPLE_RATE * TOTAL_DURATION) {
        audioDataRef.current = audioDataRef.current.slice(
          -SAMPLE_RATE * TOTAL_DURATION
        );
      }

      setWaveformData(audioDataRef.current);
    };

    mediaRecorder.current.ondataavailable = (e) => {
      audioChunks.current.push(e.data);
    };

    mediaRecorder.current.onstop = async () => {
      const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
      stream.getTracks().forEach((track) => track.stop());
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
      if (audioBlob) {
        onStopRecording(audioBlob);
      } else {
        onError('No audio data recorded.');
      }
      audioChunks.current = [];
    };

    mediaRecorder.current.start(1000);
    setIsRecording(true);
    draw();
  };

  const stopRecording = () => {
    mediaRecorder.current?.stop();
    setIsRecording(false);
  };

  return (
    <div className={classNames(styles.audioRecorder)}>
      <div className={styles.canvasContainer}>
        {!waveformData ||
          (waveformData.length === 0 && (
            <div className={styles.placeholderContainer}>
              <span className={styles.placeholder}>
                Click the button to start recording
              </span>
            </div>
          ))}
        <Waveform
          binSize={BIN_SIZE}
          totalDuration={TOTAL_DURATION}
          sampleRate={SAMPLE_RATE}
          waveformData={waveformData}
        />
      </div>
      <button
        disabled={disabled}
        onClick={isRecording ? stopRecording : startRecording}
        className={styles.recordButton}
      >
        {isRecording ? <FiSquare size={18} /> : <FiMic size={18} />}
      </button>
    </div>
  );
};

export default AudioRecorder;
