import { ResponsiveLine } from '@nivo/line';
import classNames from 'classnames';
import { MetricsResponse } from 'common/dist/types/metrics';
import _ from 'lodash';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';
import { useMetricsHook } from '../../../core/api/workbench/metrics';
import { RootState } from '../../../store/store';

export type Props = {};

function splitRangeIntoEqualParts(a, b, n) {
  if (n < 1) {
    return [];
  }

  const step = (b - a) / (n - 1);
  const result = [];

  for (let i = 0; i < n; i++) {
    const value = a + i * step;
    result.push(_.round(value, 2));
  }

  return result;
}

function transformData(metricsResponse: MetricsResponse) {
  const dataMemory = [
    {
      id: 'Usage',
      data: (metricsResponse?.memory?.usage || []).map((o) => ({
        x: new Date(o[0] * 1000),
        y: o[1] / 1024 / 1024 / 1024,
      })),
      color: '#6AA0EB',
    },
    {
      id: 'Requests',
      data: (metricsResponse?.memory?.requests || []).map((o) => ({
        x: new Date(o[0] * 1000),
        y: o[1] / 1024 / 1024 / 1024,
      })),
      color: '#b9c3cb',
    },
    {
      id: 'Limit',
      data: (metricsResponse?.memory?.limits || []).map((o) => ({
        x: new Date(o[0] * 1000),
        y: o[1] / 1024 / 1024 / 1024,
      })),
      color: '#f2ad5f',
    },
  ];

  const memoryMax = _.max(
    dataMemory.flatMap((d) => d.data.flatMap((x) => x.y))
  );
  const gridMemory = splitRangeIntoEqualParts(0, memoryMax, 5);

  const dataCpu = [
    {
      id: 'Usage',
      data: (metricsResponse?.cpu?.usage || []).map((o) => ({
        x: new Date(o[0] * 1000),
        y: o[1],
      })),
      color: '#6AA0EB',
    },
    {
      id: 'Requests',
      data: (metricsResponse?.cpu?.requests || []).map((o) => ({
        x: new Date(o[0] * 1000),
        y: o[1],
      })),
      color: '#b9c3cb',
    },
    {
      id: 'Limit',
      data: (metricsResponse?.cpu?.limits || []).map((o) => ({
        x: new Date(o[0] * 1000),
        y: o[1],
      })),
      color: '#f2ad5f',
    },
  ];

  const cpuMax = _.max(dataCpu.flatMap((d) => d.data.flatMap((x) => x.y)));
  const gridCpu = splitRangeIntoEqualParts(0, cpuMax, 5);

  return { dataMemory, dataCpu, gridMemory, gridCpu };
}

const Chart: FC<{ nivoData: any; gridValues: any }> = ({
  nivoData,
  gridValues,
}) => (
  <ResponsiveLine
    colors={(d) => d.color}
    curve='monotoneX'
    data={nivoData}
    margin={{
      bottom: 10,
      left: 40,
      right: 10,
      top: 10,
    }}
    xScale={{
      type: 'time',
      format: 'native',
      precision: 'minute',
    }}
    axisBottom={{
      tickSize: 5,
      tickPadding: 0,
      format: () => '', // Empty string for no labels
      tickValues: 10,
    }}
    yScale={{
      type: 'linear',
    }}
    pointSize={0}
    enableGridX={false}
    gridYValues={gridValues}
    axisLeft={{
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 0,
      tickValues: gridValues,
    }}
  />
);

const WorkbenchMetrics: FC<Props> = () => {
  const notebookUser = useSelector<RootState, string>(
    (state) => state.currentUser.id
  );

  if (!notebookUser) return null;

  const metrics = useMetricsHook(notebookUser).data;
  const { dataMemory, dataCpu, gridMemory, gridCpu } = transformData(metrics);

  return (
    <div className={styles.workbenchMetrics}>
      <span className={styles.metricsTitle}>Metrics</span>
      <div className={styles.chartParent}>
        <div className={styles.chartHeadline}>
          <span className={styles.title}>RAM&nbsp;</span>
          <span>(last 10 min, in GB)</span>
        </div>
        <div className={styles.chart}>
          <Chart nivoData={dataMemory} gridValues={gridMemory} />
        </div>
      </div>
      <div className={styles.chartParent}>
        <div className={styles.chartHeadline}>
          <span className={styles.title}>CPU&nbsp;</span>
          <span>(last 10 min)</span>
        </div>
        <div className={styles.chart}>
          <Chart nivoData={dataCpu} gridValues={gridCpu} />
        </div>
      </div>
      <div className={styles.legend}>
        <div className={styles.legendItem}>
          <span
            className={classNames(
              styles.legendIcon,
              styles.legendIconBackgroundVarA
            )}
          />
          <span className={styles.legendLabel}>Usage</span>
        </div>
        <div className={styles.legendItem}>
          <span
            className={classNames(
              styles.legendIcon,
              styles.legendIconBackgroundVarB
            )}
          />
          <span className={styles.legendLabel}>Requests</span>
        </div>
        <div className={styles.legendItem}>
          <span
            className={classNames(
              styles.legendIcon,
              styles.legendIconBackgroundVarC
            )}
          />
          <span className={styles.legendLabel}>Limit</span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(WorkbenchMetrics);
