import React, { FC, useState } from 'react';

import { SourceDocument } from './ChatMessages';
import styles from './styles.module.scss';
import ExpandCollapseIcon from '../../../atoms/expand-collapse-icon/ExpandCollapseIcon';

type Props = {
  sourceDocuments: SourceDocument[];
};

const SourceDocuments: FC<Props> = ({ sourceDocuments }) => {
  const [isExpanded, setExpanded] = useState<boolean>(false);

  return (
    <div className={styles.sourceDocuments}>
      <div
        className={styles.sourceDocumentsTitle}
        onClick={() => setExpanded(!isExpanded)}
      >
        <span>Source Documents</span>
        <ExpandCollapseIcon
          isExpanded={isExpanded}
          onClick={() => setExpanded(!isExpanded)}
        />
      </div>

      {isExpanded && (
        <div className={styles.sourceDocumentsBody}>
          {sourceDocuments.map((sourceDocument, i) => (
            <div key={i} className={styles.sourceDocument}>
              <span className={styles.documentName}>
                {sourceDocument.file_name}
              </span>
              <span className={styles.pageContent}>
                {sourceDocument.page_content}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SourceDocuments;
