export const REPORT_ELEMENT_TYPES = {
  LINE_CHART: 'line-chart',
  BAR_CHART: 'bar-chart',
  PIE_CHART: 'pie-chart',
  CLASSIFICATION_TREE: 'classification-tree',
  SHAP_CHART: 'shap-chart',
  PERFORMANCE_DRIFT: 'performance-drift',
  PARAMETER_TUNING_RESULTS: 'parameter-tuning-results',
  CONSTRAINT_EXHAUSTION: 'constraint-exhaustion',
  TEXT_OUTPUT: 'text-output',
  FEATURE_IMPORTANCE: 'feature_importance',
  PIPELINE_TUNING_BEST_RESULT: 'pipeline-tuning-best-result',
  PIPELINE_TUNING_SINGLE_RESULTS: 'pipeline-tuning-single-results',
  HEATMAP: 'heatmap',
  IMAGE: 'image',
  TABLE: 'table',
} as const;
export type ReportElementTypes =
  (typeof REPORT_ELEMENT_TYPES)[keyof typeof REPORT_ELEMENT_TYPES];
