import React, { FC } from 'react';
import { IntlShape } from 'react-intl';

import styles from './styles.module.scss';

type Props = {
  intl: IntlShape;
  showSuggestions: () => void;
  search: (query: string) => void;
  input: {
    onChange: (value: string) => void;
    onFocus: () => void;
    value: string;
  };
};

const QueryInput: FC<Props> = ({ showSuggestions, intl, search, input }) => {
  const onChange = (value: string) => {
    input.onChange(value);
    search(value);
    showSuggestions();
  };

  const onFocus = () => {
    input.onFocus();
    showSuggestions();
  };

  const placeholder = intl.formatMessage({
    id: 'dashboard.search.search_placeholder',
  });

  return (
    <input
      type='text'
      className={styles.inputGroupField}
      placeholder={placeholder}
      autoComplete='off'
      value={input.value}
      onChange={(event) => onChange(event.target.value)}
      onFocus={onFocus}
    />
  );
};

export default QueryInput;
