import { useQueryClient } from '@tanstack/react-query';
import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';
import ReactModal from 'react-modal';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import FieldParameters from './FieldParameters';
import FieldResources from './FieldResources';
import { fieldParameters, fieldResources, form } from './form';
import { notebookKeys } from '../../../../core/api/notebook';
import { versionControlKeys } from '../../../../core/api/versionControl';
import Button from '../../../atoms/button/Button';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    minWidth: '600px',
    transform: 'translate(-50%, -50%)',
  },
};

export interface Props {
  isShown?: boolean;
  hideConfigureCodeCapsuleModal: (...args: unknown[]) => unknown;
  resetForm: (...args: unknown[]) => unknown;
  updateCodeCapsuleConfiguration: (...args: unknown[]) => unknown;
  path?: string;
  formValues?: {
    resources?: {
      cpu?: string;
      memory?: string;
    };
    parameters?: {
      key?: string;
      value?: string;
    }[];
  };
  repoMeta?: {};
}

const ConfigureCodeCapsuleModal: FC<Props & InjectedFormProps> = ({
  isShown = false,
  hideConfigureCodeCapsuleModal,
  updateCodeCapsuleConfiguration,
  resetForm,
  path,
  formValues,
  repoMeta,
  valid,
}) => {
  const repoMetaWithValues = { ...repoMeta, ...formValues };

  const queryClient = useQueryClient();
  const closeModal = () => {
    hideConfigureCodeCapsuleModal();
    resetForm();
  };

  const onSubmit = () => {
    updateCodeCapsuleConfiguration(path, repoMetaWithValues);
    hideConfigureCodeCapsuleModal();

    void queryClient.invalidateQueries(versionControlKeys.all());
    void queryClient.invalidateQueries(notebookKeys.all());
  };

  return (
    <ReactModal
      isOpen={isShown}
      contentLabel='Dialog Modal'
      onRequestClose={closeModal}
      style={customStyles}
      appElement={document.getElementById('react-app')}
      parentSelector={() => document.querySelector('#MainContainer')}
    >
      <div className={'configure-code-capsule-modal modal-parent'}>
        <div
          title={'Close Dialog'}
          className={'workbench-button close-button'}
          onClick={closeModal}
        >
          <FiX className={'icon'} size={'20px'} />
        </div>

        <div className={'modal-group-parent resources-parent'}>
          <span className={'modal-headline'}>
            Proposed Resources (optional)
          </span>
          <span className={'modal-subheadline'}>
            Propose resource limits for this Code Capsule.
          </span>
          <Field name={fieldResources} component={FieldResources} />
        </div>

        <div className={'modal-group-parent parameters-parent'}>
          <span className={'modal-headline'}>Available Parameters</span>
          <span className={'modal-subheadline'}>
            Define the list of available input Parameters and their default
            values.
          </span>
          <Field name={fieldParameters} component={FieldParameters} />
        </div>

        <div className={'code-capsule-modal--button-bar'}>
          <Button
            color={'primary'}
            label={'OK'}
            disabled={!valid}
            onClick={onSubmit}
          />
          <Button color={'white'} label={'Cancel'} onClick={closeModal} />
        </div>
      </div>
    </ReactModal>
  );
};

export default reduxForm(form)(ConfigureCodeCapsuleModal);
