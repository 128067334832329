import React, { Component } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';
import Busy from '../../../atoms/busy/Busy';
import CassandraTablePreview from '../../../organisms/cassandra-table-preview/CassandraTablePreview';
import NotFound from '../../../pages/not-found/NotFound.container';
import { dataManagementRoutes } from '../../routes';

export interface Props extends RouteComponentProps {
  dataSourceCode: string;
  bucket: string;
  path: string;
  fetchPreview: (dataSourceCode: string, path: string) => void;
  data: {
    colSpecs: { colName: string; colType: string }[];
    data: string[][];
  };
  loading: boolean;
  loaded: boolean;
  error: { status: string; message: string };
}

export default class TableBrowser extends Component<Props> {
  componentDidMount() {
    const { dataSourceCode, bucket, path, fetchPreview } = this.props;
    if (bucket && path) {
      fetchPreview(dataSourceCode, `${bucket}/${path}`);
    }
  }
  getBackPath() {
    const { path: currentPath } = this.props;

    // Remove trailing slashes and ensure it starts with a slash
    const cleanPath = '/' + currentPath.replace(/\/*$/, '');

    // Find the last occurrence of '/'
    const lastSlashIndex = cleanPath.lastIndexOf('/');

    // If it's the root, return just a slash
    if (lastSlashIndex === 0) {
      return '/';
    }

    // Return everything before the last '/', plus a trailing slash
    return cleanPath.slice(0, lastSlashIndex) + '/';
  }
  render() {
    const { dataSourceCode, bucket, path, data, loading, loaded, error } =
      this.props;

    if (error) {
      return <NotFound />;
    }

    if (loading) {
      return <Busy isBusy />;
    }
    const backPath = this.getBackPath();
    const linkTo = `${dataManagementRoutes.basePath}/s3/${dataSourceCode}/bucket/${bucket}/path${backPath}`;

    return (
      <div>
        <div>
          <div className={styles.backButtonContainer}>
            <Link to={linkTo} style={{ textDecoration: 'none' }}>
              <div className={styles.backButton}>
                <FiChevronLeft size={20} className={styles.backIcon} />
                <span className={styles.backText}>Back</span>
              </div>
            </Link>
          </div>
        </div>

        <span className={styles.tableBrowserHeadline}>
          TableBrowser for File <b>{path}</b> in Bucket <b>{bucket}</b>
        </span>

        {data && data.colSpecs && data.data && (
          <div className={styles.scrollableTableContainer}>
            <CassandraTablePreview
              colSpecs={data.colSpecs}
              data={data.data}
              editable={false}
            />
          </div>
        )}

        {loaded && (!data || data.data.length === 0) && (
          <span>The table is currently empty.</span>
        )}
      </div>
    );
  }
}
