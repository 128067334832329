// Helper function to convert raw change type to a more readable format
import { FileData } from '../types/types';

export const speakingChangeType = (rawChangeType: string): string => {
  if (rawChangeType === 'M') return 'Modified';
  if (rawChangeType === 'D') return 'Deleted';
  if (rawChangeType === 'R') return 'Renamed';
  return rawChangeType;
};

// Helper function to format the file path for display
export const styledPath = (f: FileData): string => {
  if (f.change_type === 'R') return `${f.b_path} -> ${f.a_path}`;
  return f.a_path;
};
