import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiX } from 'react-icons/fi';
import ReactModal from 'react-modal';

import infoStyles from './../../../info/styles.module.scss';
import { useGitDeleteBranchHook } from '../../../../../../../core/api/notebook';
import Button from '../../../../../../atoms/button/Button';
import { IntlCheckbox } from '../../../../../../atoms/react-hook-form-input-elements/checkbox/Checkbox';
import styles from '../styles.module.scss';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

interface DeleteBranchModalProps {
  isDeleteBranchModalOpen: boolean;
  hideDeleteBranchModal: () => void;
  infoFilePath: string;
  activeBranch: string;
  isForceDeleteVisible?: boolean;
}

interface FormInputs {
  deleteRemote: boolean;
}

const DeleteBranchModal: FC<DeleteBranchModalProps> = ({
  isDeleteBranchModalOpen,
  hideDeleteBranchModal,
  infoFilePath,
  activeBranch,
  isForceDeleteVisible = false,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: { deleteRemote: false },
  });
  const { mutate } = useGitDeleteBranchHook();

  const handleClose = () => {
    hideDeleteBranchModal();
  };

  const onSubmit = (data: FormInputs) => {
    const parts = infoFilePath.split('/');
    const repositoryPath = parts.slice(0, parts.length - 1).join('/');
    let force = false;
    if (isForceDeleteVisible) {
      force = true;
    }

    mutate({
      repositoryPath,
      branchName: activeBranch,
      force: force,
      remote: false,
    });
    if (data?.deleteRemote)
      mutate({
        repositoryPath,
        branchName: activeBranch,
        force: force,
        remote: data.deleteRemote,
      });
    handleClose();
  };

  const renderWarning = () => {
    if (isForceDeleteVisible) {
      return (
        <p className={'warning-text'}>
          The current branch has unmerged changes. By deleting the branch you
          will lose these changes.
        </p>
      );
    }
    return null;
  };

  return (
    <ReactModal
      isOpen={isDeleteBranchModalOpen}
      contentLabel='Delete Branch Modal'
      onRequestClose={handleClose}
      style={customStyles}
      appElement={document.getElementById('react-app')}
      parentSelector={() => document.querySelector('#MainContainer')}
    >
      <div className={infoStyles.modalCommitParent}>
        <div
          title={'Close Dialog'}
          className={'workbench-button close-button'}
          onClick={handleClose}
        >
          <FiX className={'icon'} size={'20px'} />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={infoStyles.modalCommitElements}>
            <div className={styles.parent}>
              <p>Are you sure you want to delete the branch {activeBranch}?</p>
              {renderWarning()}
              <Controller
                name={'deleteRemote'}
                control={control}
                render={({ field }) => (
                  <IntlCheckbox
                    label={'Delete Remote Branch too'}
                    {...field}
                    checked={field.value}
                    onChange={() => {
                      field.onChange(!field.value);
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className={infoStyles.modalCommitButtons}>
            <Button
              color={'red'}
              label={isForceDeleteVisible ? 'Delete Anyway' : 'Delete'}
              disabled={!isValid}
              type='submit'
            />
          </div>
        </form>
      </div>
    </ReactModal>
  );
};

export default DeleteBranchModal;
