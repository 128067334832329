import { App } from './app';
import { CodeCapsule } from './codeCapsule';
import { Module } from './module';
import { UserRepresentationWithRealmRolesAndGroups } from './users';

/**
 * The type of repository returned after creation
 */
export type Repository = {
  name: string;
  code: string;
  repoName: string;
  repoFullName: string;
  repoDescription?: string;
  repoType: RepoType;
  createdByUserId: string;
  createdAt: Date;
};

export type RepositoryWithEntity =
  | PlainRepository
  | CodeCapsuleRepository
  | AppRepository
  | ModuleRepository;

export type PlainRepository = Repository & {
  repoType: typeof REPO_TYPE.PLAIN;
};

export type CodeCapsuleRepository = Repository & {
  repoType: typeof REPO_TYPE.CODE_CAPSULE;
  codeCapsule?: CodeCapsule;
};

export type AppRepository = Repository & {
  repoType: typeof REPO_TYPE.APP;
  app?: App;
};

export type ModuleRepository = Repository & {
  repoType: typeof REPO_TYPE.MODULE;
  module?: Module;
};

/**
 * The type of repository returned when listing repositories
 */
export type RepositoryListing = RepositoryWithEntity & {
  createdBy?: UserRepresentationWithRealmRolesAndGroups;
};
/**
 * If the notebook git api checked for a merge
 */
export type PullConflicts = {
  branch?: string;
  conflicts?: string[];
  error?: string;
};
/**
 * If the notebook git api tried to push
 */
export type PushConflicts = {
  branch: string;
  commits_behind: string;
};

/**
 * Placeholder, since the GitApi is not typed
 */
export type GitApiRepository = {
  full_name: string;
  name: string;
  owner?: {
    username: string;
    login: string;
  };
  [k: string]: unknown;
};

export const REPO_TYPE = {
  MODULE: 'module',
  CODE_CAPSULE: 'code-capsule',
  APP: 'app',
  PLAIN: 'plain',
} as const;
export type RepoType = (typeof REPO_TYPE)[keyof typeof REPO_TYPE];

export const repoTypeToSpeaking = {
  plain: 'Plain Repository',
  'code-capsule': 'Code Capsule',
  app: 'App',
  module: 'Module',
};
export const repoTypeToSpeakingPlural = {
  plain: 'Plain Repositories',
  'code-capsule': 'Code Capsules',
  app: 'Apps',
  module: 'Modules',
};
//Gitea 1.22.0 type
export type CreateRepoOption = {
  name: string;
  auto_init?: boolean;
  default_branch?: string;
  description?: string;
  gitignores?: string;
  issue_labels?: string;
  license?: string;
  uniqueItems?: true;
  object_format_name?: 'sha1' | 'sha256';
  private?: boolean;
  readme?: string;
  template?: boolean;
  trust_model?:
    | 'default'
    | 'collaborator'
    | 'committer'
    | 'collaboratorcommitter';
};
//Gitea 1.22.0 type
export type Organization = {
  avatar_url: string;
  description: string;
  full_name: string;
  id: number;
  location: string;
  name: string;
  repo_admin_change_team_access: boolean;
  username: string;
  visibility: string;
  website: string;
};
//Gitea 1.22.0 type
export type GiteaRepositoryTemplate = {
  name: string;
  owner: string;
  avatar?: boolean;
  default_branch?: string;
  description?: string;
  git_content?: boolean;
  git_hooks?: boolean;
  labels?: boolean;
  private?: boolean;
  protected_branch?: boolean;
  topics?: boolean;
  webhooks?: boolean;
};
//Gitea 1.22.0 type
export type EditRepoOption = {
  allow_fast_forward_only_merge?: boolean;
  allow_manual_merge?: boolean;
  allow_merge_commits?: boolean;
  allow_rebase?: boolean;
  allow_rebase_explicit?: boolean;
  allow_rebase_update?: boolean;
  allow_squash_merge?: boolean;
  archived?: boolean;
  autodetect_manual_merge?: boolean;
  default_allow_maintainer_edit?: boolean;
  default_branch?: string;
  default_delete_branch_after_merge?: boolean;
  default_merge_style?: string;
  description?: string;
  enable_prune?: boolean;
  external_tracker?: {
    external_tracker_format?: string;
    external_tracker_regexp_pattern?: string;
    external_tracker_style?: string;
    external_tracker_url?: string;
  };
  external_wiki?: {
    external_wiki_url?: string;
  };
  has_actions?: boolean;
  has_issues?: boolean;
  has_packages?: boolean;
  has_projects?: boolean;
  has_pull_requests?: boolean;
  has_releases?: boolean;
  has_wiki?: boolean;
  ignore_whitespace_conflicts?: boolean;
  internal_tracker?: {
    allow_only_contributors_to_track_time?: boolean;
    enable_issue_dependencies?: boolean;
    enable_time_tracker?: boolean;
  };
  mirror_interval?: string;
  name?: string;
  uniqueItems?: true;
  private?: boolean;
  template?: boolean;
  website?: string;
};
//Gitea 1.22.0 type
export type ContentsResponse = {
  _links: {
    git: string;
    html: string;
    self: string;
  };
  content: string;
  download_url: string;
  encoding: string;
  git_url: string;
  html_url: string;
  last_commit_sha: string;
  name: string;
  path: string;
  sha: string;
  size: number;
  submodule_git_url: string;
  target: string;
  type: string;
  url: string;
};

//Gitea 1.22.0 type, but extracted to use it better
type GiteaFileBase = {
  author?: {
    email?: string;
    name?: string;
  };
  branch?: string;
  committer?: {
    email?: string;
    name?: string;
  };
  dates?: {
    author?: string;
    committer?: string;
  };
  message?: string;
  new_branch?: string;
  signoff?: boolean;
};

//Gitea 1.22.0 type
export type UpdateFileOptions = GiteaFileBase & {
  content?: string;
  from_path?: string;
  sha: string;
};
type Operations = 'create' | 'update' | 'delete';
//Gitea 1.22.0 type
export type ChangeFileOperation = {
  content?: string;
  from_path?: string;
  operation: Operations;
  path: string;
  sha?: string;
};
export type ChangeFilesOptions = GiteaFileBase & {
  files: ChangeFileOperation[];
};

// Commit types taken as the response from /repos/{owner}/{repo}/commits for Gitea 1.22.0 - Generated by https://quicktype.io
export interface Commit {
  author: CommitAuthor;
  commit: CommitClass;
  committer: CommitAuthor;
  created: string;
  files: File[];
  html_url: string;
  parents: Tree[];
  sha: string;
  stats: Stats;
  url: string;
}

interface CommitAuthor {
  active: boolean;
  avatar_url: string;
  created: string;
  description: string;
  email: string;
  followers_count: number;
  following_count: number;
  full_name: string;
  id: number;
  is_admin: boolean;
  language: string;
  last_login: string;
  location: string;
  login: string;
  login_name: string;
  prohibit_login: boolean;
  restricted: boolean;
  starred_repos_count: number;
  visibility: string;
  website: string;
}

interface CommitClass {
  author: CommitAuthorClass;
  committer: CommitAuthorClass;
  message: string;
  tree: Tree;
  url: string;
  verification: Verification;
}

interface CommitAuthorClass {
  date: string;
  email: string;
  name: string;
}

interface Tree {
  created: string;
  sha: string;
  url: string;
}

interface Verification {
  payload: string;
  reason: string;
  signature: string;
  signer: Signer;
  verified: boolean;
}

interface Signer {
  email: string;
  name: string;
  username: string;
}

interface File {
  filename: string;
}

interface Stats {
  additions: number;
  deletions: number;
  total: number;
}

//Gitea 1.22.0 type
interface PayloadCommit {
  added?: string[];
  id?: string;
  message?: string;
  modified?: string[];
  removed?: string[];
  timestamp?: string;
  url?: string;
  author?: PayloadUser;
  committer?: PayloadUser;
  verification?: PayloadCommitVerification;
}

//Gitea 1.22.0 type
interface PayloadUser {
  email?: string;
  name?: string;
  username?: string;
}

//Gitea 1.22.0 type
interface PayloadCommitVerification {
  payload?: string;
  reason?: string;
  signature?: string;
  verified?: boolean;
  signer?: PayloadUser;
}

//Gitea 1.22.0 type /repos/{owner}/{repo}/branches  List a repository's branches
export type Branch = {
  commit?: PayloadCommit;
  effective_branch_protection_name?: string;
  enable_status_check?: boolean;
  name?: string;
  protected?: boolean;
  required_approvals?: number;
  status_check_con?: string[];
  user_can_merge?: boolean;
  user_can_push?: boolean;
};
